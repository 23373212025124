import { init } from '@sentry/react';

import { config } from './config';

export const initialize = () => {
  if (config.sentryDsn) {
    init({
      dsn: config.sentryDsn,
      environment: process.env.STAGE,
      release: process.env.RELEASE,
      tracesSampleRate: 1.0,
      beforeSend(event) {
        if (
          event.exception?.values &&
          event.exception?.values.length > 0 &&
          event.exception?.values[0].stacktrace?.frames &&
          event.exception?.values[0].stacktrace?.frames.length > 0 &&
          event.exception?.values[0].stacktrace?.frames[
            event.exception?.values[0].stacktrace?.frames.length - 1
          ].filename?.match(/sdk.alli.ai/i)
        ) {
          return event;
        }
        return null;
      },
    });
  }
};
