const transform = (factor: number) => factor * 8;

export interface Spacing {
  (): number;
  (value: number): number;
  (topBottom: number, rightLeft: number): string;
  (top: number, rightLeft: number, bottom: number): string;
  (top: number, right: number, bottom: number, left: number): string;
}

// @ts-ignore
export const spacing: Spacing = (...args: number[]) => {
  if (args.length === 0) {
    return transform(1);
  }

  if (args.length === 1) {
    return transform(args[0]);
  }

  return args
    .map(factor => {
      const output = transform(factor);
      return typeof output === 'number' ? `${output}px` : output;
    })
    .join(' ');
};
