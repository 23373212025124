import {
  KnowledgeBaseWhereUniqueInput,
  Int,
  Nullable,
  KnowledgeBasePreview as KnowledgeBasePreviewBase,
  CSVKnowledgeBase,
  HWPKnowledgeBase,
  GoogleDocsKnowledgeBase,
  TextKnowledgeBase,
  MSDocsKnowledgeBase,
  PDFKnowledgeBase,
  MSExcelKnowledgeBase,
  MSPPTKnowledgeBase,
  OldMSDocsKnowledgeBase,
  ImageKnowledgeBase,
  KnowledgeBasePreviewHighlights as KnowledgeBasePreviewHighlightsBase,
  KnowledgeBasePreviewPageHighlight,
  KnowledgeBasePreviewHighlightsInput,
  KnowledgeBaseDraftjsPreview,
} from '@/models';
import { makeQuery, makeLazyQuery } from '@/graphql';

import query from './knowledgeBasePreviewQuery.graphql';

export type KnowledgeBase =
  | Pick<GoogleDocsKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<TextKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<MSDocsKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<PDFKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<MSExcelKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<MSPPTKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<OldMSDocsKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<ImageKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<CSVKnowledgeBase, '__typename' | 'id' | 'title'>
  | Pick<HWPKnowledgeBase, '__typename' | 'id' | 'title'>;

export interface KnowledgeBasePreviewHighlights
  extends KnowledgeBasePreviewHighlightsBase {
  pageIndexes: Nullable<KnowledgeBasePreviewPageHighlight[]>;
}

export interface KnowledgeBasePreview extends KnowledgeBasePreviewBase {
  knowledgeBase: Nullable<KnowledgeBase>;
  highlights: Nullable<KnowledgeBasePreviewHighlights>;
  draftjs: Nullable<
    Pick<KnowledgeBaseDraftjsPreview, '__typename' | 'id' | 'answer'>
  >;
}

export interface Data {
  knowledgeBasePreview: Nullable<KnowledgeBasePreview>;
}

export interface Variables {
  where: KnowledgeBaseWhereUniqueInput;
  pageNo?: Int;
  highlightIndexes?: Int[];
  highlights?: KnowledgeBasePreviewHighlightsInput;
  exactAnswer?: string;
}

export const useKnowledgeBasePreviewQuery = makeQuery<Data, Variables>(query);
export const useKnowledgeBasePreviewLazyQuery = makeLazyQuery<Data, Variables>(
  query,
);
