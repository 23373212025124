
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SendFormChatFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SendFormChat"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plainText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"media"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMediaFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"nextInputType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"formValues"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFormValueFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"useAutoComplete"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"enableAgentSelection"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetAgents"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AgentSelectFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"hidden"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":412}};
    doc.loc.source = {"body":"#import './ChatFormValueFragment.graphql'\n#import './ChatMediaFragment.graphql'\n#import './AgentSelectFragment.graphql'\n\nfragment SendFormChatFragment on SendFormChat {\n  id\n  createdAt\n  message\n  plainText\n  media {\n    ...ChatMediaFragment\n  }\n  nextInputType\n  formValues {\n    ...ChatFormValueFragment\n  }\n\n  useAutoComplete\n  enableAgentSelection\n  targetAgents {\n    ...AgentSelectFragment\n  }\n  hidden\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ChatFormValueFragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./ChatMediaFragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./AgentSelectFragment.graphql').definitions));


      module.exports = doc;
    
