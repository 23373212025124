import { createTheme as createMuiTheme } from '@mui/material/styles';

import { ALLI_Z_INDEX } from '@/constants';
import { palette, typography } from '@/styles';

const createTheme = () => {
  const muiTheme = createMuiTheme({
    palette,
    typography,
    zIndex: {
      modal: ALLI_Z_INDEX + 3,
    },
  });

  return muiTheme;
};

export type Theme = ReturnType<typeof createTheme>;

export const theme = createTheme();
