import {
  LocaleEnum,
  Nullable,
  SDKReferrerInput,
  SDKSettings,
  SDKUrlPattern,
  ServerError,
  Typename,
} from '@/models';
import { createMutation } from '@/graphql';

import { Conversation } from '../StartConversationMutation';

import mutation from './continueConversationMutation.graphql';

interface UserContinueConversation
  extends Typename<'UserContinueConversation'> {
  conversation: Nullable<Conversation>;
  sdkSettings: Nullable<SDKSettings>;
  sdkUrlPatterns: Nullable<SDKUrlPattern[]>;
  errors: Nullable<ServerError[]>;
  apiKey: Nullable<string>;
  ownUserId: Nullable<string>;
}

export interface Data {
  continueConversation: Nullable<UserContinueConversation>;
}

export interface Variables {
  locale?: LocaleEnum;
  sdkReferrer?: SDKReferrerInput;
  userAgent?: string;
}

export const ContinueConversationMutation = createMutation<Data, Variables>(
  mutation,
);
