import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';

import { makeStyles, Styles, StyledComponentProps, Color } from '@/styles';
import { capitalize, fade } from '@/utils';

import { ButtonBase, ButtonBaseProps } from './ButtonBase';

const styles: Styles<
  | 'root'
  | 'edgeStart'
  | 'edgeEnd'
  | 'colorInherit'
  | 'colorPrimary'
  | 'colorSecondary'
  | 'colorBlue'
  | 'disabled'
  | 'sizeSmall'
  | 'label'
> = theme => ({
  root: {
    textAlign: 'center',
    flex: '0 0 auto',
    fontSize: '24px',
    padding: '12px',
    borderRadius: '50%',
    overflow: 'visible', // Explicitly set the default value to solve a bug on IE 11.
    color: theme.palette.action.active,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: fade(
        theme.palette.action.active,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&$disabled': {
      backgroundColor: 'transparent',
      color: theme.palette.action.disabled,
    },
  },
  edgeStart: {
    marginLeft: '-12px',
    '$sizeSmall&': {
      marginLeft: '-3px',
    },
  },
  edgeEnd: {
    marginRight: '-12px',
    '$sizeSmall&': {
      marginRight: '-3px',
    },
  },
  colorInherit: {
    color: 'inherit',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.secondary.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorBlue: {
    color: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.blue.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  disabled: {},
  sizeSmall: {
    padding: '3px',
    fontSize: '18px',
  },
  label: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
});

export const useIconButtonStyles = makeStyles(styles);
const iconButtonClasses = cn('alli-sdk-IconButton');

export type IconButtonProps<
  T extends React.ElementType = 'button'
> = ButtonBaseProps<T> &
  StyledComponentProps<typeof styles> & {
    color?: Color;
    edge?: 'start' | 'end' | false;
    size?: 'small' | 'medium';
  };

export const IconButton = forwardRef(function IconButtonBase<
  T extends React.ElementType = 'button'
>(
  {
    color = 'default',
    edge,
    size = 'medium',
    classes: classesProp,
    children,
    ...other
  }: React.PropsWithChildren<IconButtonProps<T>>,
  ref: React.Ref<IconButtonProps<T>['ref']>,
) {
  const classes = useIconButtonStyles({ classes: classesProp });

  return (
    <ButtonBase
      {...other}
      className={clsx(
        classes.root,
        {
          [classes[`color${capitalize(color)}` as keyof typeof classes]]:
            color !== 'default',
          [classes.disabled]: other.disabled,
          [classes[`size${capitalize(size)}` as keyof typeof classes]]:
            size !== 'medium',
          [classes.edgeStart]: edge === 'start',
          [classes.edgeEnd]: edge === 'end',
        },
        iconButtonClasses({
          color,
          disabled: other.disabled,
          size,
          edge,
        }),
        other.className,
      )}
      ref={ref}
    >
      <span className={clsx(classes.label, iconButtonClasses('label'))}>
        {children}
      </span>
    </ButtonBase>
  );
});
