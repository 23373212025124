import { Typename, ID, Nullable, Node, Float, Edge, Int } from './graphql';
import { Locale } from './User';

export interface ConversationWhereUniqueInput {
  id: ID;
}

// export interface ConversationFilterInput {
// }

export enum LocaleEnum {
  en = 'EN',
  ko = 'KO',
  ja = 'JA',
}

export const localeMap: Record<Locale, LocaleEnum> = {
  [Locale.en]: LocaleEnum.en,
  [Locale.ko]: LocaleEnum.ko,
  [Locale.ja]: LocaleEnum.ja,
};

export enum ConversationOrder {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  LAST_CHAT_TIME_ASC = 'LAST_CHAT_TIME_ASC',
  LAST_CHAT_TIME_DESC = 'LAST_CHAT_TIME_DESC',
  CAMPAIGN_NAME_ASC = 'CAMPAIGN_NAME_ASC',
  CAMPAIGN_NAME_DESC = 'CAMPAIGN_NAME_DESC',
}

export enum ConversationState {
  INIT = 'INIT', // will not be used in client side
  END_OF_CHATBOT = 'END_OF_CHATBOT', // chatbot flow is ended
  END_BY_AGENT = 'END_BY_AGENT', // agent completed the conversation
  END_BY_USER = 'END_BY_USER',
  WAIT_AGENT = 'WAIT_AGENT',
  WAIT_USER_ANSWER = 'WAIT_USER_ANSWER', // chatbot is waiting for user input
  WAIT_USER_ANSWER_2 = 'WAIT_USER_ANSWER_2', // chatbot is waiting for user input
  INTERRUPTED = 'INTERRUPTED', // agent has entered the conversation
  PENDING = 'PENDING',
  END_OF_OBSERVER = 'END_OF_OBSERVER',
}

export interface TypingInfo extends Typename<'TypingInfo'> {
  agentTyping: boolean;
  userTyping: boolean;
}

export interface Conversation extends Typename<'Conversation'>, Node {
  state: ConversationState;
  lastChatTime: Nullable<Float>;
  isTry: Nullable<boolean>;
}

export type ConversationEdge<N> = Edge<'ConversationEdge', N>;

export interface ReadInfo extends Typename<'ReadInfo'> {
  agentLastReadChatId: Nullable<ID>;
  agentLastReadTime: Nullable<Float>;
  agentUnreadChatCount: Nullable<Int>;
  userLastReadChatId: Nullable<ID>;
  userLastReadTime: Nullable<Float>;
  userUnreadChatCount: Nullable<Int>;
}
