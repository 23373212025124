import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { StyledComponentProps } from '@/styles';

import { Button } from '../Button';

import { useStyles } from './common';

interface ErrorStateProps
  extends StyledComponentProps<keyof ReturnType<typeof useStyles>>,
    React.HTMLAttributes<HTMLDivElement> {
  onRetry?: React.MouseEventHandler<HTMLButtonElement>;
}

const ErrorState = forwardRef<HTMLDivElement, ErrorStateProps>(
  ({ onRetry, classes: classesProp, children, ...other }, ref) => {
    const classes = useStyles({ classes: classesProp });

    return (
      <div {...other} className={clsx(classes.root, other.className)} ref={ref}>
        <div className={classes.title}>
          <FormattedMessage
            tagName="div"
            id="ErrorState.Title"
            defaultMessage="<div>Oops, sorry.</div><div>Something went wrong.</div>"
            description="Error state title text"
            values={{
              div: (chunks: React.ReactNodeArray) => <div>{chunks}</div>,
            }}
          />
        </div>

        <div className={classes.description}>
          {children || (
            <FormattedMessage
              id="ErrorState.Description"
              defaultMessage="Why don't you try one more time?"
              description="Error state description text"
            />
          )}
        </div>

        {onRetry && (
          <Button
            className={classes.action}
            color="blue"
            variant="contained"
            onClick={onRetry}
          >
            <FormattedMessage
              id="ErrorState.RetryButton"
              defaultMessage="retry"
              description="Retry button text"
            />
          </Button>
        )}
      </div>
    );
  },
);

ErrorState.displayName = 'ErrorState';
export default ErrorState;
