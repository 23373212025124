
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChatFormValueFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormValue"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"display"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"value"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"mandatory"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"choices"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"multipleOption"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"inputType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"file"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFileFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"variable"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatVariableFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"formValueInfos"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatFormValueInfoFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":381}};
    doc.loc.source = {"body":"#import './ChatFileFragment.graphql'\n#import './ChatVariableFragment.graphql'\n#import './ChatFormValueInfoFragment.graphql'\n\nfragment ChatFormValueFragment on FormValue {\n  display\n  value\n  mandatory\n  choices\n  multipleOption\n  inputType\n\n  file {\n    ...ChatFileFragment\n  }\n  variable {\n    ...ChatVariableFragment\n  }\n  formValueInfos {\n    ...ChatFormValueInfoFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ChatFileFragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./ChatVariableFragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./ChatFormValueInfoFragment.graphql').definitions));


      module.exports = doc;
    
