const duration = {
  complex: 375,
  enteringScreen: 225,
  leavingScreen: 195,
  short: 250,
  shorter: 200,
  shortest: 150,
  standard: 300,
};

const easing = {
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
  sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
};

const formatMs = (milliseconds: number) => `${Math.round(milliseconds)}ms`;

const create = (
  props: string | string[] = ['all'],
  options: Partial<{
    duration: number | string;
    easing: string;
    delay: number | string;
  }> = {},
): string => {
  const {
    duration: durationOption = duration.standard,
    easing: easingOption = easing.easeInOut,
    delay = 0,
  } = options;

  return (Array.isArray(props) ? props : [props])
    .map(
      animatedProp =>
        `${animatedProp} ${
          typeof durationOption === 'string'
            ? durationOption
            : formatMs(durationOption)
        } ${easingOption} ${
          typeof delay === 'string' ? delay : formatMs(delay)
        }`,
    )
    .join(',');
};

const getAutoHeightDuration = (height: number) => {
  if (!height) {
    return 0;
  }

  const constant = height / 36;

  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
};

export const transitions = {
  create,
  duration,
  easing,
  getAutoHeightDuration,
};
