import {
  Nullable,
  SDKSettings,
  SDKUrlPattern,
  Typename,
  ServerError,
} from '@/models';
import { createMutation } from '@/graphql';

import { Conversation, Variables } from '../StartConversationMutation';

import mutation from './tryConversationMutation.graphql';

interface UserTryConversation extends Typename<'UserTryConversation'> {
  conversation: Nullable<Conversation>;
  sdkSettings: Nullable<SDKSettings>;
  sdkUrlPatterns: Nullable<SDKUrlPattern[]>;
  errors: Nullable<ServerError[]>;
}

export interface Data {
  tryConversation: Nullable<UserTryConversation>;
}

export const TryConversationMutation = createMutation<Data, Variables>(
  mutation,
);
