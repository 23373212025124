import { onError } from 'apollo-link-error';
import forEach from 'lodash/forEach';

import { logger } from '../Logger';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    forEach(graphQLErrors, graphQLError => {
      logger.printDebug(
        `[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`,
      );
    });
  }

  if (networkError) {
    logger.printDebug(`[Network error]: ${networkError}`);
  }
});
