import forEach from 'lodash/forEach';

import { SDKReferrerInput } from '../models';

const getJsonFromUrl = (url: string) => {
  const query = url.substr(1);
  const result: any = {};
  forEach(query.split('&'), function (part) {
    const item = part.split('=');
    result[item[0].toLowerCase()] = decodeURIComponent(item[1]);
  });
  return result;
};

export const getSDKReferrerInput = (
  location: Location,
  document: Document,
): SDKReferrerInput => {
  const json = getJsonFromUrl(location.search);
  return {
    utmMedium: json.utm_medium,
    utmSource: json.utm_source,
    utmCampaign: json.utm_campaign,
    referrer: encodeURIComponent(document.referrer) || undefined,
  };
};
