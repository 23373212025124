import { useCallback, Ref } from 'react';

export const useCombinedRef = <T>(...refs: Array<Ref<T>>): Ref<T> => {
  const combinedRef: Ref<T> = useCallback(
    (val: T | null) => {
      refs.forEach(ref => {
        if (typeof ref === 'function') {
          ref(val);
        } else if (ref !== null) {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          ref.current = val;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...refs],
  );

  return combinedRef;
};
