import React, { FunctionComponent } from 'react';
import {
  StylesProvider,
  StylesOptions,
  createGenerateClassName,
  ThemeProvider as MaterialThemeProvider,
  ThemeProviderProps as MaterialThemeProviderProps,
} from '@material-ui/styles';

import { theme, Theme } from './theme';

const generateClassName = createGenerateClassName({
  seed: 'alli-sdk',
});

interface ThemeProviderProps
  extends StylesOptions,
    Omit<MaterialThemeProviderProps<Theme>, 'theme'> {
  theme?: MaterialThemeProviderProps<Theme>['theme'];
}

/**
 * @deprecated Use theme/ThemeProvider
 */
export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  theme: themeProp,
  children,
  ...other
}) => (
  <StylesProvider generateClassName={generateClassName} {...other}>
    <MaterialThemeProvider theme={themeProp || theme}>
      {children}
    </MaterialThemeProvider>
  </StylesProvider>
);
