import {
  MutationFunction,
  MutationFetchResult,
  MutationFunctionOptions,
} from '@apollo/react-common';
import uniqBy from 'lodash/uniqBy';

import {
  Nullable,
  ID,
  MediaInput,
  FileInput,
  ServerError,
  ConversationState,
  VariableInput,
  Typename,
  FillSlotRichUXInput,
  ContactAgentByEmailInput,
  SendFormInput,
} from '@/models';
import { makeMutation } from '@/graphql';

import { UpdateQueryType, Chat, ChatEdge } from '../ConversationChatsQuery';

import mutation from './sendChatMutation.graphql';

interface UserSendChat extends Typename<'UserSendChat'> {
  chat: Nullable<Chat>;
  responses: Nullable<Chat[]>;
  state: Nullable<ConversationState>;
  errors: Nullable<ServerError[]>;
}

export interface Data {
  sendChat: Nullable<UserSendChat>;
}

export interface Variables {
  chatId?: ID;
  conversationId: ID;
  message?: string;
  mediaInput?: MediaInput;
  fileInput?: FileInput;
  fillSlotInput?: FillSlotRichUXInput;
  selected?: number;
  choices?: number[];
  contactAgentByEmailInput?: ContactAgentByEmailInput;
  sendFormInput?: SendFormInput;
  lastChatId?: ID;
  variables?: VariableInput[];
  textChoice?: boolean;
}

export const useSendChatMutation = makeMutation<Data, Variables>(mutation);

export const applyWithRetry = (
  mutationFn: MutationFunction<Data, Variables>,
  vars: MutationFunctionOptions<Data, Variables>,
  shouldRetry: (
    data: void | MutationFetchResult<Data>,
  ) => boolean | undefined | void | null,
) => {
  return mutationFn(vars).then(data => {
    if (shouldRetry(data)) {
      return mutationFn(vars);
    }
    return data;
  });
};

export const sendChatMutationCallback = (updateQuery: UpdateQueryType) => (
  data: void | MutationFetchResult<Data>,
) => {
  updateQuery(prev => {
    if (
      !(
        data &&
        data.data &&
        data.data.sendChat &&
        data.data.sendChat.responses
      ) ||
      !prev?.chats
    ) {
      return prev;
    }
    const { chats } = prev;
    const responses = data.data.sendChat.responses.filter(
      c => !chats.edges.map(a => a.node.id).includes(c.id),
    );

    return {
      ...prev,
      chats: {
        ...chats,
        edges: uniqBy(
          [
            ...(chats.edges || []),
            ...responses.map<ChatEdge>(a => ({
              cursor: `${a.id}`,
              node: a,
              __typename: 'ChatEdge',
            })),
          ],
          'cursor',
        ),
      },
    };
  });
};
