import React, { useCallback } from 'react';

export function setRef<T>(ref: React.Ref<T>, value: T | null) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}

export function useForkRef<T>(...refs: React.Ref<T>[]) {
  return useCallback(
    (node: T | null) => {
      refs.forEach(ref => {
        setRef(ref, node);
      });
    },
    [refs],
  );
}
