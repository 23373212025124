import { Nullable, Int, Typename } from '../graphql';

import { SDKSettingsMedia } from './SDKSettingsMedia';

export enum ChatBubblePositionEnum {
  LEFT_TOP = 'LEFT_TOP',
  RIGHT_TOP = 'RIGHT_TOP',
  LEFT_BOTTOM = 'LEFT_BOTTOM',
  RIGHT_BOTTOM = 'RIGHT_BOTTOM',
}

export enum ChatWindowStyle {
  NORMAL = 'NORMAL',
  COMPACT = 'COMPACT',
}

export enum ChatOptionAlign {
  DEFAULT = 'DEFAULT',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum ChatTextAlign {
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum PopUpBoxIconOption {
  AVATAR = 'AVATAR',
  NO_ICON = 'NO_ICON',
  CUSTOM = 'CUSTOM',
}

export interface SDKSettings extends Typename<'SDKSettings'> {
  avatar: Nullable<SDKSettingsMedia>;
  companyLogo: Nullable<SDKSettingsMedia>;
  companyName: Nullable<string>;
  showContactAgentButton: Nullable<boolean>;
  showSendConversationButton: Nullable<boolean>;
  hasNewConversationButton: Nullable<boolean>;
  hasBackButton: Nullable<boolean>;
  chatOptionAlign: Nullable<ChatOptionAlign>;
  chatTextAlign: Nullable<ChatTextAlign>;
  chatWindowStyle: Nullable<ChatWindowStyle>;
  themeColor: Nullable<string>;
  componentsHeaderColor: Nullable<string>;
  componentsColor: Nullable<string>;
  componentsTextColor: Nullable<string>;
  chatBubblePosition: Nullable<ChatBubblePositionEnum>;
  useAutoComplete: Nullable<boolean>;
  numAutoComplete: Nullable<Int>;
  chatWindowWidth: Nullable<Int>;
  chatWindowHeight: Nullable<Int>;
  popUpLauncher: Nullable<SDKSettingsMedia>;
  popUpWidth: Nullable<Int>;
  popUpCustomMessage: Nullable<string>;
  popUpUseCustomMessage: Nullable<boolean>;
  popUpShowMessageFirstArrive: Nullable<boolean>;
  popUpShowMessageAfterClose: Nullable<boolean>;
  popUpBoxIconOption: Nullable<PopUpBoxIconOption>;
  popUpBoxIcon: Nullable<SDKSettingsMedia>;
}
