import 'regenerator-runtime/runtime';
import 'core-js/stable/symbol';
import 'core-js/stable/error';
import 'core-js/stable/aggregate-error';
import 'core-js/stable/array';
import 'core-js/stable/data-view';
import 'core-js/stable/date';
import 'core-js/stable/escape';
import 'core-js/stable/function';
import 'core-js/stable/global-this';
import 'core-js/stable/json';
import 'core-js/stable/map';
import 'core-js/stable/math';
import 'core-js/stable/number';
import 'core-js/stable/object';
import 'core-js/stable/parse-float';
import 'core-js/stable/parse-int';
import 'core-js/stable/promise';
import 'core-js/stable/reflect';
import 'core-js/stable/regexp';
import 'core-js/stable/set';
import 'core-js/modules/es.string.at-alternative';
import 'core-js/stable/string/code-point-at';
import 'core-js/stable/string/ends-with';
import 'core-js/stable/string/from-code-point';
import 'core-js/stable/string/includes';
import 'core-js/stable/string/iterator';
import 'core-js/stable/string/match';
import 'core-js/stable/string/match-all';
import 'core-js/stable/string/pad-end';
import 'core-js/stable/string/pad-start';
import 'core-js/stable/string/raw';
import 'core-js/stable/string/repeat';
import 'core-js/stable/string/replace';
import 'core-js/stable/string/replace-all';
import 'core-js/stable/string/search';
import 'core-js/stable/string/split';
import 'core-js/stable/string/starts-with';
import 'core-js/stable/string/substr';
import 'core-js/stable/string/trim';
import 'core-js/stable/string/trim-end';
import 'core-js/stable/string/trim-start';
import 'core-js/stable/string/anchor';
import 'core-js/stable/string/big';
import 'core-js/stable/string/blink';
import 'core-js/stable/string/bold';
import 'core-js/stable/string/fixed';
import 'core-js/stable/string/fontcolor';
import 'core-js/stable/string/fontsize';
import 'core-js/stable/string/italics';
import 'core-js/stable/string/link';
import 'core-js/stable/string/small';
import 'core-js/stable/string/strike';
import 'core-js/stable/string/sup';
import 'core-js/stable/typed-array';
import 'core-js/stable/unescape';
import 'core-js/stable/weak-map';
import 'core-js/stable/weak-set';
import 'core-js/stable/atob';
import 'core-js/stable/btoa';
import 'core-js/stable/dom-collections';
import 'core-js/stable/dom-exception';
import 'core-js/modules/web.immediate';
import 'core-js/stable/queue-microtask';
import 'core-js/stable/self';
import 'core-js/stable/structured-clone';
import 'core-js/stable/url';
import 'whatwg-fetch';
import 'web-streams-polyfill';

import { renderApp } from './renderApp';
import { SettingFactory, SdkType } from './SettingFactory';
import { Alli as AlliAPI } from './API';
import { ALLI_CONTAINER } from './constants';
import { AlliEventKeyMap } from './Alli.const';
import { initializer } from './initializer';

initializer(async () => {
  const setting = SettingFactory.getInstance(
    SdkType.WEB_SDK,
    `${process.env.RELEASE}`,
  );
  const Alli = AlliAPI(setting);
  (window as any).Alli = Alli;

  const render = () => {
    const id = ALLI_CONTAINER;
    let container = document.getElementById(id);

    if (!container) {
      container = document.createElement('div');
      container.id = id;
      document.body.appendChild(container);
    }

    renderApp(container as HTMLDivElement, setting);
  };

  setting.addListener(AlliEventKeyMap.Init, render);
});
