import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';

import { Styles, StyledComponentProps, makeStyles } from '@/styles';
import { capitalize } from '@/utils';

const SIZE = 44;

const getRelativeValue = (value: number, min: number, max: number) =>
  (Math.min(Math.max(min, value), max) - min) / (max - min);

const easeOut = (t: number) => {
  let rel = getRelativeValue(t, 0, 1);
  // https://gist.github.com/gre/1650294
  rel = (rel -= 1) * rel * rel + 1;
  return rel;
};

const easeIn = (t: number) => t * t;

const styles: Styles<
  | 'root'
  | 'static'
  | 'indeterminate'
  | 'colorPrimary'
  | 'colorSecondary'
  | 'svg'
  | 'circle'
  | 'circleStatic'
  | 'circleIndeterminate'
  | '@keyframes circular-rotate'
  | '@keyframes circular-dash'
  | 'circleDisableShrink'
> = theme => ({
  root: {
    display: 'inline-block',
  },
  static: {
    transition: theme.transitions.create('transform'),
  },
  indeterminate: {
    animation: '$circular-rotate 1.4s linear infinite',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  colorBlue: {
    color: theme.palette.blue.main,
  },
  svg: {
    display: 'block', // Keeps the progress centered
  },
  circle: {
    stroke: 'currentColor',
    // Use butt to follow the specification, by chance, it's already the default CSS value.
    // strokeLinecap: 'butt',
  },
  circleStatic: {
    transition: theme.transitions.create('stroke-dashoffset'),
  },
  circleIndeterminate: {
    animation: '$circular-dash 1.4s ease-in-out infinite',
    // Some default value that looks fine waiting for the animation to kicks in.
    strokeDasharray: '80px, 200px',
    strokeDashoffset: '0px', // Add the unit to fix a Edge 16 and below bug.
  },
  '@keyframes circular-rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes circular-dash': {
    '0%': {
      strokeDasharray: '1px, 200px',
      strokeDashoffset: '0px',
    },
    '50%': {
      strokeDasharray: '100px, 200px',
      strokeDashoffset: '-15px',
    },
    '100%': {
      strokeDasharray: '100px, 200px',
      strokeDashoffset: '-125px',
    },
  },
  circleDisableShrink: {
    animation: 'none',
  },
});

const useStyles = makeStyles(styles, { name: 'CircularProgress' });
const spinnerClasses = cn('alli-sdk-Spinner');

export interface CircularProgressProps
  extends StyledComponentProps<typeof styles>,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  color?: 'primary' | 'secondary' | 'blue' | 'inherit';
  disableShrink?: boolean;
  size?: number | string;
  thickness?: number;
  value?: number;
  variant?: 'determinate' | 'indeterminate' | 'static';
}

export const CircularProgress = forwardRef<
  HTMLDivElement,
  CircularProgressProps
>(
  (
    {
      color = 'primary',
      disableShrink,
      size = 40,
      thickness = 3.6,
      value = 0,
      variant = 'indeterminate',
      classes: classesProp,
      children,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp });
    const circleStyle: React.CSSProperties = {};
    const rootStyle: React.CSSProperties = {};
    const rootProps: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > = {};

    if (variant === 'determinate' || variant === 'static') {
      const circumference = 2 * Math.PI * ((SIZE - thickness) / 2);
      circleStyle.strokeDasharray = circumference.toFixed(3);
      rootProps['aria-valuenow'] = Math.round(value);

      if (variant === 'static') {
        circleStyle.strokeDashoffset = `${(
          ((100 - value) / 100) *
          circumference
        ).toFixed(3)}px`;
        rootStyle.transform = 'rotate(-90deg)';
      } else {
        circleStyle.strokeDashoffset = `${(
          easeIn((100 - value) / 100) * circumference
        ).toFixed(3)}px`;
        rootStyle.transform = `rotate(${(easeOut(value / 70) * 270).toFixed(
          3,
        )}deg)`;
      }
    }

    return (
      <div
        role="progressbar"
        {...rootProps}
        {...props}
        className={clsx(
          classes.root,
          {
            [classes[`color${capitalize(color)}` as keyof typeof classes]]:
              color !== 'inherit',
            [classes.indeterminate]: variant === 'indeterminate',
            [classes.static]: variant === 'static',
          },
          rootProps.className,
          spinnerClasses({
            color,
            variant,
            shrink: !disableShrink,
          }),
          props.className,
        )}
        style={{
          width: size,
          height: size,
          ...rootStyle,
          ...props.style,
        }}
        ref={ref}
      >
        <svg
          className={clsx(classes.svg, spinnerClasses('svg'))}
          viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
        >
          <circle
            className={clsx(
              classes.circle,
              {
                [classes.circleIndeterminate]: variant === 'indeterminate',
                [classes.circleStatic]: variant === 'static',
                [classes.circleDisableShrink]: disableShrink,
              },
              spinnerClasses('circle'),
            )}
            style={circleStyle}
            cx={SIZE}
            cy={SIZE}
            r={(SIZE - thickness) / 2}
            fill="none"
            strokeWidth={thickness}
          />
        </svg>
      </div>
    );
  },
);
