import { ID, Node, Nullable, Typename } from './graphql';

export enum NLUModelLanguageEnum {
  en = 'EN',
  ko = 'KO',
  ja = 'JA',
}

export enum ProjectUsageType {
  ALL = 'ALL',
  ANSWER_BOT = 'ANSWER_BOT',
  COGNITIVE_SEARCH = 'COGNITIVE_SEARCH',
  GPT_MODE = 'GPT_MODE',
}

export interface Project extends Typename<'Project'>, Node {
  nluLanguage: Nullable<NLUModelLanguageEnum>;
  name: string;
  apiKey: string;
  usageType: ProjectUsageType;
  usePreviewPane: boolean;
}

export interface ProjectWhereUniqueInput {
  id: ID;
}
