import React, { FunctionComponent } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OperationVariables } from '@apollo/react-common';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import {
  Mutation as ApolloMutation,
  MutationComponentOptions,
} from '@apollo/react-components';
import { DocumentNode } from 'graphql';

// eslint-disable-next-line @typescript-eslint/ban-types
interface ApolloMutationComponent<P = {}> extends FunctionComponent<P> {
  mutation: DocumentNode;
}

interface ApolloMutationComponentOptions<
  TData = any,
  TVariables = OperationVariables
> extends Omit<MutationComponentOptions<TData, TVariables>, 'mutation'> {
  mutation?: MutationComponentOptions<TData, TVariables>['mutation'];
}

export function createMutation<TData = any, TVariables = OperationVariables>(
  doc: DocumentNode,
) {
  const Mutation: ApolloMutationComponent<
    ApolloMutationComponentOptions<TData, TVariables>
  > = props => (
    // @ts-ignore
    <ApolloMutation<TData, TVariables> mutation={doc} {...props} />
  );

  Mutation.mutation = doc;
  return Mutation;
}

export function makeMutation<TData = any, TVariables = OperationVariables>(
  doc: DocumentNode,
) {
  const useMutationHook = (options?: MutationHookOptions<TData, TVariables>) =>
    useMutation(doc, options);

  useMutationHook.mutation = doc;
  return useMutationHook;
}
