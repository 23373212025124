export enum ViewType {
  /**
   * ConversationList view
   */
  history = 'history',

  /**
   * Conversation view
   */
  conversation = 'conversation',
}
