import { getContrast } from 'polished';

const common = {
  black: '#000000',
  white: '#ffffff',
};

const grey = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161',
};

const light = {
  text: {
    primary: 'rgba(0,0,0,0.87)',
    secondary: 'rgba(0,0,0,0.58)',
    disabled: 'rgba(0,0,0,0.38)',
    hint: 'rgba(0,0,0,0.38)',
  },
  divider: 'rgba(0,0,0,0.12)',
  background: {
    paper: common.white,
    default: grey[50],
  },
  action: {
    activatedOpacity: 0.12,
    active: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    hover: 'rgba(0, 0, 0, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    selectedOpacity: 0.08,
  },
};

const dark = {
  text: {
    primary: common.white,
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: grey[800],
    default: '#303030',
  },
  action: {
    active: common.white,
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(255, 255, 255, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
};

const types = {
  light,
  dark,
};

const createPalette = () => {
  const type = 'light';
  const tonalOffset = 0.2;
  const contrastThreshold = 3;

  const getContrastText = (background: string) =>
    getContrast(background, dark.text.primary) >= contrastThreshold
      ? dark.text.primary
      : light.text.primary;

  return {
    // augmentColor,
    getContrastText,
    tonalOffset,
    contrastThreshold,
    common,
    grey,
    type,
    ...types[type],
    primary: {
      main: '#7f00f6',
      light: '#b84cff',
      dark: '#4100c2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FF5777',
      light: '#ff8ba6',
      dark: '#c6184c',
      contrastText: '#fff',
    },
    success: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#388e3c',
      light: '#81c784',
      main: '#4caf50',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    warning: {
      contrastText: 'rgba(0, 0, 0, 0.87)',
      dark: '#f57c00',
      light: '#ffb74d',
      main: '#ff9800',
    },
    error: {
      main: '#ca0000',
      light: '#ff4e2f',
      dark: '#910000',
      contrastText: '#fff',
    },
    blue: {
      main: '#0052FF',
      light: '#71a2ff',
      dark: '#004acb',
      contrastText: '#fff',
    },
    charcoalGrey: {
      main: '#343b43',
      light: '#5e656e',
      dark: '#0e151c',
      contrastText: '#fff',
    },
  };
};

export const palette = createPalette();
