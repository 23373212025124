
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChatVariableFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Variable"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"validationCustom"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"validationFailedMessage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":138}};
    doc.loc.source = {"body":"fragment ChatVariableFragment on Variable {\n  id\n  name\n  description\n  displayText\n  validationCustom\n  validationFailedMessage\n  type\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
