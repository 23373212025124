import { useMemo } from 'react';
import { atom, useRecoilCallback } from 'recoil';
import { v4 } from 'uuid';

import { ConfirmDialogProps } from './ConfirmDialog';

interface DialogState {
  key: string;
  options: ConfirmOptions | ((params: ConfirmOptionsParams) => ConfirmOptions);
}

export const confirmAtom = atom<DialogState[]>({
  key: 'Confirm',
  default: [],
});

interface ConfirmOptionsParams {
  key: string;
  cancel(): void;
}

type ConfirmOptions = Omit<ConfirmDialogProps, 'ref' | 'open'>;

interface ConfirmContextValue {
  enqueueConfirm(
    options:
      | ConfirmOptions
      | ((params: ConfirmOptionsParams) => ConfirmOptions),
  ): string;
  cancelConfirm(key: string): void;
}

export const useConfirm = (): ConfirmContextValue => {
  const enqueueConfirm = useRecoilCallback(
    ({ set }) => (
      options:
        | ConfirmOptions
        | ((params: ConfirmOptionsParams) => ConfirmOptions),
    ) => {
      const key = v4();
      set(confirmAtom, prev => [...prev, { key, options }]);
      return key;
    },
    [],
  );
  const cancelConfirm = useRecoilCallback(
    ({ set }) => (key: string) =>
      set(confirmAtom, prev => prev.filter(d => d.key !== key)),
    [],
  );
  const contextValue = useMemo<ConfirmContextValue>(
    () => ({ enqueueConfirm, cancelConfirm }),
    [cancelConfirm, enqueueConfirm],
  );
  return contextValue;
};
