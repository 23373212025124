import {
  Box,
  DialogActions,
  Dialog as DialogBase,
  DialogProps as DialogBaseProps,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { CheckCircle, Error, Info } from '@mui/icons-material';
import React, { forwardRef, useMemo } from 'react';

export interface ConfirmDialogProps extends Omit<DialogBaseProps, 'title'> {
  color?: 'default' | 'primary' | 'secondary' | 'error';
  title?: React.ReactNode;
  actions?: React.ReactNode;
  hideIcon?: boolean;
}

export const ConfirmDialog = forwardRef<HTMLDivElement, ConfirmDialogProps>(
  ({ color: colorProp, title, actions, hideIcon, children, ...other }, ref) => {
    const { color, icon } = useMemo(() => {
      if (colorProp === 'primary') {
        return { color: 'text.primary', icon: <CheckCircle color="inherit" /> };
      }
      if (colorProp === 'secondary') {
        return {
          color: 'text.secondary',
          icon: <CheckCircle color="inherit" />,
        };
      }
      if (colorProp === 'error') {
        return { color: 'error.main', icon: <Error color="inherit" /> };
      }
      return { color: 'text.primary', icon: <Info color="inherit" /> };
    }, [colorProp]);

    return (
      <DialogBase scroll="paper" {...other} ref={ref}>
        {title && (
          <DialogTitle
            typography="h6"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color,
            }}
          >
            {!hideIcon && (
              <Box
                sx={{
                  display: 'flex',
                  flexShrink: 0,
                  marginRight: 1,
                }}
              >
                {icon}
              </Box>
            )}

            {title}
          </DialogTitle>
        )}

        {children && (
          <DialogContent
            sx={{
              typography: 'body2',
              color: 'text.secondary',
            }}
          >
            {children}
          </DialogContent>
        )}

        {actions && <DialogActions>{actions}</DialogActions>}
      </DialogBase>
    );
  },
);

ConfirmDialog.displayName = 'ConfirmDialog';
