/**
 * @see alli_ios.ejs
 */
export function addEventForIOS(): (() => void)[] {
  const unsubscribers = [];
  if (window?.Alli && window?.webkit?.messageHandlers) {
    const conversationSubscription = window.Alli.subscribe(
      'conversation',
      (data: any) => {
        if (data.data) {
          if (
            window?.webkit?.messageHandlers?.onConversationStarted?.postMessage
          ) {
            window.webkit.messageHandlers.onConversationStarted.postMessage(
              JSON.stringify(data.data),
            );
          }
        } else if (data.error) {
          if (
            window?.webkit?.messageHandlers?.onConversationError?.postMessage
          ) {
            window.webkit.messageHandlers.onConversationError.postMessage(
              JSON.stringify(data.error),
            );
          }
        } else if (
          window?.webkit?.messageHandlers?.onConversationNotStarted?.postMessage
        ) {
          window.webkit.messageHandlers.onConversationNotStarted.postMessage(
            '',
          );
        }
      },
    );
    unsubscribers.push(conversationSubscription);

    const closeSubscription = window.Alli.subscribe('close', () => {
      if (window?.webkit?.messageHandlers?.onConversationClosed?.postMessage) {
        window.webkit.messageHandlers.onConversationClosed.postMessage('');
      }

      if (window?.webkit?.messageHandlers?.onClose?.postMessage) {
        window.webkit.messageHandlers.onClose.postMessage('');
      }
    });
    unsubscribers.push(closeSubscription);
  }

  if (window?.webkit?.messageHandlers?.onSDKLoaded?.postMessage) {
    window.webkit.messageHandlers.onSDKLoaded.postMessage('');
  }

  return unsubscribers;
}

/**
 * @see alli_android.ejs
 */
export function addEventForAndroid(): (() => void)[] {
  const unsubscribers = [];
  if (window?.Alli && window?.AlliObject) {
    const conversationSubscription = window.Alli.subscribe(
      'conversation',
      (data: any) => {
        if (data.data) {
          if (window?.AlliObject?.onConversationStarted) {
            window.AlliObject.onConversationStarted(JSON.stringify(data.data));
          }
        } else if (data.error) {
          if (window?.AlliObject?.onConversationError) {
            window.AlliObject.onConversationError(JSON.stringify(data.error));
          }
        } else if (window?.AlliObject?.onConversationNotStarted) {
          window.AlliObject.onConversationNotStarted('');
        }
      },
    );
    unsubscribers.push(conversationSubscription);

    const closeSubscription = window.Alli.subscribe('close', () => {
      if (window?.AlliObject?.onConversationClosed) {
        window.AlliObject.onConversationClosed('');
      }

      if (window?.AlliObject?.onClose) {
        window.AlliObject.onClose('');
      }
    });
    unsubscribers.push(closeSubscription);
  }

  if (window?.AlliObject?.onSDKLoaded) {
    window.AlliObject.onSDKLoaded('');
  }

  return unsubscribers;
}
