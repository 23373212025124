import React, { forwardRef, useRef, useState, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
import Cookies from 'js-cookie';

import { ALLI_Z_INDEX } from '@/constants';
import { makeStyles } from '@/styles';
import { useWindowSize } from '@/hooks';
import { isIE } from '@/utils';

import {
  Device,
  isFullScreenSDK,
  isNativeSDK,
  useEnvironment,
} from './EnvironmentProvider';
import { useSettings } from './SettingsProvider';
import { Paper } from './Paper';
import { IconButton, Tooltip } from './Button';
import { Close } from './Icons';

const conversationClassName = cn('conversation');

const PADDING_MARGIN_OFFSET = 88;
const COOKIE_KEY_PREFIX = 'alli-error-notice';
const COOKIE_VALUE = 'true';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.htmlFontSize,
    color: theme.palette.common.white,
    fontWeight: 'normal',
    WebkitTextSizeAdjust: '100%',
    '&$deviceWeb, &$deviceSmallWeb': {
      position: 'fixed',
      zIndex: ALLI_Z_INDEX,
    },
  },
  paper: {
    ...theme.typography.body2,
    backgroundColor: '#313131',
    color: 'inherit',
    position: 'absolute',
    bottom: '100%',
    margin: theme.spacing(2),
    wordBreak: isIE() ? 'break-all' : 'break-word',
    wordWrap: 'break-word',
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2, 5, 2, 2),
    animation: '$showUp 200ms ease-in',
    right: 0,
    left: 'auto',
  },
  tooltip: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  '@keyframes showUp': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  deviceWeb: {},
  deviceSmallWeb: {},
}));

const getErrorNoticeCookieKey = (apikey: string, placement: string): string => {
  return `${COOKIE_KEY_PREFIX}-${apikey}-${placement}`;
};

const getErrorNoticeCookieResult = (
  apikey: string,
  placement: string,
): boolean => {
  const key = getErrorNoticeCookieKey(apikey, placement);
  const ck = Cookies.get(key);
  if (ck) {
    return ck.toLocaleLowerCase() === COOKIE_VALUE;
  }
  return false;
};

const setErrorNoticeCookie = (
  apikey: string,
  placement: string,
  value: string,
) => {
  const key = getErrorNoticeCookieKey(apikey, placement);
  Cookies.set(key, value, { expires: 10, sameSite: 'None', secure: true });
};

interface ErrorNoticeProps extends React.HTMLAttributes<HTMLDivElement> {}

const ErrorNotice = forwardRef<HTMLDivElement, ErrorNoticeProps>(
  ({ children, ...other }, ref) => {
    const intl = useIntl();
    const classes = useStyles();
    const settings = useSettings();
    const environment = useEnvironment();
    const containerRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    const [open, setOpen] = useState<boolean>(true);

    const conversationContainer =
      settings &&
      settings.styleOptions &&
      settings.styleOptions.conversationContainer;

    const errorNoticeFromCookie = useMemo(
      () =>
        settings && settings.apiKey && settings.placement
          ? getErrorNoticeCookieResult(settings.apiKey, settings.placement)
          : false,
      [settings],
    );

    const getPositionOffsets = (): {
      bottomOffset: number;
      rightOffset: number;
    } => {
      let bottomOffset = 0;
      let rightOffset = 0;

      if (conversationContainer) {
        if (conversationContainer.bottom) {
          bottomOffset = conversationContainer.bottom;
        }
        if (conversationContainer.right) {
          rightOffset = conversationContainer.right;
        }
      }
      return {
        bottomOffset,
        rightOffset,
      };
    };

    const { bottomOffset, rightOffset } = getPositionOffsets();

    const initialPositions = {
      bottom: `${bottomOffset || 0}px`,
      right: `${rightOffset || 0}px`,
    };

    const widthValue = Math.min(
      250,
      width - (PADDING_MARGIN_OFFSET + rightOffset),
    );

    if (
      !settings ||
      isNativeSDK(environment.device) ||
      !open ||
      errorNoticeFromCookie
    ) {
      return null;
    }

    return (
      <div
        {...other}
        ref={containerRef}
        className={clsx(
          classes.root,
          {
            [classes.deviceWeb]: environment.device === Device.web,
            [classes.deviceSmallWeb]: isFullScreenSDK(environment.device),
          },
          conversationClassName('container'),
          other.className,
        )}
        style={{
          ...initialPositions,
          ...other.style,
        }}
      >
        <Paper
          elevation={8}
          className={classes.paper}
          style={{
            width: `${widthValue}px`,
          }}
        >
          {settings.errorNoticeMessage || (
            <FormattedMessage
              id="ErrorNotice.Message"
              defaultMessage="Answer bot cannot be loaded at the moment. Please try again later."
              description="Error nitice message"
            />
          )}

          <Tooltip
            className={classes.tooltip}
            tooltipText={intl.formatMessage({
              id: 'DisconnectedIndicator.Message',
              defaultMessage:
                'We are unable to establish a connection to the server.',
              description: 'WebSocket disconnected message',
            })}
          >
            <IconButton
              size="small"
              color="inherit"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => {
                setOpen(false);
                if (settings && settings.apiKey && settings.placement) {
                  setErrorNoticeCookie(
                    settings.apiKey,
                    settings.placement,
                    COOKIE_VALUE,
                  );
                }
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Paper>
      </div>
    );
  },
);

export default ErrorNotice;
