
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ChatOptionInfoFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ChatOptionInfo"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shortenOption"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"longOption"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"urlVariable"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatVariableFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"optionBoxTemplate"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"style"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bgColor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bold"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"fontColor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"italic"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lineColor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"underline"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"reusable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"media"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMediaFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":339}};
    doc.loc.source = {"body":"#import './ChatVariableFragment.graphql'\n\nfragment ChatOptionInfoFragment on ChatOptionInfo {\n  shortenOption\n  longOption\n  url\n  urlVariable {\n    ...ChatVariableFragment\n  }\n  optionBoxTemplate\n\n  style {\n    bgColor\n    bold\n    fontColor\n    italic\n    lineColor\n    underline\n  }\n  reusable\n\n  media {\n    ...ChatMediaFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ChatVariableFragment.graphql').definitions));


      module.exports = doc;
    
