import React, {
  Component,
  createContext,
  ComponentType,
  FunctionComponent,
  useContext,
} from 'react';

import { noop } from '../utils';

export interface InjectedTryModeProps {
  isTryMode: boolean;
  enableTryMode(): void;
  disableTryMode(): void;
}

const TryModeContext = createContext<InjectedTryModeProps>({
  isTryMode: false,
  enableTryMode: noop,
  disableTryMode: noop,
});

export const useTryMode = () => useContext(TryModeContext);

export function withTryMode<T extends InjectedTryModeProps>(
  WrappedComponent: ComponentType<T>,
): FunctionComponent<Omit<T, keyof InjectedTryModeProps>> {
  return props => (
    <TryModeContext.Consumer>
      {injectedProps => (
        // @ts-ignore
        <WrappedComponent {...props} {...injectedProps} />
      )}
    </TryModeContext.Consumer>
  );
}

interface TryModeProviderProps {
  isTryMode?: boolean;
}

interface TryModeProviderState {
  contextValue: InjectedTryModeProps;
}

export class TryModeProvider extends Component<
  TryModeProviderProps,
  TryModeProviderState
> {
  // eslint-disable-next-line react/sort-comp
  private enableTryMode = () => {
    this.setState(prev => ({
      ...prev,
      contextValue: {
        ...prev.contextValue,
        isTryMode: true,
      },
    }));
  };

  private disableTryMode = () => {
    this.setState(prev => ({
      ...prev,
      contextValue: {
        ...prev.contextValue,
        isTryMode: false,
      },
    }));
  };

  public state: TryModeProviderState = {
    contextValue: {
      isTryMode: !!this.props.isTryMode,
      enableTryMode: this.enableTryMode,
      disableTryMode: this.disableTryMode,
    },
  };

  public render() {
    return (
      <TryModeContext.Provider value={this.state.contextValue}>
        {this.props.children}
      </TryModeContext.Provider>
    );
  }
}
