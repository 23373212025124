import { Settings } from './Settings';
import { logger } from './Logger';
import { AlliClient } from './AlliClient';

export enum SdkType {
  WEB_SDK = 'WEB_SDK',
  INTERNAL_SDK = 'INTERNAL_SDK',
  IOS_SDK = 'IOS_SDK',
  ANDROID_SDK = 'ANDROID_SDK',
}

let setting: Settings | null = null;

export class SettingFactory {
  public static getInstance = (sdkType: SdkType, sdkVersion: string) => {
    if (setting === null) {
      setting = new Settings(
        new AlliClient(null, null, 'us', null, null, sdkType, sdkVersion),
        logger,
      );
    }
    return setting;
  };
}
