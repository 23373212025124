import { CSSProperties } from 'react';

const htmlFontSize = 16;

const round = (value: number) => Math.round(value * 1e5) / 1e5;

export type Variant =
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

type TypographyStyle = Pick<
  CSSProperties,
  'fontWeight' | 'fontSize' | 'lineHeight' | 'letterSpacing'
>;

export const createTypography = () => {
  const fontSize = 14;
  const fontWeightLight: CSSProperties['fontWeight'] = 300;
  const fontWeightRegular: CSSProperties['fontWeight'] = 400;
  const fontWeightMedium: CSSProperties['fontWeight'] = 500;
  const fontWeightBold: CSSProperties['fontWeight'] = 700;
  const fontFamily =
    "Roboto, 'Noto Sans JP', 'Noto Sans KR', 'Helvetica Neue', sans-serif";

  const buildVariant = (
    fontWeight: CSSProperties['fontWeight'],
    size: number,
    lineHeight: CSSProperties['lineHeight'],
    letterSpacing: number,
  ): TypographyStyle => ({
    fontWeight,
    fontSize: `${size}px`,
    lineHeight,
    letterSpacing: `${round(letterSpacing / size)}em`,
  });

  return {
    fontSize,
    fontFamily,
    htmlFontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h5: buildVariant(fontWeightRegular, 24, 1.334, 0),
    h6: buildVariant(fontWeightMedium, 20, 1.6 /* TODO 1.6 */, 0.15),
    subtitle1: buildVariant(fontWeightRegular, 16, 1.5, 0.15),
    subtitle2: buildVariant(fontWeightMedium, 14, 1.71 /* TODO 1.57 */, 0.1),
    body1: buildVariant(fontWeightRegular, 16, 1.5, 0.15),
    body2: buildVariant(fontWeightRegular, 14, 1.43, 0.15),
    button: buildVariant(fontWeightMedium, 14, 1.75, 0.4),
    caption: buildVariant(fontWeightRegular, 12, 1.33 /* TODO 1.66 */, 0.4),
    overline: buildVariant(fontWeightRegular, 12, 2.66, 1),
  };
};

export const typography = createTypography();
export type Typography = ReturnType<typeof createTypography>;
