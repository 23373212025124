import { Typename, Node, JSONString, Nullable, Float } from '../graphql';
import { ChatAgent } from './ChatAgent';

export enum ChatEvent {
  SEND_CONVERSATION_TO_EMAIL = 'SEND_CONVERSATION_TO_EMAIL',
  AGENT_INTERNAL_MESSAGE = 'AGENT_INTERNAL_MESSAGE',
  AGENT_ENTER = 'AGENT_ENTER',
}

export interface EventChat extends Typename<'EventChat'>, Node {
  createdAt: Float;
  eventType: ChatEvent;
  eventFallbackMessage: string;
  eventAdditionalInfo: Nullable<JSONString>;
  enteringAgent: Nullable<ChatAgent>;
}
