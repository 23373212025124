import { JSONString, Nullable, Typename } from './graphql';

export enum MallyError {
  alreadyActivated = 'ALREADY_ACTIVATED',
  alreadyAssigned = 'ALREADY_ASSIGNED',
  alreadySignup = 'ALREADY_SIGNUP',
  campaignAlreadyStarted = 'CAMPAIGN_ALREADY_STARTED',
  campaignEmptyChatFlow = 'CAMPAIGN_EMPTY_CHAT_FLOW',
  campaignDateFailure = 'CAMPAIGN_DATE_FAILURE',
  consistencyError = 'CONSISTENCY_ERROR',
  detailError = 'DETAIL_ERROR',
  doesNotExist = 'DOES_NOT_EXIST',
  duplicated = 'DUPLICATED',
  invalidAgent = 'INVALID_AGENT',
  invalidEmailOrPassword = 'INVALID_EMAIL_OR_PASSWORD',
  invalidParameter = 'INVALID_PARAMETER',
  invalidPassword = 'INVALID_PASSWORD',
  invalidState = 'INVALID_STATE',
  invalidToken = 'INVALID_TOKEN',
  notActivated = 'NOT_ACTIVATED',
  notMatchTarget = 'NOT_MATCH_TARGET',
  raceCondition = 'RACE_CONDITION',
  reloadConversation = 'RELOAD_CONVERSATION',
  restrictedFile = 'RESTRICTED_FILE',
  tooMany = 'TOO_MANY',
  usedVariable = 'USED_VARIABLE',
  usedTarget = 'USED_TARGET',
}

export interface ServerError extends Typename<'Error'> {
  key: MallyError;
  message: Nullable<string>;
  field: Nullable<string>;
  info: Nullable<JSONString>;
}
