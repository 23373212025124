import React, {
  Component,
  createContext,
  ComponentType,
  FunctionComponent,
  useContext,
} from 'react';

export interface InjectedPreviewModeProps {
  isPreviewMode: boolean;
}

const PreviewModeContext = createContext<InjectedPreviewModeProps>({
  isPreviewMode: false,
});

export const usePreviewMode = () => useContext(PreviewModeContext);

export function withPreviewMode<T extends InjectedPreviewModeProps>(
  WrappedComponent: ComponentType<T>,
): FunctionComponent<Omit<T, keyof InjectedPreviewModeProps>> {
  return props => (
    <PreviewModeContext.Consumer>
      {injectedProps => (
        // @ts-ignore
        <WrappedComponent {...props} {...injectedProps} />
      )}
    </PreviewModeContext.Consumer>
  );
}

interface PreviewModeProviderProps {
  isPreviewMode?: boolean;
}

interface PreviewModeProviderState {
  contextValue: InjectedPreviewModeProps;
}

export class PreviewModeProvider extends Component<
  PreviewModeProviderProps,
  PreviewModeProviderState
> {
  public state: PreviewModeProviderState = {
    contextValue: {
      isPreviewMode: !!this.props.isPreviewMode,
    },
  };

  public render() {
    return (
      <PreviewModeContext.Provider value={this.state.contextValue}>
        {this.props.children}
      </PreviewModeContext.Provider>
    );
  }
}
