import {
  ID,
  JSONString,
  Typename,
  Nullable,
  Int,
  DateTime,
  Node,
} from './graphql';

export enum MediaType {
  image = 'IMAGE',
  video = 'VIDEO',
}

// max file size 75MB
export const MAX_FILE_SIZE = 75 * 1048576;

// max file size 10MB
export const MAX_IMAGE_FILE_SIZE = 10 * 1048576;

export type Upload = File | Blob | FileList;

export interface MediaInput {
  mediaType?: MediaType;
  metaInfo?: JSONString;
  id?: ID;
  url?: string;
  file?: Upload;
}

export interface Media extends Typename<'Media'>, Node {
  mediaType: Nullable<string>;
  url: string;
  metaInfo: Nullable<JSONString>;
  height: Nullable<Int>;
  width: Nullable<Int>;
  createdAt: Nullable<DateTime>;
  modifiedAt: Nullable<DateTime>;
  resizedUrls: Nullable<string[]>;
}
