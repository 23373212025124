import { ID, Int, Nullable, Typename } from '../graphql';
import { FileInput } from '../FileType';

import { ChatFile } from './ChatFile';
import { ChatVariable } from './ChatVariable';
import { ChatFormValueInfo } from './ChatFormValueInfo';

export enum FormVariableInputType {
  text = 'TEXT',
  button = 'BUTTON',
  file = 'FILE',
  dynamic = 'MEDIA',
  dropdown = 'DROPDOWN',
}

export interface ChatFormValue extends Typename<'FormValue'> {
  display: string;
  mandatory: boolean;
  variable: Nullable<ChatVariable>;
  value: Nullable<string>;
  file: Nullable<ChatFile>;
  choices: Nullable<Int[]>;
  multipleOption: Nullable<boolean>;
  formValueInfos: Nullable<ChatFormValueInfo[]>;
  inputType: Nullable<FormVariableInputType>;
}

export interface FormValueInput {
  variable: ID;
  value: Nullable<string>;
  file: Nullable<FileInput>;
  choices: Nullable<Int[]>;
}
