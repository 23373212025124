import * as React from 'react';
import noop from 'lodash/noop';

import { ViewType } from './Models';

type AppContextValue = {
  changeViewType: (nextViewType: ViewType) => void;
};

export const AppContext = React.createContext<AppContextValue>({
  changeViewType: noop,
});

export interface AppContextProviderProps extends AppContextValue {
  children: React.ReactNode;
}

export const AppContextProvider = ({
  children,
  ...others
}: AppContextProviderProps): JSX.Element => {
  const contextValue = React.useMemo(() => others, [others]);
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export const useAppContext = (): AppContextValue => {
  return React.useContext(AppContext);
};
