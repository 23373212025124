import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { makeStyles, StyledComponentProps } from '@/styles';

import { CircularProgress } from '../CircularProgress';

import { useStyles as useCommonStyles } from './common';

const useStyles = makeStyles<
  Record<string, unknown>,
  keyof ReturnType<typeof useCommonStyles>
>(theme => ({
  root: {},
  title: {
    marginTop: theme.spacing(2),
  },
  description: {},
  action: {},
}));

export interface LoadingStateProps
  extends StyledComponentProps<keyof ReturnType<typeof useStyles>>,
    React.HTMLAttributes<HTMLDivElement> {
  hideTitle?: boolean;
}

const LoadingState = forwardRef<HTMLDivElement, LoadingStateProps>(
  ({ hideTitle, classes: classesProp, children, ...other }, ref) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles({ classes: classesProp });

    return (
      <div
        {...other}
        className={clsx(commonClasses.root, classes.root, other.className)}
        ref={ref}
      >
        <CircularProgress color="blue" />

        {!hideTitle && (
          <div className={clsx(commonClasses.title, classes.title)}>
            <FormattedMessage
              id="LoadingState.Title"
              defaultMessage="Just a second"
              description="Loading state title text"
            />
          </div>
        )}

        {children && (
          <div className={clsx(commonClasses.description, classes.description)}>
            {children}
          </div>
        )}
      </div>
    );
  },
);

LoadingState.displayName = 'LoadingState';
export default LoadingState;
