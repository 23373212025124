import { Component, ErrorInfo } from 'react';
import { captureException } from '@sentry/react';

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public componentDidCatch(error: Error, info: ErrorInfo) {
    captureException({ ...error, info });
    console.error(error, info);
  }

  public render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
