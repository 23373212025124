import { Node, Nullable, Typename } from './graphql';

export enum VariableType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATETIME = 'DATETIME',
  FILE = 'FILE',
}

export interface Variable extends Typename<'Variable'>, Node {
  name: string;
  description: string;
  displayText: string;
  type: VariableType;
  validationCustom: Nullable<string>;
  validationFailedMessage: Nullable<string>;
}

export interface VariableInput {
  name: string;
  value: string;
}
