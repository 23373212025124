export const downloadFile = async (
  url: string | null,
  filename: string,
): Promise<void> => {
  if (url !== null) {
    const anchorLink = document.createElement('a');
    anchorLink.style.display = 'none';
    anchorLink.href = url;
    anchorLink.target = '_blank';
    anchorLink.download = filename;
    anchorLink.click();
  }
};
