import { useState, useEffect } from 'react';

import { JSONString } from '@/models';

import { useEventCallback } from './useEventCallback';

export interface UseJSONStringState<T = any> {
  data: T | null;
  error: Error | null;
}

export interface UseJSONStringOptions {
  data: JSONString;
  onError?(error: Error): void;
}

const parseJSONString = <T>(data: JSONString): UseJSONStringState<T> => {
  try {
    const parsed: T = JSON.parse(data);

    return {
      data: parsed,
      error: null,
    };
  } catch (err) {
    return {
      data: null,
      error: err as Error,
    };
  }
};

export function useJSONString<T = any>({
  data,
  onError,
}: UseJSONStringOptions): UseJSONStringState<T> {
  const [state, setState] = useState<UseJSONStringState>(parseJSONString(data));

  const handleError = useEventCallback(
    (err: Error) => {
      if (onError) {
        onError(err);
      }
    },
    [onError],
  );

  useEffect(() => {
    const newState = parseJSONString(data);

    if (newState.error) {
      handleError(newState.error);
    }

    setState(newState);
  }, [data, handleError]);

  return state;
}
