import { makeStyles } from '@/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  description: {
    ...theme.typography.subtitle1,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    margin: theme.spacing(1.5, 0, 0, 0),
  },
  action: {
    marginTop: theme.spacing(2),
  },
}));
