import React from 'react';
import { render } from 'react-dom';
import { RecoilRoot } from 'recoil';

import { ThemeProvider } from '@/styles';
import { ThemeProvider as ThemeProviderV5 } from '@/theme';

import App from './components/App';
import { Settings } from './Settings';
import { ErrorBoundary } from './components/ErrorBoundary';
import { TryModeProvider } from './components/TryModeProvider';
import { ConversationProvider } from './components/ConversationProvider';
import { EnvironmentProvider, Device } from './components/EnvironmentProvider';
import { FocusVisible } from './components/FocusVisible';
import { isSmall } from './utils';
import { ChatEdgesProvider } from './components/ChatEdgesProvider';

function detectDevice(settings: Settings) {
  if (settings.popupMode) {
    return Device.popup;
  }
  if (isSmall(window)) {
    return Device.mobile;
  }
  return Device.web;
}

export const renderApp = (container: HTMLDivElement, settings: Settings) => {
  render(
    <ErrorBoundary>
      <RecoilRoot>
        <EnvironmentProvider
          value={{
            device: detectDevice(settings),
          }}
        >
          <ThemeProvider>
            <ThemeProviderV5>
              <TryModeProvider isTryMode>
                <ConversationProvider>
                  <ChatEdgesProvider
                    messageIntervalTime={settings.messageIntervalTime}
                  >
                    <FocusVisible>
                      <App settings={settings} />
                    </FocusVisible>
                  </ChatEdgesProvider>
                </ConversationProvider>
              </TryModeProvider>
            </ThemeProviderV5>
          </ThemeProvider>
        </EnvironmentProvider>
      </RecoilRoot>
    </ErrorBoundary>,
    container,
  );
};
