import { useRef, useCallback, useState, useEffect } from 'react';
import { ContentRect, MeasurementType } from 'react-measure';
import ResizeObserver from 'resize-observer-polyfill';
import { useUnmount } from 'react-use';

import { useEventCallback } from './useEventCallback';

type MeasureTypes = Partial<Record<MeasurementType, boolean>>;

const measureTypes: MeasurementType[] = [
  'client',
  'offset',
  'scroll',
  'bounds',
  'margin',
];

const getTypes = (props: MeasureTypes) => {
  const allowedTypes: MeasurementType[] = [];

  measureTypes.forEach(type => {
    if (props[type]) {
      allowedTypes.push(type);
    }
  });

  return allowedTypes;
};

const getContentRect = (
  node: HTMLElement,
  types: MeasurementType[],
): ContentRect => {
  const calculations: ContentRect = {};

  if (types.indexOf('client') > -1) {
    calculations.client = {
      top: node.clientTop,
      left: node.clientLeft,
      width: node.clientWidth,
      height: node.clientHeight,
    };
  }

  if (types.indexOf('offset') > -1) {
    calculations.offset = {
      top: node.offsetTop,
      left: node.offsetLeft,
      width: node.offsetWidth,
      height: node.offsetHeight,
    };
  }

  if (types.indexOf('scroll') > -1) {
    calculations.scroll = {
      top: node.scrollTop,
      left: node.scrollLeft,
      width: node.scrollWidth,
      height: node.scrollHeight,
    };
  }

  if (types.indexOf('bounds') > -1) {
    const rect = node.getBoundingClientRect();
    calculations.bounds = {
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left,
      width: rect.width,
      height: rect.height,
    };
  }

  if (types.indexOf('margin') > -1) {
    const styles = getComputedStyle(node);
    calculations.margin = {
      top: styles ? Number.parseInt(styles.marginTop, 10) : 0,
      right: styles ? Number.parseInt(styles.marginRight, 10) : 0,
      bottom: styles ? Number.parseInt(styles.marginBottom, 10) : 0,
      left: styles ? Number.parseInt(styles.marginLeft, 10) : 0,
    };
  }

  return calculations;
};

/**
 * Returns the global window object associated with provided element.
 */
const getWindowOf = <T extends HTMLElement>(target: T | null) => {
  // Assume that the element is an instance of Node, which means that it
  // has the "ownerDocument" property from which we can retrieve a
  // corresponding global object.
  const ownerGlobal = target?.ownerDocument?.defaultView;

  // Return the local window object if it's not possible extract one from
  // provided element.
  return ownerGlobal || window;
};

interface MeasureOptions extends MeasureTypes {
  onResize?(contentRect: ContentRect): void;
}

export const useMeasure = <T extends HTMLElement = HTMLElement>({
  onResize,
  ...options
}: MeasureOptions): {
  measureRef(node: T | null): void;
  measure(entries?: ResizeObserverEntry[]): void;
  contentRect: ContentRect;
} => {
  const [contentRect, setContentRect] = useState<ContentRect>({});
  const animationFrameIdRef = useRef<number | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<T | null>(null);
  const windowRef = useRef<ReturnType<typeof getWindowOf>>(getWindowOf(null));

  const clearResizeObserver = useEventCallback(() => {
    if (resizeObserverRef.current !== null) {
      resizeObserverRef.current.disconnect();
      resizeObserverRef.current = null;
    }
  }, []);

  const clearAnimation = useEventCallback(() => {
    if (animationFrameIdRef.current !== null) {
      windowRef.current.cancelAnimationFrame(animationFrameIdRef.current);
      animationFrameIdRef.current = null;
    }
  }, []);

  const handleResize = useEventCallback(
    (cr: ContentRect) => {
      setContentRect(cr);

      if (onResize) {
        onResize(cr);
      }
    },
    [onResize],
  );

  const measure = useEventCallback(
    (entries?: ResizeObserverEntry[]) => {
      if (nodeRef.current === null) {
        return;
      }

      const cr = getContentRect(nodeRef.current, getTypes(options));

      if (entries) {
        cr.entry = entries[0].contentRect;
      }

      animationFrameIdRef.current = windowRef.current.requestAnimationFrame(
        () => {
          if (resizeObserverRef.current !== null) {
            handleResize(cr);
          }
        },
      );
    },
    [options, handleResize],
  );

  const subscribe = useEventCallback(() => {
    resizeObserverRef.current = ('ResizeObserver' in windowRef.current &&
    (windowRef.current as any).ResizeObserver
      ? new (windowRef.current as any).ResizeObserver(measure)
      : new ResizeObserver(measure)) as ResizeObserver;

    if (nodeRef.current !== null) {
      resizeObserverRef.current.observe(nodeRef.current);
      handleResize(getContentRect(nodeRef.current, getTypes(options)));
    }
  }, [handleResize, options, measure]);

  useEffect(() => {
    subscribe();

    return () => {
      clearResizeObserver();
    };
  }, [subscribe, clearResizeObserver]);

  useUnmount(() => {
    clearAnimation();
    clearResizeObserver();
  });

  const handleRef = useCallback((node: T | null) => {
    if (resizeObserverRef.current !== null && nodeRef.current !== null) {
      resizeObserverRef.current.unobserve(nodeRef.current);
    }

    nodeRef.current = node;
    windowRef.current = getWindowOf(node);

    if (resizeObserverRef.current !== null && nodeRef.current !== null) {
      resizeObserverRef.current.observe(nodeRef.current);
    }
  }, []);

  return {
    measureRef: handleRef,
    measure,
    contentRect,
  };
};
