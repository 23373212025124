import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  KnowledgeBasePreview,
  useKnowledgeBasePreviewLazyQuery,
  Variables,
} from '@/components/KnowledgeBasePreview/KnowledgeBasePreviewQuery';
import { noop } from '@/utils';
import { Device, useEnvironment } from '@/components/EnvironmentProvider';
import { useWindowSize } from '@/hooks';
import { ID } from '@/models';
import { useConversation } from '@/components/ConversationProvider';

export const PreviewDataFileType = {
  PDF: 'PDF',
  HTML: 'HTML',
  OTHER: 'OTHER',
};

interface PreviewContainerVariables extends Variables {
  chatId: ID | undefined;
  fileNumber: string;
}

interface PreviewContainerContextProps {
  previewData: PreviewData | undefined;
  setPreviewData: Dispatch<SetStateAction<PreviewData | undefined>>;
  isOpen: boolean;
  variables: null | PreviewContainerVariables;
  setVariables: (value: null | PreviewContainerVariables) => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<SetStateAction<boolean>>;
  canPreviewBeOpened: boolean;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
interface PreviewContainerProviderProps {
  children: ReactElement;
}

export type PreviewData = {
  file?: string;
  type: keyof typeof PreviewDataFileType;
  pageNo: number;
  totalPageCount: number;
  fileName: string;
  htmlFile?: Pick<
    KnowledgeBasePreview,
    '__typename' | 'knowledgeBase' | 'css' | 'body'
  >;
  chatId: ID | undefined;
  fileNumber: string;
};

const PreviewContainerContext = createContext<PreviewContainerContextProps>({
  previewData: undefined,
  variables: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVariables: (value: null | Variables) => {},
  setPreviewData: noop,
  isOpen: false,
  setIsOpen: noop,
  isFullScreen: false,
  setIsFullScreen: noop,
  canPreviewBeOpened: false,
  isLoading: false,
  setIsLoading: noop,
});

const PreviewContainerProvider = ({
  children,
}: PreviewContainerProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState<PreviewData | undefined>();
  const [variables, setVariables] = useState<null | PreviewContainerVariables>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const environment = useEnvironment();
  const { width, height } = useWindowSize();
  const { conversation } = useConversation();
  const isPreviewEnabled = conversation?.project?.usePreviewPane || false;
  const isWeb = environment.device === Device.web;
  const isPopup = environment.device === Device.popup;
  const canPreviewBeOpened =
    isPreviewEnabled && width > 1048 && height > 600 && (isWeb || isPopup);

  const memoisedData = React.useMemo(
    () => ({
      previewData,
      setPreviewData,
      isFullScreen,
      setIsFullScreen,
      isOpen,
      setIsOpen,
      variables,
      setVariables,
      canPreviewBeOpened,
      isLoading,
      setIsLoading,
    }),
    [
      canPreviewBeOpened,
      isFullScreen,
      isLoading,
      isOpen,
      previewData,
      variables,
    ],
  );

  useEffect(() => {
    if (!canPreviewBeOpened) {
      setIsOpen(false);
    }
  }, [canPreviewBeOpened]);

  const [
    query,
    { data: queryData, loading },
  ] = useKnowledgeBasePreviewLazyQuery();
  const kbp = queryData?.knowledgeBasePreview || null;

  useEffect(() => {
    if (
      kbp &&
      kbp?.pdf &&
      variables &&
      variables.pageNo &&
      kbp.pdf &&
      kbp?.knowledgeBase?.title
    ) {
      setPreviewData({
        file: kbp?.pdf,
        type: 'PDF',
        pageNo: variables.pageNo,
        totalPageCount: kbp?.totalPageCount || 0,
        fileName: kbp?.knowledgeBase?.title,
        chatId: variables.chatId,
        fileNumber: variables.fileNumber,
      });
      setIsLoading(false);
    } else if (
      kbp &&
      kbp?.knowledgeBase?.title &&
      kbp?.body &&
      variables &&
      variables.pageNo
    ) {
      setPreviewData({
        htmlFile: kbp,
        type: 'HTML',
        pageNo: variables.pageNo,
        totalPageCount: kbp?.totalPageCount || 0,
        fileName: kbp?.knowledgeBase?.title,
        chatId: variables.chatId,
        fileNumber: variables.fileNumber,
      });
      setIsLoading(false);
    } else {
      setPreviewData({
        file: '',
        type: 'OTHER',
        pageNo: 0,
        totalPageCount: 0,
        fileName: kbp?.knowledgeBase?.title || '',
        chatId: variables?.chatId,
        fileNumber: variables?.fileNumber || '[1]',
      });
      setIsLoading(false);
    }
  }, [kbp, setIsLoading, setPreviewData, variables]);

  useEffect(() => {
    if (isOpen && variables !== null && variables.where.id !== '') {
      query({ variables });
    }
    if (loading) {
      setPreviewData(prevState => {
        if (prevState) {
          if (prevState.file) {
            return {
              ...prevState,
              file: '',
            };
          }
          return {
            ...prevState,
            htmlFile: undefined,
          };
        }
        return undefined;
      });
      setIsLoading(true);
    }
  }, [query, variables, loading, setPreviewData, setIsLoading, isOpen]);

  return (
    <PreviewContainerContext.Provider value={memoisedData}>
      {children}
    </PreviewContainerContext.Provider>
  );
};

export const usePreviewContainer = () => useContext(PreviewContainerContext);

export default PreviewContainerProvider;
