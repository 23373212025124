import {
  OperationVariables,
  BaseSubscriptionOptions,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@apollo/react-common';
import {
  useSubscription as useApolloSubscription,
  SubscriptionHookOptions as ApolloSubscriptionHookOptions,
} from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { useCallback, useRef } from 'react';

import { useMountedState } from '../components/useMounted';

interface SubscriptionHookOptions<TData = any, TVariables = OperationVariables>
  extends Omit<
    ApolloSubscriptionHookOptions<TData, TVariables>,
    'shouldResubscribe'
  > {
  shouldResubscribe?:
    | boolean
    | ((
        current: BaseSubscriptionOptions<TData, TVariables>,
        next: BaseSubscriptionOptions<TData, TVariables>,
      ) => boolean);
}

export const useSubscription = <TData = any, TVariables = OperationVariables>(
  doc: DocumentNode,
  {
    shouldResubscribe: shouldResubscribeOption,
    ...options
  }: SubscriptionHookOptions<TData, TVariables> = {},
) => {
  const mounted = useMountedState();
  const prevRef = useRef<BaseSubscriptionOptions<TData, TVariables> | null>(
    null,
  );

  const shouldResubscribe = useCallback(
    (next: BaseSubscriptionOptions<TData, TVariables>) => {
      const prev = prevRef.current;
      prevRef.current = next;

      if (
        typeof shouldResubscribeOption === 'boolean' ||
        typeof shouldResubscribeOption === 'undefined'
      ) {
        return shouldResubscribeOption !== false;
      }

      let result = false;

      if (typeof shouldResubscribeOption === 'function' && prev !== null) {
        result = shouldResubscribeOption(prev, next);
      }

      return result;
    },
    [shouldResubscribeOption],
  );

  const result = useApolloSubscription<TData, TVariables>(doc, {
    ...options,
    skip: !mounted || options.skip,
    shouldResubscribe,
  });

  return result;
};

export function makeSubscription<TData = any, TVariables = OperationVariables>(
  doc: DocumentNode,
) {
  const useSubscriptionHook = (
    options?: SubscriptionHookOptions<TData, TVariables>,
  ) => useSubscription<TData, TVariables>(doc, options);

  useSubscriptionHook.subscription = doc;
  return useSubscriptionHook;
}
