import { WrappedComponentProps } from 'react-intl';

import { ChatEvent, EventChat } from '../models';

export const getEventMessage = (
  chat: EventChat,
  intl: WrappedComponentProps['intl'],
): string => {
  if (chat.eventType === ChatEvent.AGENT_ENTER && chat.enteringAgent) {
    return intl.formatMessage(
      {
        id: 'ChatBody.Agent.Enterance.DisplayName',
        defaultMessage: 'Agent {displayName} joined this conversation.',
        description: 'Conversation agent entrance text',
      },
      {
        displayName:
          chat.enteringAgent.displayName || chat.enteringAgent.firstName,
      },
    );
  }

  const eventAdditionalInfo = JSON.parse(chat.eventAdditionalInfo || '{}');
  if (
    chat.eventType === ChatEvent.SEND_CONVERSATION_TO_EMAIL &&
    eventAdditionalInfo.email
  ) {
    return intl.formatMessage(
      {
        id: 'ChatBody.GetTranscript.Message',
        defaultMessage: 'User sent transcript to {email}',
        description: 'Conversation user get transcript text',
      },
      {
        email: eventAdditionalInfo.email,
      },
    );
  }
  return chat.eventFallbackMessage;
};
