let instance: Logger | null = null;

export class Logger {
  private debug = false;
  constructor() {
    if (!instance) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      instance = this;
    }
    return instance;
  }

  public setDebug(debug: boolean) {
    this.debug = debug;
  }

  public isDebug() {
    return this.debug;
  }

  public printDebug(...args: any[]) {
    if (this.debug === true) {
      console.log(...args);
    }
  }
}

export const logger = new Logger();
