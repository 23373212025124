import {
  Nullable,
  LocaleEnum,
  Conversation as ConversationBase,
  TypingInfo as TypingInfoBase,
  Agent as AgentBase,
  Project,
  Campaign,
  Media,
  JSONString,
  SDKSettings,
  SDKReferrerInput,
  SDKUrlPattern,
  VariableInput,
  Typename,
  ReadInfo,
  ServerError,
} from '@/models';
import { createMutation } from '@/graphql';

import { Me } from '../MeQuery';
import { Chat } from '../ChatList/ConversationChatsQuery';

import mutation from './startConversationMutation.graphql';

export interface Agent
  extends Pick<AgentBase, '__typename' | 'id' | 'firstName' | 'lastName'> {
  avatar: Nullable<
    Pick<Media, '__typename' | 'id' | 'url' | 'width' | 'height'>
  >;
}

interface TypingInfo
  extends Pick<TypingInfoBase, '__typename' | 'agentTyping'> {
  agent: Nullable<Agent>;
}

export interface Conversation
  extends Pick<
    ConversationBase,
    '__typename' | 'id' | 'state' | 'isTry' | 'lastChatTime'
  > {
  user: Nullable<Me>;
  assignee: Nullable<Pick<AgentBase, '__typename' | 'id'>>;
  project: Nullable<
    Pick<
      Project,
      | '__typename'
      | 'id'
      | 'name'
      | 'nluLanguage'
      | 'apiKey'
      | 'usageType'
      | 'usePreviewPane'
    >
  >;
  campaign: Nullable<Pick<Campaign, '__typename' | 'id' | 'name'>>;
  typingInfo: TypingInfo;
  lastChat: Nullable<Chat>;
  readInfo: Nullable<ReadInfo>;
}

interface UserStartConversation extends Typename<'UserStartConversation'> {
  conversation: Nullable<Conversation>;
  sdkSettings: Nullable<SDKSettings>;
  sdkUrlPatterns: Nullable<SDKUrlPattern[]>;
  debug: Nullable<JSONString>;
  errors: Nullable<ServerError[]>;
}

export interface Data {
  startConversation: Nullable<UserStartConversation>;
}

export interface Variables {
  placement?: string;
  userAgent?: string;
  locale?: LocaleEnum;
  debug?: boolean;
  startOver?: boolean;
  sdkReferrer?: SDKReferrerInput;
  variables?: VariableInput[];
}

export const StartConversationMutation = createMutation<Data, Variables>(
  mutation,
);
