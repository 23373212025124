import {
  makeStyles as materialMakeStyles,
  withStyles as materialWithStyles,
  useTheme as useMaterialTheme,
} from '@material-ui/styles';
import {
  Styles as MaterialStyles,
  WithStyles as MaterialWithStyles,
  ClassKeyInferable,
  WithStylesOptions,
  ClassNameMap,
  ClassKeyOfStyles,
  StyledComponentProps as MaterialStyledComponentProps,
} from '@material-ui/styles/withStyles';

import { Theme } from './theme';

export type Styles<
  ClassKey extends string = string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Props extends {} = {}
> = MaterialStyles<Theme, Props, ClassKey>;

export const makeStyles = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  Props extends {} = {},
  ClassKey extends string = string
>(
  styles: Styles<ClassKey, Props>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>,
): ((props?: Props) => ClassNameMap<ClassKey>) =>
  // @ts-ignore
  materialMakeStyles<Theme, Props, ClassKey>(styles, options);

export type WithStyles<
  StylesType extends ClassKeyInferable<Theme, any>,
  IncludeTheme extends boolean | undefined = false
> = MaterialWithStyles<StylesType, IncludeTheme>;

export type StyledComponentProps<
  StylesType extends ClassKeyInferable<Theme, any>
> = Pick<MaterialStyledComponentProps<ClassKeyOfStyles<StylesType>>, 'classes'>;

export const withStyles = materialWithStyles;
export const useTheme = () => useMaterialTheme<Theme>();
