import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';
import { StyleRules } from '@material-ui/styles';

import { Styles, makeStyles, StyledComponentProps } from '@/styles';
import { capitalize } from '@/utils';

const iconClasses = cn('alli-sdk-Icon');
export const iconClassName = iconClasses();

const styles: Styles<
  | 'root'
  | 'colorPrimary'
  | 'colorSecondary'
  | 'colorAction'
  | 'colorError'
  | 'colorDisabled'
  | 'fontSizeInherit'
  | 'fontSizeSmall'
  | 'fontSizeLarge'
> = theme => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const root: StyleRules<{}, 'root'>['root'] = {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
    flexShrink: 0,
    fontSize: '24px',
    transition: theme.transitions.create('fill', {
      duration: theme.transitions.duration.shorter,
    }),
  };

  return {
    '@global': {
      [`.${iconClassName}`]: root,
    },
    root,
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    colorSecondary: {
      color: theme.palette.secondary.main,
    },
    colorAction: {
      color: theme.palette.action.active,
    },
    colorError: {
      color: theme.palette.error.main,
    },
    colorDisabled: {
      color: theme.palette.action.disabled,
    },
    fontSizeInherit: {
      fontSize: 'inherit',
    },
    fontSizeSmall: {
      fontSize: '20px',
    },
    fontSizeLarge: {
      fontSize: '35px',
    },
  };
};

const useStyles = makeStyles(styles);

export interface IconProps
  extends StyledComponentProps<typeof styles>,
    React.SVGProps<SVGSVGElement> {
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
  fontSize?: 'default' | 'inherit' | 'small' | 'large';
  titleAccess?: string;
  htmlColor?: React.SVGProps<SVGSVGElement>['color'];
}

export const createIcon = (
  WrappedComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>,
  name: string,
) =>
  forwardRef<SVGSVGElement, IconProps>(
    (
      {
        color = 'inherit',
        htmlColor,
        fontSize = 'default',
        titleAccess,
        viewBox = '0 0 24 24',
        classes: classesProp,
        ...other
      },
      ref,
    ) => {
      const classes = useStyles({ classes: classesProp });

      return (
        <WrappedComponent
          focusable="false"
          {...other}
          viewBox={viewBox}
          color={htmlColor}
          ref={ref}
          className={clsx(
            classes.root,
            {
              [classes[`color${capitalize(color)}` as keyof typeof classes]]:
                color !== 'inherit',
              [classes[
                `fontSize${capitalize(fontSize)}` as keyof typeof classes
              ]]: fontSize !== 'default',
            },
            iconClasses({
              color,
              fontSize,
            }),
            iconClasses(name),
            other.className,
          )}
        />
      );
    },
  );
