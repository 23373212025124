import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { StyledComponentProps } from '@/styles';

import { Button } from '../Button';

import { useStyles } from './common';

interface EmptyStateProps
  extends StyledComponentProps<keyof ReturnType<typeof useStyles>>,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  action?: React.ReactNode;
  onAction?: React.MouseEventHandler<HTMLButtonElement>;
}

const EmptyState = forwardRef<HTMLDivElement, EmptyStateProps>(
  (
    { title, action, onAction, classes: classesProp, children, ...other },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp });

    return (
      <div {...other} className={clsx(classes.root, other.className)} ref={ref}>
        {title && <div className={classes.title}>{title}</div>}

        {children && <div className={classes.description}>{children}</div>}

        {onAction && action && (
          <Button
            className={classes.action}
            color="primary"
            variant="contained"
            onClick={onAction}
          >
            {action}
          </Button>
        )}
      </div>
    );
  },
);

EmptyState.displayName = 'EmptyState';
export default EmptyState;
