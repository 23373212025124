import {
  Nullable,
  ConversationWhereUniqueInput,
  Typename,
  ServerError,
} from '@/models';
import { createMutation } from '@/graphql';

import { Conversation } from '../../StartConversationMutation';

import mutation from './endConversationMutation.graphql';

interface UserEndConversation extends Typename<'UserEndConversation'> {
  conversation: Nullable<Conversation>;
  errors: Nullable<Array<Pick<ServerError, 'message'>>>;
}

export interface Data {
  endConversation: Nullable<UserEndConversation>;
}

export interface Variables {
  where: ConversationWhereUniqueInput;
}

export const EndConversationMutation = createMutation<Data, Variables>(
  mutation,
);
