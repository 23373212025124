import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import findIndex from 'lodash/findIndex';

import { ID } from '@/models';

import { noop } from '../utils';

import {
  ChatEdge,
  ChatConnection,
  Chat,
} from './ChatList/ConversationChatsQuery';

const isValidChat = (chat: Chat) => {
  const invalidBotChat =
    chat.__typename === 'BotChat' &&
    chat.message === null &&
    chat.media === null &&
    chat.faqCarousel === null &&
    (chat.chatOptionInfos === null || chat.chatOptionInfos.length === 0);

  return !invalidBotChat;
};

export interface InjectedChatEdgesProps {
  edges: ChatEdge[];
  buffering: boolean;
  lastChatId: ID | null;
  lastChatEdge: ChatEdge | null;
  setEdgesByChatConnection(
    chats: ChatConnection,
    isChatsQueryCompleted?: boolean,
  ): void;
  resetEdges(): void;
}

const ChatEdgesContext = createContext<InjectedChatEdgesProps>({
  edges: [],
  buffering: false,
  lastChatId: null,
  lastChatEdge: null,
  setEdgesByChatConnection: noop,
  resetEdges: noop,
});

export const useChatEdges = (): InjectedChatEdgesProps =>
  useContext(ChatEdgesContext);

interface ChatEdgesProviderProps {
  messageIntervalTime?: number;
}

export const ChatEdgesProvider: FunctionComponent<ChatEdgesProviderProps> = ({
  messageIntervalTime = 10,
  children,
}) => {
  const MessageIntervalTime = 10;
  const [_edges, _setEdges] = useState<ChatEdge[]>([]);
  const [lastChatId, setLastChatId] = useState<ID | null>(null);
  const [lastChatEdge, setLastChatEdge] = useState<ChatEdge | null>(null);
  const [buffering, setBuffering] = useState<boolean>(false);
  const queue = useRef<ChatEdge[]>([]);
  const shadows = useRef<ChatEdge[]>([]);
  const intervalID = useRef<number | null>(null);

  const clear = () => {
    if (intervalID.current !== null) {
      window.clearInterval(intervalID.current);
      intervalID.current = null;
    }
  };

  const start = useCallback(() => {
    if (intervalID.current === null) {
      intervalID.current = window.setInterval(() => {
        if (queue.current.length === 0) {
          clear();
        } else {
          const edge = queue.current.shift();
          if (edge) {
            _setEdges(prev => [...prev, edge]);
          }
          if (queue.current.length === 0) {
            setBuffering(false);
          }
        }
      }, MessageIntervalTime);
    }
  }, [MessageIntervalTime]);

  const pushToArray = useCallback(
    (edge: ChatEdge) => {
      queue.current.push(edge);
      setBuffering(true);
      start();
    },
    [start],
  );

  const setEdgesByChatConnection = useCallback(
    (chats: ChatConnection, isChatsQueryCompleted?: boolean) => {
      const edges = chats.edges.filter(
        edge =>
          isValidChat(edge.node) &&
          (edge.node.__typename === 'EventChat' || !edge.node.hidden),
      );
      setLastChatEdge(
        chats.edges.length > 0 ? chats.edges[chats.edges.length - 1] : null,
      );
      setLastChatId(
        chats.edges.length > 0
          ? chats.edges[chats.edges.length - 1].node.id
          : null,
      );

      if (
        // load more
        edges.length > 0 &&
        shadows.current.length > 0 &&
        edges.length !== shadows.current.length &&
        edges[edges.length - 1].node.id ===
          shadows.current[shadows.current.length - 1].node.id
      ) {
        shadows.current = edges;
        _setEdges(edges);
      } else if (
        // update chat state
        edges.length === shadows.current.length &&
        queue.current.length === 0
      ) {
        shadows.current = edges;
        _setEdges(edges);
      } else {
        edges.forEach(edge => {
          if (
            findIndex(shadows.current, e => e.node.id === edge.node.id) === -1
          ) {
            shadows.current = [...shadows.current, edge];

            if (isChatsQueryCompleted) {
              // edgesBuffer.push(edge);
              // edgesBufferRef.current.push(edge);
              if (edge.node.__typename === 'UserChat') {
                _setEdges(prev => [...prev, edge]);
              } else {
                pushToArray(edge);
              }
            }
          }
        });
      }
    },
    [pushToArray],
  );

  const resetEdges = () => {
    _setEdges([]);
    setLastChatId(null);
    setLastChatEdge(null);
    setBuffering(false);
    queue.current = [];
    shadows.current = [];
    intervalID.current = null;
  };

  const contextValue = useMemo<InjectedChatEdgesProps>(
    () => ({
      edges: _edges,
      buffering,
      lastChatId,
      lastChatEdge,
      setEdgesByChatConnection,
      resetEdges,
    }),
    [_edges, buffering, lastChatId, lastChatEdge, setEdgesByChatConnection],
  );

  return (
    <ChatEdgesContext.Provider value={contextValue}>
      {children}
    </ChatEdgesContext.Provider>
  );
};
