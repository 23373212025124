import { OptionStyle } from './ChatOptionStyle';
import { ChatMedia } from './ChatMedia';

import { Nullable, Typename } from '../graphql';
import { Variable } from '../Variable';

export enum OptionBoxTemplate {
  DEFAULT = 'DEFAULT',
  NEXT_PAGE = 'NEXT_PAGE',
}

export interface ChatOptionInfo extends Typename<'ChatOptionInfo'> {
  shortenOption: string;
  longOption: string;
  style: OptionStyle;
  reusable: Nullable<boolean>;
  media: Nullable<ChatMedia>;
  url: Nullable<string>;
  urlVariable: Nullable<Variable>;
  optionBoxTemplate: Nullable<OptionBoxTemplate>;
}
