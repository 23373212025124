import { SDKUrlPattern } from '../models';

export const getLinkTarget = (patterns: SDKUrlPattern[] | null) => (
  href: string,
): string => {
  return patterns &&
    patterns.findIndex(pattern => new RegExp(pattern.regex).test(href)) >= 0
    ? '_self'
    : '_blank';
};
