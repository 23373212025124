import { createContext, useContext } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export const SubscriptionClientContext = createContext<SubscriptionClient | null>(
  null,
);
export const useSubscriptionClient = (): SubscriptionClient | null =>
  useContext(SubscriptionClientContext);

export const SubscriptionClientProvider = SubscriptionClientContext.Provider;
