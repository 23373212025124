import { ID } from '../graphql';
import { ConversationWhereUniqueInput } from '../Conversation';

export enum ChatInputType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATETIME = 'DATETIME',
  FILL_SLOT_RICH_UX = 'FILL_SLOT_RICH_UX',
  SEND_FORM_RICH_UX = 'SEND_FORM_RICH_UX',
  CHOICE = 'CHOICE',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
  CONTACT_AGENT_BY_EMAIL_RICH_UX = 'CONTACT_AGENT_BY_EMAIL_RICH_UX',
}

export interface ChatWhereInput {
  conversationWhere: ConversationWhereUniqueInput;
}

export interface ChatBubbleWhereUniqueInput {
  conversationWhere: ConversationWhereUniqueInput;
  id: ID;
}

export enum UserAnswerFeedbackSelectionEnum {
  NOT_RELEVANT,
  INACCURATE,
  NOT_BEST_ANSWER,
}
