import { parseToRgb, rgba } from 'polished';

export const fade = (color: string, amount: number) => {
  if (color === 'transparent') {
    return color;
  }

  const parsedColor = parseToRgb(color);
  const alpha = Math.max(0, Math.min(1, amount));

  return rgba({
    ...parsedColor,
    alpha,
  });
};
