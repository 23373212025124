import { UAParser } from 'ua-parser-js';

const parser = new UAParser();
const browser = parser.getBrowser();

export const isEdge = (): boolean => browser.name === 'Edge';

export const isIE = (): boolean => browser.name === 'IE';

export const isIEAndEdge = (): boolean => isIE() || isEdge();

export const isFirefox = (): boolean => browser.name === 'Firefox';

export const isMobileAgent = (navigator: Navigator): boolean => {
  return !!navigator.userAgent.match(
    /Android|webOS|iPhone|iPod|BlackBerry|Windows Phone/i,
  );
};

export const isSmall = (window: Window): boolean =>
  isMobileAgent(window.navigator);
