import { AlliEventKeyMap } from './Alli.const';
import { Settings } from './Settings';
import {
  Options,
  User,
  EventOptions,
  SettingsSubscriptionCallback,
} from './Alli.types';

export const Alli = (settings: Settings) => ({
  initialize(opts: Options) {
    return settings.initialize({ ...opts });
  },
  initialized() {
    return settings.initialized;
  },
  destroy() {
    return settings.destroy();
  },
  setUser(user: User | null) {
    return settings.setUser(user === null ? null : { ...user });
  },
  setOptions(opts: Omit<Options, 'apiKey'>) {
    return settings.setOptions({ ...opts });
  },
  setPlacement(placement?: string) {
    if (!(typeof placement === 'string' || typeof placement === 'undefined')) {
      throw new Error('Invalid placement name');
    }

    return settings.setPlacement(placement);
  },
  event(options?: EventOptions) {
    return settings.event(options);
  },
  close() {
    return settings.close();
  },
  reload() {
    return settings.reload();
  },
  subscribe(
    eventType: AlliEventKeyMap,
    callback: SettingsSubscriptionCallback,
  ) {
    return settings.subscribe(eventType, callback);
  },
  endConversation() {
    return settings.endConversation();
  },
  isChatWindowOpen() {
    return settings.windowOpen;
  },
  setChatWindowOpen(value?: boolean) {
    // eslint-disable-next-line no-param-reassign
    settings.windowOpen =
      typeof value === 'undefined' ? !settings.windowOpen : value;
  },
  __startConversationWithCampaignToken(
    campaignToken: string,
    opts?: Options,
    eventOptions?: Omit<EventOptions, 'placement'>,
  ) {
    return settings.startConversation(campaignToken, opts, eventOptions);
  },
  __startConversationWithOutCampaignToken(
    opts?: Options,
    eventOptions?: Omit<EventOptions, 'placement'>,
  ) {
    return settings.startConversation('', opts, eventOptions);
  },
  __startConversation(token: string, opts?: Options) {
    return settings.continueConversation(token, opts);
  },
  __sendChat(message: string) {
    settings.sendChat(message);
  },
});
