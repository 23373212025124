import { Locale, LocaleEnum } from '../models';

export const DEFAULT_LOCALE = Locale.en;

export const getInitialLocale = (locale: string = DEFAULT_LOCALE): Locale => {
  const formattedLocale = locale.split('-')[0];
  if (formattedLocale in Locale) {
    return formattedLocale as Locale;
  }
  return DEFAULT_LOCALE;
};

interface Nav extends Navigator {
  userLanguage?: string;
  browserLanguage?: string;
  systemLanguage?: string;
}

export const getBrowserLocale = (nav: Nav): Locale =>
  getInitialLocale(
    (nav.languages && nav.languages[0]) ||
      nav.language ||
      nav.userLanguage ||
      nav.browserLanguage ||
      nav.systemLanguage,
  );

export const getBrowserLocaleEnum = (nav: Nav): LocaleEnum =>
  LocaleEnum[getBrowserLocale(nav)];
