import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';

import { Styles, StyledComponentProps, makeStyles } from '@/styles';

type Elevation =
  | 'elevation0'
  | 'elevation1'
  | 'elevation2'
  | 'elevation3'
  | 'elevation4'
  | 'elevation5'
  | 'elevation6'
  | 'elevation7'
  | 'elevation8'
  | 'elevation9'
  | 'elevation10'
  | 'elevation11'
  | 'elevation12'
  | 'elevation13'
  | 'elevation14'
  | 'elevation15'
  | 'elevation16'
  | 'elevation17'
  | 'elevation18'
  | 'elevation19'
  | 'elevation20'
  | 'elevation21'
  | 'elevation22'
  | 'elevation23'
  | 'elevation24';

const elevationStyles: Styles<Elevation> = theme =>
  theme.shadows.reduce(
    (acc, shadow, index) => {
      const key = `elevation${index}` as Elevation;

      return {
        ...acc,
        [key]: {
          ...acc[key],
          boxShadow: shadow,
        },
      };
    },
    {
      elevation0: {},
      elevation1: {},
      elevation2: {},
      elevation3: {},
      elevation4: {},
      elevation5: {},
      elevation6: {},
      elevation7: {},
      elevation8: {},
      elevation9: {},
      elevation10: {},
      elevation11: {},
      elevation12: {},
      elevation13: {},
      elevation14: {},
      elevation15: {},
      elevation16: {},
      elevation17: {},
      elevation18: {},
      elevation19: {},
      elevation20: {},
      elevation21: {},
      elevation22: {},
      elevation23: {},
      elevation24: {},
    },
  );

const styles: Styles<'root' | 'rounded' | 'outlined' | Elevation> = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: theme.transitions.create('box-shadow'),
  },
  rounded: {
    borderRadius: theme.shape.borderRadius,
  },
  outlined: {
    border: `1px solid ${theme.palette.divider}`,
  },
  ...elevationStyles(theme),
});

const useStyles = makeStyles(styles, { name: 'Paper' });
const paperClasses = cn('alli-sdk-Paper');

export interface PaperProps
  extends StyledComponentProps<typeof styles>,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  elevation?: number;
  square?: boolean;
  variant?: 'elevation' | 'outlined';
}

export const Paper = forwardRef<HTMLDivElement, PaperProps>(
  (
    {
      elevation = 1,
      square,
      variant = 'elevation',
      classes: classesProp,
      ...other
    },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp });

    return (
      <div
        {...other}
        className={clsx(
          classes.root,
          {
            [classes.rounded]: !square,
            [classes[`elevation${elevation}` as keyof typeof classes]]:
              variant === 'elevation',
            [classes.outlined]: variant === 'outlined',
          },
          paperClasses({
            elevation,
            square,
            variant,
          }),
          other.className,
        )}
        ref={ref}
      />
    );
  },
);
