import React, {
  createContext,
  ComponentType,
  FunctionComponent,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { noop } from '../utils';
import {
  SDKSettingsMedia,
  Int,
  SDKSettings as SDKSettingsModel,
  ConversationState,
  ChatBubblePositionEnum,
  ChatOptionAlign,
  ChatWindowStyle,
  SDKUrlPattern,
  PopUpBoxIconOption,
  ID,
  ChatTextAlign,
} from '../models';
import { DEFAULT_SDKSETTINGS } from '../constants';

import { Conversation } from './StartConversationMutation';

export interface SDKSettings {
  avatar: string;
  avatarSrcSet: string | undefined;
  companyLogo: string | undefined;
  companyName: string;
  showContactAgentButton: boolean;
  showSendConversationButton: boolean;
  themeColor: string;
  componentsHeaderColor: string;
  componentsColor: string;
  componentsTextColor: string;
  chatBubblePosition: ChatBubblePositionEnum;
  hasNewConversationButton: boolean;
  hasBackButton: boolean;
  chatOptionAlign: ChatOptionAlign;
  chatTextAlign: ChatTextAlign;
  chatWindowStyle: ChatWindowStyle;
  useAutoComplete: boolean;
  numAutoComplete: Int;
  chatWindowWidth: Int;
  chatWindowHeight: Int;
  popUpLauncher: string;
  popUpLauncherSrcSet: string | undefined;
  popUpWidth: Int;
  popUpCustomMessage: string;
  popUpUseCustomMessage: boolean;
  popUpShowMessageFirstArrive: boolean;
  popUpShowMessageAfterClose: boolean;
  popUpBoxIconOption: PopUpBoxIconOption;
  popUpBoxIcon: string | undefined;
}

export interface InjectedConversationProps {
  placement: string | null;
  conversation: Conversation | null;
  sdkSettings: SDKSettings;
  sdkUrlPatterns: SDKUrlPattern[];
  startOver: boolean;
  error: boolean;
  generativeAnsRendered: boolean;
  loadConversation(apiKey: string, id: ID): void;
  clearConversation(apiKey: string | null, id: ID | null): void;
  dispatch(action: ConversationAction): void;
}

export const conversationContextDefault: InjectedConversationProps = {
  placement: null,
  conversation: null,
  sdkSettings: DEFAULT_SDKSETTINGS,
  sdkUrlPatterns: [],
  startOver: false,
  generativeAnsRendered: true,
  error: false,
  loadConversation: noop,
  clearConversation: noop,
  dispatch: noop,
};

export const ConversationContext = createContext<InjectedConversationProps>(
  conversationContextDefault,
);

export const useConversation = (): InjectedConversationProps =>
  useContext(ConversationContext);

export function withConversation<T extends InjectedConversationProps>(
  WrappedComponent: ComponentType<T>,
): FunctionComponent<Omit<T, keyof InjectedConversationProps>> {
  return props => {
    const injectedProps = useConversation();

    // @ts-ignore
    return <WrappedComponent {...props} {...injectedProps} />;
  };
}

const getAvatarUrl = (avatar: SDKSettingsMedia) => {
  if (
    avatar.resizedUrls &&
    avatar.resizedUrls.length > 0 &&
    avatar.resizedUrls[0] !== null
  ) {
    return avatar.resizedUrls[0];
  }
  return avatar.url;
};

const getAvatarSrcSet = (avatar: SDKSettingsMedia) => {
  if (
    avatar.resizedUrls &&
    avatar.resizedUrls.filter(url => url !== null).length === 3
  ) {
    return avatar.resizedUrls.map((s, i) => `${s} ${i + 1}x`).join(',');
  }
  return undefined;
};

const getSDKSettings = (
  prev: SDKSettings,
  value: SDKSettingsModel | null,
): SDKSettings => {
  if (value === null) {
    return prev;
  }
  return {
    avatar: value.avatar !== null ? getAvatarUrl(value.avatar) : prev.avatar,
    avatarSrcSet:
      value.avatar !== null ? getAvatarSrcSet(value.avatar) : prev.avatarSrcSet,
    companyLogo:
      value.companyLogo !== null ? value.companyLogo.url : prev.companyLogo,
    companyName:
      value.companyName !== null ? value.companyName : prev.companyName,
    showContactAgentButton:
      value.showContactAgentButton !== null
        ? value.showContactAgentButton
        : prev.showContactAgentButton,
    showSendConversationButton:
      value.showSendConversationButton !== null
        ? value.showSendConversationButton
        : prev.showSendConversationButton,
    themeColor: value.themeColor !== null ? value.themeColor : prev.themeColor,
    componentsHeaderColor:
      value.componentsHeaderColor !== null
        ? value.componentsHeaderColor
        : prev.componentsHeaderColor,
    componentsColor:
      value.componentsColor !== null
        ? value.componentsColor
        : prev.componentsColor,
    componentsTextColor:
      value.componentsTextColor !== null
        ? value.componentsTextColor
        : prev.componentsTextColor,
    chatBubblePosition:
      value.chatBubblePosition !== null
        ? value.chatBubblePosition
        : prev.chatBubblePosition,
    hasNewConversationButton:
      value.hasNewConversationButton !== null
        ? value.hasNewConversationButton
        : prev.hasNewConversationButton,
    hasBackButton:
      value.hasBackButton !== null ? value.hasBackButton : prev.hasBackButton,
    useAutoComplete:
      value.useAutoComplete !== null
        ? value.useAutoComplete
        : prev.useAutoComplete,
    numAutoComplete:
      value.numAutoComplete !== null
        ? value.numAutoComplete
        : prev.numAutoComplete,
    chatOptionAlign:
      value.chatOptionAlign !== null
        ? value.chatOptionAlign
        : prev.chatOptionAlign,
    chatTextAlign:
      value.chatTextAlign !== null ? value.chatTextAlign : prev.chatTextAlign,
    chatWindowStyle:
      value.chatWindowStyle !== null
        ? value.chatWindowStyle
        : prev.chatWindowStyle,
    chatWindowWidth:
      value.chatWindowWidth !== null
        ? value.chatWindowWidth
        : prev.chatWindowWidth,
    chatWindowHeight:
      value.chatWindowHeight !== null
        ? value.chatWindowHeight
        : prev.chatWindowHeight,
    popUpLauncher:
      value.popUpLauncher !== null
        ? value.popUpLauncher.url
        : prev.popUpLauncher,
    popUpLauncherSrcSet:
      value.popUpLauncher !== null
        ? getAvatarSrcSet(value.popUpLauncher)
        : prev.popUpLauncherSrcSet,
    popUpWidth: value.popUpWidth !== null ? value.popUpWidth : prev.popUpWidth,
    popUpCustomMessage:
      value.popUpCustomMessage !== null
        ? value.popUpCustomMessage
        : prev.popUpCustomMessage,
    popUpUseCustomMessage:
      value.popUpUseCustomMessage !== null
        ? value.popUpUseCustomMessage
        : prev.popUpUseCustomMessage,
    popUpShowMessageFirstArrive:
      value.popUpShowMessageFirstArrive !== null
        ? value.popUpShowMessageFirstArrive
        : prev.popUpShowMessageFirstArrive,
    popUpShowMessageAfterClose:
      value.popUpShowMessageAfterClose !== null
        ? value.popUpShowMessageAfterClose
        : prev.popUpShowMessageAfterClose,
    popUpBoxIconOption:
      value.popUpBoxIconOption !== null
        ? value.popUpBoxIconOption
        : prev.popUpBoxIconOption,
    popUpBoxIcon: value.popUpBoxIcon
      ? value.popUpBoxIcon.url
      : prev.popUpBoxIcon,
  };
};

interface ConversationProviderProps {}

interface StateType {
  placement: string | null;
  conversation: Conversation | null;
  sdkSettings: SDKSettings;
  sdkUrlPatterns: SDKUrlPattern[];
  startOver: boolean;
  error: boolean;
  generativeAnsRendered: boolean;
}

interface PayloadType {
  placement: string | null;
  conversation: Conversation | null;
  sdkSettings: SDKSettingsModel | null;
  sdkUrlPatterns: SDKUrlPattern[];
  error: boolean;
  conversationState: ConversationState;
  generativeAnsRendered: boolean;
}

export enum ConversationActionType {
  INIT = 'INIT',
  CLOSE = 'CLOSE',
  START_NEW_CONVERSATION = 'START_NEW_CONVERSATION',
  START_CONVERSATION = 'START_CONVERSATION',
  TRY_CONVERSATION = 'TRY_CONVERSATION',
  LOAD_CONVERSATION = 'LOAD_CONVERSATION',
  CONTINUE_CONVERSATION = 'CONTINUE_CONVERSATION',
  UPDATE_CONVERSATION = 'UPDATE_CONVERSATION',
  UPDATE_CONVERSATION_STATE = 'UPDATE_CONVERSATION_STATE',
  UPDATE_GENERATIVE_ANS_STATE = 'UPDATE_GENERATIVE_ANS_STATE',
  END_CONVERSATION = 'END_CONVERSATION',
  FAIL_CONVERSATION = 'FAIL_CONVERSATION',
  ERROR_CONVERSATION = 'ERROR_CONVERSATION',
  SELECT_CONVERSATION = 'SELECT_CONVERSATION',
}

type ConversationAction =
  | {
      type: ConversationActionType.INIT;
      payload: Pick<PayloadType, 'placement'>;
    }
  | {
      type:
        | ConversationActionType.CLOSE
        | ConversationActionType.START_NEW_CONVERSATION
        | ConversationActionType.ERROR_CONVERSATION;
    }
  | {
      type:
        | ConversationActionType.START_CONVERSATION
        | ConversationActionType.TRY_CONVERSATION;
      payload: Pick<
        PayloadType,
        'conversation' | 'sdkSettings' | 'sdkUrlPatterns'
      >;
    }
  | {
      type: ConversationActionType.CONTINUE_CONVERSATION;
      payload: Pick<
        PayloadType,
        'conversation' | 'sdkSettings' | 'sdkUrlPatterns' | 'placement'
      >;
    }
  | {
      type: ConversationActionType.LOAD_CONVERSATION;
      payload: Pick<PayloadType, 'conversation' | 'sdkUrlPatterns'> & {
        sdkSettings: SDKSettings;
      };
    }
  | {
      type:
        | ConversationActionType.END_CONVERSATION
        | ConversationActionType.UPDATE_CONVERSATION
        | ConversationActionType.SELECT_CONVERSATION;
      payload: Pick<PayloadType, 'conversation'>;
    }
  | {
      type: ConversationActionType.UPDATE_CONVERSATION_STATE;
      payload: Pick<PayloadType, 'conversationState'>;
    }
  | {
      type: ConversationActionType.UPDATE_GENERATIVE_ANS_STATE;
      payload: Pick<PayloadType, 'generativeAnsRendered'>;
    }
  | {
      type: ConversationActionType.FAIL_CONVERSATION;
      payload: Pick<
        PayloadType,
        'error' | 'conversation' | 'sdkSettings' | 'sdkUrlPatterns'
      >;
    };

const initialArg: StateType = {
  placement: null,
  conversation: null,
  sdkSettings: DEFAULT_SDKSETTINGS,
  sdkUrlPatterns: [],
  startOver: false,
  error: false,
  generativeAnsRendered: true,
};

const reducer = (state: StateType, action: ConversationAction): StateType => {
  switch (action.type) {
    case ConversationActionType.INIT:
      return {
        ...state,
        placement: action.payload.placement,
      };
    case ConversationActionType.CLOSE:
      return {
        ...state,
        placement: null,
      };
    case ConversationActionType.START_NEW_CONVERSATION:
      return {
        ...state,
        conversation: null,
        startOver: true,
      };
    case ConversationActionType.START_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
        sdkSettings: getSDKSettings(
          state.sdkSettings,
          action.payload.sdkSettings,
        ),
        sdkUrlPatterns: action.payload.sdkUrlPatterns,
        startOver: false,
      };
    case ConversationActionType.TRY_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
        sdkSettings: getSDKSettings(
          state.sdkSettings,
          action.payload.sdkSettings,
        ),
        sdkUrlPatterns: action.payload.sdkUrlPatterns,
        startOver: false,
      };
    case ConversationActionType.LOAD_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
        sdkSettings: action.payload.sdkSettings || state.sdkSettings,
        sdkUrlPatterns: action.payload.sdkUrlPatterns,
      };
    case ConversationActionType.CONTINUE_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
        sdkSettings: getSDKSettings(
          state.sdkSettings,
          action.payload.sdkSettings,
        ),
        sdkUrlPatterns: action.payload.sdkUrlPatterns,
        placement: action.payload.placement,
      };
    case ConversationActionType.END_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
      };
    case ConversationActionType.FAIL_CONVERSATION:
      return {
        ...state,
        startOver: false,
        conversation: action.payload.conversation,
        sdkSettings: getSDKSettings(
          state.sdkSettings,
          action.payload.sdkSettings,
        ),
        sdkUrlPatterns: action.payload.sdkUrlPatterns,
        error: action.payload.error,
      };
    case ConversationActionType.ERROR_CONVERSATION:
      return {
        ...state,
        error: true,
      };
    case ConversationActionType.SELECT_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
      };
    case ConversationActionType.UPDATE_CONVERSATION:
      return {
        ...state,
        conversation: action.payload.conversation,
      };
    case ConversationActionType.UPDATE_CONVERSATION_STATE:
      return {
        ...state,
        conversation: state.conversation && {
          ...state.conversation,
          state: action.payload.conversationState,
        },
      };
    case ConversationActionType.UPDATE_GENERATIVE_ANS_STATE:
      return {
        ...state,
        generativeAnsRendered: action.payload.generativeAnsRendered,
      };
    default:
      return state;
  }
};

export const ConversationProvider: FunctionComponent<ConversationProviderProps> = ({
  children,
}) => {
  const [loadOnce, setLoadOnce] = useState(true);
  const [
    {
      placement,
      conversation,
      sdkSettings,
      sdkUrlPatterns,
      generativeAnsRendered,
      startOver,
      error,
    },
    dispatch,
  ] = useReducer(reducer, initialArg);

  const loadConversation = useCallback(
    (apiKey: string, id: ID) => {
      if (loadOnce) {
        const data = JSON.parse(
          localStorage.getItem(`${apiKey}-${id}-${placement}`) || '{}',
        );

        if (data.conversation) {
          dispatch({
            type: ConversationActionType.LOAD_CONVERSATION,
            payload: {
              conversation: data.conversation,
              sdkSettings: data.sdkSettings || null,
              sdkUrlPatterns: data.sdkUrlPatterns || [],
            },
          });
          setLoadOnce(false);
        }
      }
    },
    [placement, loadOnce],
  );

  const clearConversation = useCallback(
    (apiKey: string | null, id: ID | null) => {
      if (apiKey && id) {
        localStorage.removeItem(`${apiKey}-${id}-${placement}`);
      }
    },
    [placement],
  );

  useEffect(() => {
    const handleUnload = () => {
      if (
        conversation &&
        conversation.project &&
        conversation.user &&
        placement
      ) {
        const key = `${conversation.project.apiKey}-${conversation.user.ownUserId}-${placement}`;
        localStorage.setItem(
          key,
          JSON.stringify({
            conversation,
            sdkSettings,
            sdkUrlPatterns,
          }),
        );
      }
    };

    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('unload', handleUnload);
    };
  }, [placement, conversation, sdkSettings, sdkUrlPatterns]);

  const contextValue = useMemo<InjectedConversationProps>(
    () => ({
      placement,
      conversation,
      sdkSettings,
      sdkUrlPatterns,
      generativeAnsRendered,
      startOver,
      error,
      loadConversation,
      clearConversation,
      dispatch,
    }),
    [
      placement,
      conversation,
      sdkSettings,
      sdkUrlPatterns,
      startOver,
      generativeAnsRendered,
      error,
      loadConversation,
      clearConversation,
    ],
  );

  return (
    <ConversationContext.Provider value={contextValue}>
      {children}
    </ConversationContext.Provider>
  );
};
