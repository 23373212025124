import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';
import { EventEmitter } from 'fbemitter';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { onError } from 'apollo-link-error';

import { err5xx, err4xx, ID } from '@/models';
import { errorLink, fragmentMatcher, uploadLink } from './apollo';
import { SdkType } from './SettingFactory';
import { utoa } from './utils';
import { config } from './config';
import { ClientOption, Region } from './Alli.types';
import { AlliEventKeyMap } from './Alli.const';

export class AlliClient extends EventEmitter {
  public established: boolean = true;
  private authLink = this.createAuthLink();
  public subscriptionClient: SubscriptionClient = this.createSubscriptionClient();
  public client: ApolloClient<any> | null = null;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private apiKey: string | null,
    private userId: ID | null,
    private region: Region = 'us',
    private token: string | null = null,
    private campaignToken: string | null = null,
    private sdkType: SdkType,
    private sdkVersion: string,
    private fingerprint: string | null = null,
    private uuid: string | null = null,
    private chatToken: string | null = null,
  ) {
    super();
  }

  public setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  public setUserId(userId: ID | null) {
    this.userId = userId;
  }

  public setFingerprint(fingerprint: string | null) {
    this.fingerprint = fingerprint;
    return this;
  }

  public setUuid(uuid: string | null) {
    this.uuid = uuid;
    return this;
  }

  public setToken(token: string | null) {
    this.token = token;
    return this;
  }

  public setCampaignToken(campaignToken: string | null) {
    this.campaignToken = campaignToken;
    return this;
  }

  public setOption(option?: ClientOption) {
    if (option) {
      this.apiKey = option.apiKey ? option.apiKey : this.apiKey;
      this.userId =
        typeof option.userId !== 'undefined' ? option.userId : this.userId;
      this.region = option.region ? option.region : this.region;
      this.token = option.token ? option.token : this.token;
      this.chatToken = option.chatToken ? option.chatToken : this.chatToken;
      this.campaignToken = option.campaignToken
        ? option.campaignToken
        : this.campaignToken;
    }
  }

  // TODO when region/apiKey/sdkType/sdkVersion/user.id/fingerprint/uuid changes,
  private createSubscriptionClient() {
    return new SubscriptionClient(
      (this.region === 'ja' && config.graphqlSubscriptionJaUri) ||
        (this.region === 'kr' && config.graphqlSubscriptionKrUri) ||
        config.graphqlSubscriptionUri,
      {
        reconnect: true,
        lazy: true,
        connectionParams: () =>
          new Promise((resolve, reject) => {
            const result: Record<string, string | number> = {
              'alli-sdk-type': this.sdkType,
              'alli-sdk-version': this.sdkVersion,
            };
            if (this.chatToken) {
              result['chat-token'] = this.chatToken;

              if (this.campaignToken) {
                result['CAMPAIGN-TOKEN'] = this.campaignToken;
              }
            } else if (this.apiKey) {
              result['api-key'] = this.apiKey;

              if (this.userId !== null) {
                result['own-user-id'] = utoa(this.userId);
              }

              if (this.fingerprint) {
                result['cookie-user-id'] = utoa(this.fingerprint);
              }

              if (this.uuid) {
                result['FINGER-PRINT'] = this.uuid;
              }

              if (this.campaignToken) {
                result['CAMPAIGN-TOKEN'] = this.campaignToken;
              }
            } else {
              resolve({});
            }
            resolve(result);
          }),
        connectionCallback: (error: Error[], result?: any) => {
          this.established = true;
        },
      },
    );
  }

  private createAuthLink() {
    return setContext(
      () =>
        new Promise(resolve => {
          const result: Record<string, string | number> = {
            'alli-sdk-type': this.sdkType,
            'alli-sdk-version': this.sdkVersion,
          };
          if (this.chatToken) {
            result['chat-token'] = this.chatToken;
            if (this.campaignToken) {
              result['CAMPAIGN-TOKEN'] = this.campaignToken;
            }
          } else if (this.apiKey) {
            result['API-KEY'] = this.apiKey;

            if (this.userId !== null) {
              result['OWN-USER-ID'] = utoa(this.userId);
            }

            if (this.fingerprint) {
              result['cookie-user-id'] = utoa(this.fingerprint);
            }

            if (this.uuid) {
              result['FINGER-PRINT'] = this.uuid;
            }

            if (this.campaignToken) {
              result['CAMPAIGN-TOKEN'] = this.campaignToken;
            }
          } else {
            if (this.token) {
              result.token = this.token;
            }

            if (this.campaignToken) {
              result['CAMPAIGN-TOKEN'] = this.campaignToken;
            }

            if (this.userId !== null) {
              result['OWN-USER-ID'] = utoa(this.userId);
            }

            if (this.uuid) {
              result['FINGER-PRINT'] = this.uuid;
            }
          }

          resolve({ headers: result });
        }),
    );
  }

  // this handles 2 usecases
  // 1. when the user opens SDK via link(email)
  // and the link gets expired
  // 2. show 4xx and 5xx error page on sdk pop-up
  private communicateErr = onError(({ networkError }) => {
    if (networkError) {
      const _networkErr = JSON.parse(JSON.stringify(networkError));
      if (_networkErr) {
        if (
          _networkErr.result &&
          _networkErr.result.error &&
          _networkErr.result.error.name === 'EXPIRED_TOKEN'
        ) {
          // handle expired link
          this.emit(AlliEventKeyMap.ExpiredToken);
        } else if (
          err5xx.test(_networkErr.statusCode) ||
          err4xx.test(_networkErr.statusCode)
        ) {
          // only handle 4XX and 5XX
          this.emit(AlliEventKeyMap.ShowErrorPage, _networkErr.statusCode);
        }
      }
    }
  });

  private createLink(wsLink: WebSocketLink) {
    return ApolloLink.from([
      errorLink,
      this.communicateErr,
      this.authLink,
      // using the ability to split links, you can send data to each link
      // depending on what kind of operation is being sent
      split(
        ({ query }) => {
          // split based on operation type
          const def = getMainDefinition(query);
          return (
            'operation' in def &&
            def.operation === 'subscription' &&
            def.kind === 'OperationDefinition'
          );
        },
        wsLink,
        uploadLink(
          (this.region === 'ja' && config.graphqlJaUri) ||
            (this.region === 'kr' && config.graphqlKrUri) ||
            config.graphqlUri,
        ),
      ),
    ]);
  }

  private createClient(wsLink: WebSocketLink) {
    return new ApolloClient({
      name: `sdk-${process.env.STAGE}`,
      version: process.env.RELEASE,
      cache: new InMemoryCache({
        fragmentMatcher,
      }),
      link: this.createLink(wsLink),
      defaultOptions: {
        watchQuery: {
          notifyOnNetworkStatusChange: true,
        },
      },
    });
  }

  public resetClient(option?: ClientOption) {
    this.setOption(option);

    return new Promise<{
      client: ApolloClient<any>;
      subscriptionClient: SubscriptionClient;
    }>(resolve => {
      this.established = false;
      this.disconnectSubscriptionClient();

      this.subscriptionClient = this.createSubscriptionClient();
      const wsLink = new WebSocketLink(this.subscriptionClient);
      this.client = this.createClient(wsLink);
      // this.client = client;
      // this.emit('client', client);
      resolve({
        client: this.client,
        subscriptionClient: this.subscriptionClient,
      });
    });
  }

  public disconnectAll() {
    this.disconnectSubscriptionClient();
    this.disconnectClient();
  }

  public disconnectClient() {
    if (this.client !== null) {
      this.client.stop();
      this.client = null;
    }
  }

  public disconnectSubscriptionClient() {
    this.subscriptionClient.close();
  }
}
