import {
  addEventForAndroid,
  addEventForIOS,
} from './Alli.NativeSDKEventListener';

export function initializer(initializerFn: () => PromiseLike<void>): void {
  initializerFn().then(() => {
    addEventForIOS();
    addEventForAndroid();
  });
}
