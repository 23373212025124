import ReactDOM from 'react-dom';
import { EventEmitter } from 'fbemitter';
import defaultsDeep from 'lodash/defaultsDeep';
import isUndefined from 'lodash/isUndefined';
import { Option as LinkOptions } from 'linkifyjs/react';
import { err5xx, err4xx, ID } from '@/models';

import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { linkifyDefaultOptions } from './components/ChatList/Chat/common';
import { AlliClient } from './AlliClient';
import { Logger } from './Logger';
import { ALLI_CONTAINER } from './constants';
import { getRegion, getBrowserLocaleEnum, getSDKReferrerInput } from './utils';
import {
  Data as SendChatData,
  Variables as SendChatVariables,
  useSendChatMutation,
} from './components/ChatList/SendChatMutation';
import {
  Data as ContinueConversationData,
  Variables as ContinueConversationVariables,
  ContinueConversationMutation,
} from './components/ContinueConversationMutation';
import {
  Data as StartConversationData,
  Variables as StartConversationVariables,
  StartConversationMutation,
} from './components/StartConversationMutation';
import {
  User,
  StyleOptions,
  Variable,
  ClientOption,
  Region,
  Preview,
  EventOptions,
  Options,
  VariableObject,
  SettingsSubscriptionCallback,
  AlliMenuOptions,
  AlliPosition,
} from './Alli.types';
import { AlliEventKeyMap } from './Alli.const';
import { initialize as sentryInitialize } from './sentry';

const getDefaultSpeechRecognition = (): SpeechRecognition | null => {
  try {
    const SpeechRecognitionClass =
      (window as any).webkitSpeechRecognition ||
      window.SpeechRecognition ||
      null;

    if (SpeechRecognitionClass) {
      const recognition = new SpeechRecognitionClass();
      recognition.interimResults = true;
      recognition.maxAlternatives = 10;
      recognition.continuous = true;
      return recognition;
    }

    return null;
  } catch {
    return null;
  }
};

export const DEFAULT_MENU_OPTIONS: AlliMenuOptions = {
  canMinimize: undefined,
  canClose: false,
};
export class Settings extends EventEmitter {
  public static fingerprintKey = 'alli-fingerprint';
  public static uuidKey = 'alli-uuid';
  public apiKey: string | null = null;
  public region: Region = 'us';
  public user: User | null = null;
  public variables: Variable[] | null | undefined = null;
  public chatToken: string | null = null;
  public popupMode: boolean = false;
  public disableToggleDrag: boolean = false;
  public backButton: boolean = true;
  // by default for GPT_MODE backButton = false
  // using initialBackButton to store the value sent by the user
  public initialBackButton: boolean | undefined = undefined;
  public exitConfirm: boolean = true;
  public hasExitButton: boolean = true;
  public position: AlliPosition | null = null;
  private logging: boolean = true;

  /**
   * To display minimize menu on the conversation list
   * @deprecated use menu.canMinimize instead.
   * @default true
   */
  public canMinimize: boolean = true;
  public menu: AlliMenuOptions = { ...DEFAULT_MENU_OPTIONS };
  public header: boolean = true;
  public footer: boolean = true;
  public launcher: boolean = true;

  public providerLink: boolean = true;
  public linkOptions: LinkOptions<
    Record<string, unknown>
  > = linkifyDefaultOptions;

  public styleOptions: StyleOptions = {};
  public placement: string | null = null;
  public initialized: boolean = false;
  public preview: Preview | null = null;
  public established: boolean = true;
  public fingerprint: string | null = null;
  public isTokenExpired: boolean = false;
  public errorStatusCode: number | null = null;
  public uuid: string | null = null;
  public conversationID: ID | null = null;
  public campaignToken: string | null = null;
  public recognition: SpeechRecognition | null = getDefaultSpeechRecognition();
  public errorNotice: boolean = false;
  public errorNoticeMessage: string | null = null;
  private _windowOpen: boolean = true;
  public messageIntervalTime: number = 10;

  constructor(private alliClient: AlliClient, public alliLogger: Logger) {
    super();
    this.alliClient.addListener('expiredToken', this.handleExpiredToken);
    this.alliClient.addListener('showErrorPage', this.handleErrorPage);
  }

  public initialize({
    apiKey,
    // region,
    debug,
    chatToken,
    logging,
    providerLink,
    recognition,
    errorNotice,
    errorNoticeMessage,
    linkOptions,
    messageIntervalTime,
    position,
    launcher,
    header,
    footer,
    popupMode,
    disableToggleDrag,
    backButton,
    exitConfirm,
    hasExitButton,
    canMinimize,
    menu,
    styleOptions,
  }: Options): void {
    if (this.initialized) {
      throw new Error('Alli was initialized more than once.');
    }

    if (!apiKey) {
      throw new Error('API key must be provided.');
    }

    this.initialized = true;
    this.apiKey = apiKey;
    this.region = getRegion(apiKey);
    this.alliLogger.setDebug(debug || false);
    this.chatToken = isUndefined(chatToken) ? this.chatToken : chatToken;
    this.providerLink = isUndefined(providerLink)
      ? this.providerLink
      : providerLink;
    this.hasExitButton = isUndefined(hasExitButton)
      ? this.hasExitButton
      : hasExitButton;
    this.linkOptions = defaultsDeep(linkOptions, this.linkOptions);

    this.errorNotice = isUndefined(errorNotice)
      ? this.errorNotice
      : errorNotice;
    this.errorNoticeMessage = isUndefined(errorNoticeMessage)
      ? this.errorNoticeMessage
      : errorNoticeMessage;
    this.messageIntervalTime = isUndefined(messageIntervalTime)
      ? this.messageIntervalTime
      : messageIntervalTime;

    if (!isUndefined(recognition)) {
      this.recognition = recognition;
    }

    /**
     * Style feature part
     */
    this.position = isUndefined(position) ? this.position : position;
    this.launcher = isUndefined(launcher) ? this.launcher : launcher;
    this.header = isUndefined(header) ? this.header : header;
    this.footer = isUndefined(footer) ? this.footer : footer;
    this.popupMode = isUndefined(popupMode) ? this.popupMode : popupMode;
    this.disableToggleDrag = isUndefined(disableToggleDrag)
      ? this.disableToggleDrag
      : disableToggleDrag;
    this.backButton = isUndefined(backButton) ? this.backButton : backButton;
    this.initialBackButton = backButton;
    this.exitConfirm = isUndefined(exitConfirm)
      ? this.exitConfirm
      : exitConfirm;
    this.canMinimize = isUndefined(canMinimize)
      ? this.canMinimize
      : canMinimize;
    this.logging = isUndefined(logging) ? this.logging : logging;
    this.menu = isUndefined(menu) ? this.menu : Object.assign(this.menu, menu);
    this.styleOptions = isUndefined(styleOptions)
      ? this.styleOptions
      : styleOptions;

    /**
     * Initialize part
     */
    if (this.logging) {
      sentryInitialize();
    }
    this.setUuid(this.apiKey);
    this.alliClient.setOption({
      apiKey: this.apiKey,
      region: this.region,
      chatToken: this.chatToken || undefined,
    });
    this.emit(AlliEventKeyMap.Init, this.apiKey);
  }

  public destroy() {
    this.alliClient.disconnectAll();
    this.close();
    this.resetVariables();
    Settings.removeContainer();
  }

  private resetVariables() {
    this.apiKey = null;
    this.user = null;
    this.variables = null;
    this.popupMode = false;
    this.header = true;
    this.footer = true;
    this.launcher = true;
    this.disableToggleDrag = false;
    this.backButton = true;
    this.exitConfirm = true;
    this.hasExitButton = true;
    this.logging = true;
    this.canMinimize = true;
    this.menu = { ...DEFAULT_MENU_OPTIONS };
    this.styleOptions = {};
    this.linkOptions = linkifyDefaultOptions;
    this.providerLink = true;
    this.placement = null;
    this.initialized = false;
    this.preview = null;
    this.established = true;
    this.fingerprint = null;
    this.uuid = null;
    this.errorNotice = true;
    this.errorNoticeMessage = null;
    this.messageIntervalTime = 1000;
  }

  static removeContainer() {
    const container = document.getElementById(ALLI_CONTAINER);

    if (container) {
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    }
  }

  private handleErrorPage = (statusCode: number) => {
    this.errorStatusCode = statusCode;
  };

  private resetClient(option?: ClientOption, skipEmit?: boolean) {
    return this.alliClient.resetClient(option).then(client => {
      if (!skipEmit) {
        this.emit(
          AlliEventKeyMap.Client,
          client.client,
          client.subscriptionClient,
        );
      }

      return client;
    });
  }

  private handleExpiredToken = () => {
    this.isTokenExpired = true;
    return this.emit('expiredToken');
  };

  public setOptions(options: Omit<Options, 'apiKey'>) {
    if (typeof options.debug !== 'undefined') {
      this.alliLogger.setDebug(options.debug);
    }

    if (!isUndefined(options.providerLink)) {
      this.providerLink = options.providerLink;
    }

    if (!isUndefined(options.errorNotice)) {
      this.errorNotice = options.errorNotice;
    }

    if (!isUndefined(options.errorNoticeMessage)) {
      this.errorNoticeMessage = options.errorNoticeMessage;
    }

    if (!isUndefined(options.messageIntervalTime)) {
      this.messageIntervalTime = options.messageIntervalTime;
    }

    if (!isUndefined(options.linkOptions)) {
      this.linkOptions = defaultsDeep(options.linkOptions, this.linkOptions);
    }

    if (!isUndefined(options.recognition)) {
      this.recognition = options.recognition;
    }

    /**
     * Style feature part
     */
    if (!isUndefined(options.position)) {
      this.position = options.position;
    }
    if (!isUndefined(options.launcher)) {
      this.launcher = options.launcher;
    }
    if (!isUndefined(options.header)) {
      this.header = options.header;
    }
    if (!isUndefined(options.footer)) {
      this.footer = options.footer;
    }
    if (!isUndefined(options.popupMode)) {
      this.popupMode = options.popupMode;
    }
    if (!isUndefined(options.disableToggleDrag)) {
      this.disableToggleDrag = options.disableToggleDrag;
    }
    if (!isUndefined(options.backButton)) {
      this.backButton = options.backButton;
    }
    if (!isUndefined(options.exitConfirm)) {
      this.exitConfirm = options.exitConfirm;
    }
    if (!isUndefined(options.hasExitButton)) {
      this.hasExitButton = options.hasExitButton;
    }
    if (!isUndefined(options.logging)) {
      this.logging = options.logging;
    }
    if (!isUndefined(options.canMinimize)) {
      this.canMinimize = options.canMinimize;
    }
    if (!isUndefined(options.menu)) {
      this.menu = defaultsDeep(options.menu, this.menu);
    }
    if (!isUndefined(options.styleOptions)) {
      this.styleOptions = defaultsDeep(options.styleOptions, this.styleOptions);
    }

    this.emit(AlliEventKeyMap.Options, options);
  }

  public setUser(user: User | null, doNotReset: boolean = false) {
    if (user && !user.id) {
      throw new Error('User id is not provided');
    }

    if (!this.user && !user) {
      return;
    }

    const userXOR = (this.user && !user) || (!this.user && user);

    const emitEvent =
      (this.user &&
        user &&
        (this.user.id !== user.id ||
          this.user.firstName !== user.firstName ||
          this.user.lastName !== user.lastName ||
          this.user.avatar !== user.avatar ||
          this.user.email !== user.email)) ||
      userXOR;

    if (emitEvent) {
      this.user = user;
      this.setUserVariable(user);
      this.alliClient.setUserId((user && user.id) || null);
      this.alliLogger.printDebug('Setting user', this.user);
    }
  }

  public close(): void {
    this.emit(AlliEventKeyMap.Close);
  }

  public endConversation(): void {
    this.emit(AlliEventKeyMap.EndConversation);
  }

  public reload() {
    this.close();
    this.event();
  }

  public restart() {
    this.event();
  }

  public setPlacement(placement?: string | null) {
    if (!this.initialized) {
      throw new Error('Alli is not initialized');
    }

    if (typeof placement === 'undefined' || placement === null) {
      this.placement =
        this.placement === null ? document.URL || 'http://' : this.placement;
    } else {
      this.placement = placement;
    }
    this.alliLogger.printDebug('Setting placement', this.placement);
  }

  public setVariables(variables?: Variable[] | VariableObject | null) {
    let variablesArr: Variable[] = [];

    if (Array.isArray(variables)) {
      variablesArr = variables;
    } else if (variables) {
      variablesArr = Object.entries(variables).map(([name, value]) => ({
        name,
        value,
      }));
    }

    this.variables = [...(this.variables || []), ...variablesArr].reduce<
      Variable[]
    >((acc, curr) => {
      const index = acc.findIndex(item => item.name === curr.name);

      if (index < 0) {
        return [...acc, curr];
      }

      // remove duplicated variable
      return [...acc.slice(0, index), ...acc.slice(index + 1), curr];
    }, []);
    this.alliLogger.printDebug('Setting variables', this.variables);
  }

  public setUserVariable(user: User | null) {
    this.variables = [
      ...(this.variables || []),
      ...(user && 'firstName' in user && typeof user.firstName !== 'undefined'
        ? [{ name: 'FIRST_NAME', value: user.firstName }]
        : []),
      ...(user && 'lastName' in user && typeof user.lastName !== 'undefined'
        ? [{ name: 'LAST_NAME', value: user.lastName }]
        : []),
      ...(user && 'email' in user && typeof user.email !== 'undefined'
        ? [{ name: 'EMAIL', value: user.email }]
        : []),
    ].reduce<Variable[]>((acc, curr) => {
      const index = acc.findIndex(item => item.name === curr.name);

      if (index < 0) {
        return [...acc, curr];
      }

      // remove duplicated variable
      return [...acc.slice(0, index), ...acc.slice(index + 1), curr];
    }, []);
    this.alliLogger.printDebug('Setting variables', this.variables);
  }

  public event(eventOptions?: EventOptions) {
    this.resetClient().then(() => {
      if (!this.initialized) {
        throw new Error('Alli is not initialized');
      }

      let placement;
      if (eventOptions) {
        if (typeof eventOptions.user !== 'undefined') {
          this.setUser(eventOptions.user);
        }

        placement = eventOptions.placement;
        this.setVariables(eventOptions.variables);
      }

      this.setPlacement(placement);

      this.emit('event', this.user, this.placement, this.variables);
      this.alliLogger.printDebug(
        'run Alli with\nplacement:',
        this.placement,
        '\nuser:',
        this.user,
        '\variables:',
        this.variables,
      );
    });
  }

  public setPreview(userId: string, campaignId: string) {
    this.preview = { userId, campaignId };
    this.setUser(userId ? { id: userId } : null);
    this.resetClient().then(() => {
      this.emit(AlliEventKeyMap.Preview, this.preview);
    });
  }

  public restartPreview(userId: string) {
    if (this.preview === null) {
      throw new Error("Can't restart before start");
    }
    // this.close();
    this.preview = { ...this.preview, userId };
    // this.user = {id: userId};
    this.setUser({ id: userId });
    this.emit(AlliEventKeyMap.Preview, this.preview);
  }

  public reloadPreview() {
    this.close();
    this.resetClient();
    this.emit(AlliEventKeyMap.Preview, this.preview);
  }

  private static eventTypeAllowList = [
    AlliEventKeyMap.Close,
    AlliEventKeyMap.Conversation,
  ];

  public subscribe(
    eventType: AlliEventKeyMap,
    callback: SettingsSubscriptionCallback,
  ): () => void {
    if (Settings.eventTypeAllowList.includes(eventType)) {
      const subscription = this.addListener(eventType, callback);

      // unsubscribe
      return () => {
        subscription.remove();
      };
    }

    return () => {
      // empty
    };
  }

  public sendChat(message: string) {
    if (!this.initialized) {
      throw new Error('Alli is not initialized');
    }

    if (this.alliClient.client !== null && this.conversationID !== null) {
      this.alliClient.client.mutate<SendChatData, SendChatVariables>({
        mutation: useSendChatMutation.mutation,
        variables: {
          conversationId: this.conversationID,
          message,
        },
      });
    }
  }

  public continueConversation(token: string, opts?: Options) {
    return new Promise((resolve, reject) => {
      if (!token) {
        throw new Error('Input conversation token');
      }
      const chatToken = this.chatToken || undefined;
      this.resetClient({ token, chatToken }).then(client => {
        client.client
          .mutate<ContinueConversationData, ContinueConversationVariables>({
            mutation: ContinueConversationMutation.mutation,
            variables: {
              locale: getBrowserLocaleEnum(window.navigator),
              userAgent: window.navigator.userAgent,
              sdkReferrer: getSDKReferrerInput(
                window.location,
                window.document,
              ),
            },
          })
          .then(result => {
            if (result.data?.continueConversation) {
              const {
                apiKey,
                ownUserId,
                conversation,
                sdkSettings,
                sdkUrlPatterns,
              } = result.data.continueConversation;
              if (apiKey) {
                this.initialize({
                  ...opts,
                  apiKey,
                });
                this.setUser(
                  ownUserId
                    ? {
                        id: ownUserId,
                      }
                    : null,
                );
                this.setPlacement();
                this.emit(AlliEventKeyMap.Init, apiKey);
                this.resetClient();

                if (conversation) {
                  this.conversationID = conversation.id;
                  this.emit(AlliEventKeyMap.ContinueConversation, {
                    conversation,
                    sdkSettings,
                    sdkUrlPatterns,
                    placement: this.placement,
                  });
                  resolve({});
                } else {
                  reject();
                }
              }
            }
          });
      });
    });
  }

  public startConversation(
    campaignToken: string,
    opts?: Options,
    eventOptions?: Omit<EventOptions, 'placement'>,
  ) {
    if (eventOptions) {
      if (typeof eventOptions.user !== 'undefined') {
        this.setUser(eventOptions.user);
      }

      this.setVariables(eventOptions.variables);
    }
    return new Promise((resolve, reject) => {
      if (!campaignToken && !opts?.chatToken) {
        throw new Error('Input conversation token');
      }
      if (campaignToken) {
        this.campaignToken = campaignToken;
      }

      if (opts?.chatToken) {
        this.chatToken = opts.chatToken;
      }

      if (opts?.popupMode) {
        this.popupMode = opts.popupMode;
      }

      this.setUuid().then(() => {
        const chatToken = this.chatToken || undefined;
        this.resetClient({
          campaignToken,
          userId: eventOptions?.user?.id || null,
          region: opts?.region,
          chatToken,
        }).then(client => {
          client.client
            .mutate<StartConversationData, StartConversationVariables>({
              mutation: StartConversationMutation.mutation,
              variables: {
                locale: getBrowserLocaleEnum(window.navigator),
                userAgent: window.navigator.userAgent,
                sdkReferrer: getSDKReferrerInput(
                  window.location,
                  window.document,
                ),
                variables:
                  this.variables?.filter(
                    v => v.value !== null && typeof v.value !== 'undefined',
                  ) || undefined,
                debug: opts?.debug,
              },
            })
            .then(result => {
              if (result.data?.startConversation) {
                const {
                  conversation,
                  sdkSettings,
                  sdkUrlPatterns,
                } = result.data.startConversation;

                const apiKey =
                  conversation &&
                  conversation.project &&
                  conversation.project.apiKey;
                const ownUserId =
                  conversation &&
                  conversation.user &&
                  conversation.user.ownUserId;
                if (apiKey) {
                  this.initialize({
                    ...opts,
                    apiKey,
                  });
                  this.setUser(
                    ownUserId
                      ? {
                          id: ownUserId,
                        }
                      : null,
                  );
                  this.setPlacement();
                  this.emit(AlliEventKeyMap.Init, apiKey);
                  this.resetClient();

                  if (conversation) {
                    this.conversationID = conversation.id;
                    this.emit(AlliEventKeyMap.ContinueConversation, {
                      conversation,
                      sdkSettings,
                      sdkUrlPatterns,
                      placement: this.placement,
                    });
                    resolve({});
                  } else {
                    reject();
                  }
                }
              }
            })
            .catch(err => {
              // when token is expired
              // and startConversationMutation fails
              // show 404 page
              if (chatToken) {
                this.emit(AlliEventKeyMap.Init);
              }
              const _networkErr = JSON.parse(JSON.stringify(err));
              if (_networkErr) {
                if (
                  _networkErr.networkError &&
                  _networkErr.networkError.statusCode &&
                  (err5xx.test(_networkErr.networkError.statusCode) ||
                    err4xx.test(_networkErr.networkError.statusCode))
                ) {
                  this.emit(AlliEventKeyMap.Init);
                }
              }
            });
        });
      });
    });
  }

  public static getFingerprintKey(apiKey: string) {
    return `${Settings.fingerprintKey}__${apiKey}`;
  }

  public setFingerprint = (fingerprint?: string) => {
    if (this.apiKey) {
      const fingerprintKey = Settings.getFingerprintKey(this.apiKey);
      const value = fingerprint || Cookies.get(fingerprintKey) || null;

      this.fingerprint = value;
      this.alliClient.setFingerprint(value);

      if (value === null) {
        Cookies.remove(fingerprintKey);
      } else {
        Cookies.set(fingerprintKey, value, {
          expires: 365,
          sameSite: 'None',
          secure: true,
        });
      }

      if (value !== this.fingerprint) {
        this.emit(AlliEventKeyMap.FingerPrintChange, value);
        this.resetClient();
      }
    }
  };

  public static getUuidKey(campaignToken: string | null, apiKey?: string) {
    if (apiKey && campaignToken === null) {
      return `${Settings.uuidKey}__${apiKey}`;
    }
    return Settings.uuidKey;
  }

  public setUuid = (apiKey?: string) => {
    const uuidKey = Settings.getUuidKey(this.campaignToken, apiKey);
    const value = Cookies.get(uuidKey) || uuidv4();

    this.uuid = value;
    this.alliClient.setUuid(value);

    Cookies.set(uuidKey, value, {
      expires: 365,
      sameSite: 'None',
      secure: true,
    });

    return this.resetClient();
  };

  public get windowOpen(): boolean {
    return this._windowOpen;
  }

  public set windowOpen(value: boolean) {
    if (this._windowOpen !== value) {
      this._windowOpen = value;
      this.emit(AlliEventKeyMap.WindowOpen, value);
    }
  }
}
