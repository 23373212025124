import { Region } from '@/Alli.types';

export const getRegion = (apiKey: string): Region => {
  if (apiKey.length === 34) {
    const region = apiKey.substring(0, 2);
    if (region === 'AJ') {
      return 'ja';
    }

    if (region === 'RK') {
      return 'kr';
    }
  }

  return 'us';
};
