import React, { forwardRef } from 'react';

import { ButtonBaseProps } from './ButtonBase';

export type TooltipProps<
  T extends React.ElementType = 'button'
> = ButtonBaseProps<T> & {
  tooltipText: React.ReactNode;
};

export const Tooltip = forwardRef(function TooltipBase<
  T extends React.ElementType = 'button'
>(
  { tooltipText, children, ...other }: React.PropsWithChildren<TooltipProps<T>>,
  ref: React.Ref<TooltipProps<T>['ref']>,
) {
  return (
    <span {...other} ref={ref} title={tooltipText}>
      {children}
    </span>
  );
});
