import { createIcon } from './createIcon';
import { ReactComponent as cancel } from './cancel-24px.svg';
import { ReactComponent as close } from './close-24px.svg';
import { ReactComponent as arrowDownward } from './arrow_downward-24px.svg';
import { ReactComponent as moreHoriz } from './more_horiz-24px.svg';
import { ReactComponent as attachFile } from './attach_file-24px.svg';
import { ReactComponent as arrowBackIos } from './arrow_back_ios-24px.svg';
import { ReactComponent as arrowForwardIos } from './arrow_forward_ios-24px.svg';
import { ReactComponent as send } from './send-24px.svg';
import { ReactComponent as event } from './event-24px.svg';
import { ReactComponent as mic } from './mic-24px.svg';
import { ReactComponent as zoomIn } from './zoom_in-24px.svg';
import { ReactComponent as cloudDownload } from './cloud_download-24px.svg';
import { ReactComponent as arrowDropDown } from './arrow_drop_down-24px.svg';
import { ReactComponent as minimize } from './minimize-24px.svg';
import { ReactComponent as error } from './error-24px.svg';
import { ReactComponent as assignment } from './assignment-24px.svg';
import { ReactComponent as email } from './email-24px.svg';
import { ReactComponent as firstPage } from './first_page-24px.svg';
import { ReactComponent as lastPage } from './last_page-24px.svg';
import { ReactComponent as chevronLeft } from './chevron_left-24px.svg';
import { ReactComponent as chevronRight } from './chevron_right-24px.svg';
import { ReactComponent as checkBox } from './check_box-24px.svg';
import { ReactComponent as checkBoxOutlined } from './check_box_outlined-24px.svg';
import { ReactComponent as checkBoxOutlineBlank } from './check_box_outline_blank-24px.svg';
import { ReactComponent as indeterminateCheckBox } from './indeterminate_check_box-24px.svg';
import { ReactComponent as indeterminateCheckBoxOutlined } from './indeterminate_check_box_outlined-24px.svg';
import { ReactComponent as add } from './add-24px.svg';
import { ReactComponent as remove } from './remove-24px.svg';
import { ReactComponent as thumbUp } from './thumb_up.svg';
import { ReactComponent as thumbDown } from './thumb_down.svg';
import { ReactComponent as thumbUpFilled } from './thumb_up_filled.svg';
import { ReactComponent as thumbDownFilled } from './thumb_down_filled.svg';
import { ReactComponent as visibility } from './visibility-24px.svg';
import { ReactComponent as insertDriveFile } from './insert_drive_file-24px.svg';
import { ReactComponent as forwardToInbox } from './forward_to_inbox_black_24px.svg';
import { ReactComponent as playCircle } from './play_circle_filled_black.svg';
import { ReactComponent as newConversation } from './new_conversation-24px.svg';
import { ReactComponent as leaveChatroom } from './leave_chatroom-24px.svg';
import { ReactComponent as logo } from './logo-24px.svg';
import { ReactComponent as check } from './check-24px.svg';
import { ReactComponent as search } from './search-24px.svg';

export * from './createIcon';
export const Cancel = createIcon(cancel, 'Cancel');
export const Close = createIcon(close, 'Close');
export const ArrowDownward = createIcon(arrowDownward, 'ArrowDownward');
export const MoreHorizontal = createIcon(moreHoriz, 'MoreHorizontal');
export const AttachFile = createIcon(attachFile, 'AttachFile');
export const ArrowForwardIos = createIcon(arrowForwardIos, 'ArrowForwardIos');
export const ArrowBackIos = createIcon(arrowBackIos, 'ArrowBackIos');
export const Send = createIcon(send, 'Send');
export const Event = createIcon(event, 'Event');
export const Mic = createIcon(mic, 'Mic');
export const ZoomIn = createIcon(zoomIn, 'ZoomIn');
export const CloudDownload = createIcon(cloudDownload, 'CloudDownload');
export const ArrowDropDown = createIcon(arrowDropDown, 'ArrowDropDown');
export const Minimize = createIcon(minimize, 'Minimize');
export const Error = createIcon(error, 'Error');
export const Assignment = createIcon(assignment, 'Assignment');
export const Email = createIcon(email, 'Email');
export const Mail = createIcon(email, 'Mail');
export const FirstPage = createIcon(firstPage, 'FirstPage');
export const LastPage = createIcon(lastPage, 'LastPage');
export const ChevronLeft = createIcon(chevronLeft, 'ChevronLeft');
export const ChevronRight = createIcon(chevronRight, 'ChevronRight');
export const NavigateBefore = createIcon(chevronLeft, 'NavigateBefore');
export const NavigateNext = createIcon(chevronRight, 'NavigateNext');
export const CheckBox = createIcon(checkBox, 'CheckBox');
export const CheckBoxOutlined = createIcon(
  checkBoxOutlined,
  'CheckBoxOutlined',
);
export const CheckBoxOutlineBlank = createIcon(
  checkBoxOutlineBlank,
  'CheckBoxOutlineBlank',
);
export const IndeterminateCheckBox = createIcon(
  indeterminateCheckBox,
  'IndeterminateCheckBox',
);
export const IndeterminateCheckBoxOutlined = createIcon(
  indeterminateCheckBoxOutlined,
  'IndeterminateCheckBoxOutlined',
);
export const Add = createIcon(add, 'Add');
export const Remove = createIcon(remove, 'Remove');
export const ThumbUp = createIcon(thumbUp, 'ThumbUp');
export const ThumbDown = createIcon(thumbDown, 'ThumbDown');
export const ThumbUpFilled = createIcon(thumbUpFilled, 'ThumbUpFilled');
export const ThumbDownFilled = createIcon(thumbDownFilled, 'ThumbDownFilled');
export const Visibility = createIcon(visibility, 'Visibility');
export const InsertDriveFile = createIcon(insertDriveFile, 'InsertDriveFile');
export const ForwardToInbox = createIcon(forwardToInbox, 'ForwardToInbox');
export const PlayCircle = createIcon(playCircle, 'PlayCircle');
export const NewConversation = createIcon(newConversation, 'NewConversation');
export const LeaveChatroom = createIcon(leaveChatroom, 'LeaveChatroom');
export const Logo = createIcon(logo, 'Logo');
export const Check = createIcon(check, 'Check');
export const Search = createIcon(search, 'Search');
