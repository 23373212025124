
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ContactAgentByEmailChatFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ContactAgentByEmailChat"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plainText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"media"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMediaFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"attachments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMediaFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"nextInputType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"assigned"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"useAutoComplete"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hidden"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":280}};
    doc.loc.source = {"body":"fragment ContactAgentByEmailChatFragment on ContactAgentByEmailChat {\n  id\n  createdAt\n  message\n  plainText\n  media {\n    ...ChatMediaFragment\n  }\n  attachments {\n    ...ChatMediaFragment\n  }\n  nextInputType\n  # requiredInput\n  assigned {\n    id\n  }\n  useAutoComplete\n  hidden\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
