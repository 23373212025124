import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';

import { makeStyles, Styles, StyledComponentProps, Color } from '@/styles';
import { capitalize } from '@/utils';

import { ButtonBase, ButtonBaseProps } from './ButtonBase';

const styles: Styles<
  | 'root'
  | 'label'
  | 'primary'
  | 'secondary'
  | 'blue'
  | 'extended'
  | 'focusVisible'
  | 'disabled'
  | 'colorInherit'
  | 'sizeSmall'
  | 'sizeMedium'
> = theme => ({
  root: {
    ...theme.typography.button,
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    minHeight: '36px',
    transition: theme.transitions.create(
      ['background-color', 'box-shadow', 'border'],
      {
        duration: theme.transitions.duration.short,
      },
    ),
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
    width: 56,
    height: 56,
    boxShadow: theme.shadows[6],
    '&:active': {
      boxShadow: theme.shadows[12],
    },
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey.A100,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[300],
      },
      '&$disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
      textDecoration: 'none',
    },
    '&$focusVisible': {
      boxShadow: theme.shadows[6],
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  label: {
    width: '100%', // assure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  primary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  secondary: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  blue: {
    color: theme.palette.blue.contrastText,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.blue.main,
      },
    },
  },
  extended: {
    borderRadius: `${48 / 2}px`,
    padding: '0 16px',
    width: 'auto',
    minHeight: 'auto',
    minWidth: '48px',
    height: '48px',
    '&$sizeSmall': {
      width: 'auto',
      padding: '0 8px',
      borderRadius: `${34 / 2}px`,
      minWidth: '34px',
      height: '34px',
    },
    '&$sizeMedium': {
      width: 'auto',
      padding: '0 16px',
      borderRadius: `${40 / 2}px`,
      minWidth: '40px',
      height: '40px',
    },
  },
  focusVisible: {},
  disabled: {},
  colorInherit: {
    color: 'inherit',
  },
  sizeSmall: {
    width: '40px',
    height: '40px',
  },
  sizeMedium: {
    width: '48px',
    height: '48px',
  },
});

const useStyles = makeStyles(styles);
const fabClasses = cn('alli-sdk-Fab');

export interface FabProps
  extends StyledComponentProps<typeof styles>,
    Omit<ButtonBaseProps, 'classes'> {
  color?: Color;
  size?: 'small' | 'medium' | 'large';
  variant?: 'round' | 'extended';
}

export const Fab = forwardRef<HTMLButtonElement, FabProps>(
  (
    {
      color = 'default',
      size = 'large',
      variant = 'round',
      classes: classesProp,
      children,
      ...other
    },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp });

    return (
      <ButtonBase
        {...other}
        ref={ref}
        className={clsx(
          classes.root,
          {
            [classes.extended]: variant === 'extended',
            [classes.primary]: color === 'primary',
            [classes.secondary]: color === 'secondary',
            [classes[`size${capitalize(size)}` as keyof typeof classes]]:
              size !== 'large',
            [classes.disabled]: other.disabled,
            [classes.colorInherit]: color === 'inherit',
          },
          fabClasses({
            variant,
            color,
            size,
            disabled: other.disabled,
          }),
          other.className,
        )}
      >
        <span className={clsx(classes.label, fabClasses('label'))}>
          {children}
        </span>
      </ButtonBase>
    );
  },
);
