export type ID = string | number;

export interface Node {
  id: ID;
}

export type Float = number;
export type DateTime = string;
export type Int = number;
export type JSONString = string;

/**
 * It is some value
 * use "isSome" utils to check validation.
 */
export type Some<T> = T;

/**
 * It is null or undefined that we called nil
 * use "isNone" utils to check validation.
 */
export type None = null | undefined;

/**
 * It is some or none
 * use "isSome" or "isNone" utils to check validation considering what you want.
 */
export type Option<T> = Some<T> | None;

/**
 * It is some or null
 * use "isSome" or "isNull" utils to check validation considering what you want.
 */
export type Nullable<T> = Some<T> | null;

/**
 * It is some or undefined
 * use "isSome" or "isUndefined" utils to check validation considering what you want.
 */
export type Optional<T> = Some<T> | undefined;

export interface Typename<T> {
  __typename: T;
}

export interface Edge<T, N> extends Typename<T> {
  cursor: string;
  node: N;
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: Nullable<string>;
  endCursor: Nullable<string>;
}

export const err5xx = new RegExp(/^5\d{2}$/);
export const err4xx = new RegExp(/^4\d{2}$/);
