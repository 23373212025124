import React, {
  FunctionComponent,
  createContext,
  ComponentType,
  useContext,
} from 'react';

import { Settings } from '../Settings';

const SettingsContext = createContext((null as unknown) as Settings);
export const useSettings = (): Settings => useContext(SettingsContext);

export interface InjectedSettingsProps {
  settings: Settings;
}

export function withSettings<T extends InjectedSettingsProps>(
  WrappedComponent: ComponentType<T>,
): FunctionComponent<Omit<T, keyof InjectedSettingsProps>> {
  return props => (
    <SettingsContext.Consumer>
      {(s: Settings | null) =>
        // @ts-ignore
        s && <WrappedComponent {...props} settings={s} />
      }
    </SettingsContext.Consumer>
  );
}

interface SettingsProviderProps {
  settings: Settings;
}

export const SettingsProvider: FunctionComponent<SettingsProviderProps> = props => (
  <SettingsContext.Provider value={props.settings}>
    {props.children}
  </SettingsContext.Provider>
);
