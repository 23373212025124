import React from 'react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { theme } from './theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <EmotionThemeProvider theme={theme}>
    <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
  </EmotionThemeProvider>
);
