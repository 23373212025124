import { useCallback, useRef, useEffect, DependencyList } from 'react';

export const useEventCallback = <T extends (...args: any[]) => any>(
  fn: T,
  deps: DependencyList = [],
): T => {
  const ref = useRef(fn);

  useEffect(() => {
    ref.current = fn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...deps]);

  const cb = useCallback((...args: any[]) => ref.current(...args), [ref]);
  return cb as T;
};
