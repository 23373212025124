import { useRef, useEffect } from 'react';

export const usePreviousRef = <T>(state: T) => {
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return stateRef;
};

export const usePrevious = <T>(state: T) => {
  const previousRef = usePreviousRef(state);
  return previousRef.current;
};
