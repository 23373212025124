import { palette } from './palette';
import { shadows } from './shadows';
import { typography, Variant as VariantAlias } from './typography';
import { spacing } from './spacing';
import { transitions } from './transitions';
import { zIndex } from './zIndex';

export const theme = {
  direction: 'ltr',
  palette,
  transitions,
  shape: {
    borderRadius: 4,
  },
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    unit: 'px',
  },
  shadows,
  spacing,
  zIndex,
  sonicSilver: '#757575',
  lightPink: '#b8b8b8',
  lightPink2: '#e0e0e0',
  greyish: '#b5b5b5',
  greyishBrown: '#4a4a4a',
  secondaryMain: '#3023ae',
};

export type Theme = typeof theme;

export type Color = 'inherit' | 'primary' | 'secondary' | 'blue' | 'default';
export type Alignment = 'inherit' | 'left' | 'center' | 'right' | 'justify';
export type Variant = VariantAlias;
