import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';

import { ConversationState, ChatInputType, ID } from '@/models';

import { Chat, BotChat } from '../components/ChatList/ConversationChatsQuery';

import { getLastMessage } from './LastMessage';

export const hasOptions = (chat: Chat): chat is BotChat =>
  chat.__typename === 'BotChat' && chat.chatOptionInfos !== undefined;

export const hasFaqCarousel = (chat: Chat): chat is BotChat =>
  chat.__typename === 'BotChat' && chat.faqCarousel !== undefined;

export const shouldResubscribe = (currentProps: any, nextProps: any) =>
  !isEqual(currentProps.variables, nextProps.variables) ||
  currentProps.subscription !== nextProps.subscription;

export const needContactAgentByEmailRichUX = (chat: Chat): chat is BotChat =>
  chat.__typename === 'BotChat' &&
  chat.nextInputType &&
  chat.nextInputType.includes(ChatInputType.CONTACT_AGENT_BY_EMAIL_RICH_UX);

export const isFormInput = (chatInputTypes: ChatInputType[]): boolean =>
  chatInputTypes.includes(ChatInputType.CONTACT_AGENT_BY_EMAIL_RICH_UX) ||
  chatInputTypes.includes(ChatInputType.FILL_SLOT_RICH_UX) ||
  chatInputTypes.includes(ChatInputType.SEND_FORM_RICH_UX);

export const isTextAreaEnableInput = (
  chatInputTypes: ChatInputType[],
): boolean =>
  chatInputTypes.length === 0 ||
  chatInputTypes.includes(ChatInputType.STRING) ||
  chatInputTypes.includes(ChatInputType.NUMBER) ||
  chatInputTypes.includes(ChatInputType.BOOLEAN) ||
  chatInputTypes.includes(ChatInputType.DATETIME);

export const isIncludedFileTypeInput = (
  chatInputTypes: ChatInputType[],
): boolean => chatInputTypes.includes(ChatInputType.FILE);

export const isDateTimeInput = (chatInputTypes: ChatInputType[]): boolean =>
  chatInputTypes.length === 1 &&
  chatInputTypes.includes(ChatInputType.DATETIME);

export const isNumberInput = (chatInputTypes: ChatInputType[]): boolean =>
  chatInputTypes.length === 1 && chatInputTypes.includes(ChatInputType.NUMBER);

export const isImageType = (type: string): boolean =>
  startsWith(type, 'image/');

export const needCalendar = (chatInputTypes: ChatInputType[]): boolean =>
  chatInputTypes.includes(ChatInputType.DATETIME);

export const isConnectedAgent = (state: ConversationState) =>
  state === ConversationState.WAIT_AGENT ||
  state === ConversationState.INTERRUPTED;

export const isInactiveConversation = (conversationState: ConversationState) =>
  [
    ConversationState.END_OF_CHATBOT,
    ConversationState.END_BY_AGENT,
    ConversationState.END_BY_USER,
    ConversationState.END_OF_OBSERVER,
  ].includes(conversationState);

export const isOpenableChat = (cursor: ID) => {
  const lastCursor = getLastMessage();
  return !(lastCursor !== null && lastCursor === cursor);
};

export const shouldConnectWS = (state: ConversationState) =>
  state !== ConversationState.INIT &&
  state !== ConversationState.END_BY_AGENT &&
  state !== ConversationState.END_BY_USER &&
  state !== ConversationState.END_OF_CHATBOT &&
  state !== ConversationState.END_OF_OBSERVER;
