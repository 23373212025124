import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@/styles';
import { ReactComponent as PageErr } from '../Icons/generalPageErr.svg';
import { ReactComponent as Code404 } from '../Icons/ic-404.svg';
import { ReactComponent as Code500 } from '../Icons/ic-500.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    height: '100vh',
    padding: theme.spacing(2),
    position: 'absolute',
    top: 0,
    width: '100%',
    background: 'white',
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5, 0),
    fontWeight: 'bold',
  },
  subTitle: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0),
  },
}));
interface NotFoundProps extends React.HTMLAttributes<HTMLElement> {
  emailTokenExpired?: boolean;
  errorStatusCode?: number | null;
}

const NotFound = forwardRef<HTMLElement, NotFoundProps>((props, ref) => {
  const classes = useStyles();
  const { emailTokenExpired, errorStatusCode } = props;
  let errIcon = <PageErr />;
  let pageTitle = (
    <FormattedMessage
      id="NotFound.Title.generic.error"
      defaultMessage="Page Not Found"
      description="Generic error title"
    />
  );
  let subTitle = (
    <FormattedMessage
      id="NotFound.Description.generic.error"
      defaultMessage="Service Unavailable"
      description="Generic error description"
    />
  );
  if (errorStatusCode === 404) {
    errIcon = <Code404 fill="rgb(127, 0, 246)" />;
    pageTitle = (
      <FormattedMessage
        id="NotFound.Title.404.error"
        defaultMessage="Page Not Found"
        description="404 error title"
      />
    );
    subTitle = (
      <FormattedMessage
        id="NotFound.Description.404.error"
        defaultMessage="Service Unavailable"
        description="404 error description"
      />
    );
  } else if (errorStatusCode === 500) {
    errIcon = <Code500 fill="rgb(127, 0, 246)" />;
    pageTitle = (
      <FormattedMessage
        id="NotFound.Title.500.error"
        defaultMessage="Page Not Found"
        description="500 error title"
      />
    );
    subTitle = (
      <FormattedMessage
        id="NotFound.Description.500.error"
        defaultMessage="Service Unavailable"
        description="500 error description"
      />
    );
  } else if (emailTokenExpired) {
    subTitle = (
      <FormattedMessage
        id="NotFound.Email.Token.Expired"
        defaultMessage="The requested conversation has been expired"
        description="Email token expiration description"
      />
    );
  }

  return (
    <section className={classes.root} ref={ref}>
      <div className={classes.icon}>{errIcon}</div>
      <div className={classes.title}>{pageTitle}</div>
      <h2 className={classes.subTitle}>{subTitle}</h2>
    </section>
  );
});

NotFound.displayName = 'NotFound';
export default NotFound;
