
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FillSlotRichUXChatFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FillSlotRichUXChat"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"message"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"media"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatMediaFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"requiredInput"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slotValues"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ChatSlotValueFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"nextInputType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"useAutoComplete"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hidden"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":316}};
    doc.loc.source = {"body":"#import './ChatMediaFragment.graphql'\n#import './ChatSlotValueFragment.graphql'\n\nfragment FillSlotRichUXChatFragment on FillSlotRichUXChat {\n  id\n  message\n  createdAt\n  media {\n    ...ChatMediaFragment\n  }\n  requiredInput\n  slotValues {\n    ...ChatSlotValueFragment\n  }\n  nextInputType\n  useAutoComplete\n  hidden\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./ChatMediaFragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./ChatSlotValueFragment.graphql').definitions));


      module.exports = doc;
    
