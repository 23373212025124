import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';

import { makeStyles, Styles, StyledComponentProps } from '@/styles';
import { capitalize } from '@/utils';

import { useButtonBase } from './ButtonBase';

const styles: Styles<
  | 'root'
  | 'underlineNone'
  | 'underlineHover'
  | 'underlineAlways'
  | 'button'
  | 'disabled'
  | 'focusVisible'
> = {
  root: {},
  underlineNone: {
    textDecoration: 'none',
  },
  underlineHover: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  underlineAlways: {
    textDecoration: 'underline',
  },
  button: {
    position: 'relative',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent', // Reset default value
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
    border: 0,
    margin: 0, // Remove the margin in Safari
    borderRadius: 0,
    padding: 0, // Remove the padding in Firefox
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    '-moz-appearance': 'none', // Reset
    '-webkit-appearance': 'none', // Reset
    '&::-moz-focus-inner': {
      borderStyle: 'none', // Remove Firefox dotted outline.
    },
    '&$focusVisible': {
      outline: 'auto',
    },
  },
  disabled: {},
  focusVisible: {},
};

const useStyles = makeStyles(styles);
const linkClasses = cn('alli-sdk-Link');

interface LinkBaseProps extends StyledComponentProps<typeof styles> {
  underline?: 'none' | 'hover' | 'always';
}

interface AnchorLinkProps
  extends LinkBaseProps,
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    > {
  disabled?: boolean;
}

export const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
  (
    { underline = 'hover', disabled, classes: classesProp, children, ...other },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp });

    const {
      focusVisible,
      tabIndex,
      className,
      onFocus,
      onBlur,
      onKeyDown,
    } = useButtonBase({
      ...other,
      disabled,
      classes,
    });

    return (
      <a
        role="link"
        aria-disabled={disabled}
        {...other}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        className={clsx(
          className,
          classes[`underline${capitalize(underline)}` as keyof typeof classes],
          linkClasses({
            disabled,
            focusVisible: focusVisible && !disabled,
            underline,
          }),
          other.className,
        )}
        ref={ref}
      >
        {children}
      </a>
    );
  },
);

interface LinkButtonProps
  extends LinkBaseProps,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {}

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ underline = 'hover', classes: classesProp, ...other }, ref) => {
    const classes = useStyles({ classes: classesProp });

    const {
      focusVisible,
      tabIndex,
      className,
      onFocus,
      onBlur,
      onKeyDown,
    } = useButtonBase({
      ...other,
      classes,
    });

    return (
      <button
        type="button"
        {...other}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
        className={clsx(
          className,
          classes.button,
          classes[`underline${capitalize(underline)}` as keyof typeof classes],
          linkClasses({
            button: true,
            focusVisible,
            underline,
          }),
          other.className,
        )}
        ref={ref}
      />
    );
  },
);
