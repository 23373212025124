const SURROGATE_UNITS_REGEX = /[\uD800-\uDFFF]/g;

const hasSurrogateUnit = (str: string) => {
  return SURROGATE_UNITS_REGEX.test(str);
};

export const refinedRange = (text: string, offset: number, length: number) => {
  if (!hasSurrogateUnit(text)) {
    return {
      offset,
      length,
    };
  }

  let _offset = offset;
  let _length = length;

  let text1 = text.slice(0, offset);
  let matches1 = text1.match(SURROGATE_UNITS_REGEX) || [];
  let length1 = matches1.length;
  while (true) {
    if (length1 > 0) {
      _offset = offset + Math.ceil(length1 / 2);
      text1 = text.slice(0, _offset);
      matches1 = text1.match(SURROGATE_UNITS_REGEX) || [];
      if (matches1.length === length1) {
        break;
      } else {
        length1 = matches1.length;
      }
    } else {
      break;
    }
  }

  let text2 = text.slice(0, _offset + _length);
  let matches2 = text2.match(SURROGATE_UNITS_REGEX) || [];
  let length2 = matches2.length;
  while (true) {
    if (length1 === length2) {
      break;
    } else {
      _length = length + Math.ceil((length2 - length1) / 2);
      text2 = text.slice(0, _offset + _length);
      matches2 = text2.match(SURROGATE_UNITS_REGEX) || [];
      if (matches2.length === length2) {
        break;
      } else {
        length2 = matches2.length;
      }
    }
  }

  return {
    offset: _offset,
    length: _length,
  };
};
