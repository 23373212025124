// eslint-disable-next-line import/no-extraneous-dependencies
import { OperationVariables } from '@apollo/react-common';
import {
  useQuery,
  QueryHookOptions,
  useLazyQuery,
  LazyQueryHookOptions,
} from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

export function makeQuery<TData = any, TVariables = OperationVariables>(
  doc: DocumentNode,
) {
  const useQueryHook = (options?: QueryHookOptions<TData, TVariables>) =>
    useQuery(doc, options);

  useQueryHook.query = doc;
  return useQueryHook;
}

export function makeLazyQuery<TData = any, TVariables = OperationVariables>(
  doc: DocumentNode,
) {
  const useLazyQueryHook = (
    options?: LazyQueryHookOptions<TData, TVariables>,
  ) => useLazyQuery(doc, options);

  useLazyQueryHook.query = doc;
  return useLazyQueryHook;
}
