import { FunctionComponent, useEffect } from 'react';

import { useConversation } from './ConversationProvider';
import { useSettings } from './SettingsProvider';

interface StartingConversationProps {
  start?(): any;
}

const StartingConversation: FunctionComponent<StartingConversationProps> = ({
  start,
}) => {
  const settings = useSettings();
  const { loadConversation, startOver } = useConversation();
  useEffect(() => {
    if (
      !startOver &&
      settings &&
      settings.apiKey &&
      settings.user &&
      settings.user.id
    ) {
      loadConversation(settings.apiKey, settings.user.id);
    }
  }, [settings, startOver, loadConversation]);

  useEffect(() => {
    if (start) {
      start();
    }
  }, [start]);
  return null;
};

export default StartingConversation;
