import { Typename, Node, Nullable } from './graphql';

export enum Locale {
  en = 'en',
  ko = 'ko',
  ja = 'ja',
}

export interface User extends Typename<'User'>, Node {
  avatar: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  locale: Nullable<Locale>;
  ownUserId: Nullable<string>;
  temporary: Nullable<boolean>;
  email: Nullable<string>;
}
