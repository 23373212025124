import { useState, useCallback } from 'react';

export const useToggle = (
  initialState: boolean | (() => boolean),
): [
  boolean,
  () => void,
  () => void,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(prev => !prev), []);
  const on = useCallback(() => setState(true), []);
  const off = useCallback(() => setState(false), []);
  return [state, toggle, on, off, setState];
};
