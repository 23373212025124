import {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';

const useEnhancedEffect =
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'test'
    ? useLayoutEffect
    : useEffect;

export const useMountedState = ({ defer = false } = {}): boolean => {
  const [mounted, setMounted] = useState(false);

  useEnhancedEffect(() => {
    if (!defer) {
      setMounted(true);
    }
  }, [defer]);

  useEffect(() => {
    if (defer) {
      setMounted(true);
    }
  }, [defer]);

  return mounted;
};

export const useMountedRef = ({
  defer = false,
} = {}): MutableRefObject<boolean> => {
  const mountedRef = useRef(false);

  useEnhancedEffect(() => {
    if (!defer) {
      mountedRef.current = true;
    }
  }, [defer]);

  useEffect(() => {
    if (defer) {
      mountedRef.current = true;
    }
  }, [defer]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return mountedRef;
};
