import env from '@allganize/penv.macro';

type FeatureMatchRegArray = RegExpMatchArray | null;
type ConfigIdentifyCallback = (
  isFeature: FeatureMatchRegArray,
  isFeatureOnlyFront: FeatureMatchRegArray,
) => string;

function getConfigValueToCheckFeature(
  identify: ConfigIdentifyCallback,
): string {
  const isFeatureBranch = (process.env.STAGE || '').match(/^feature-(.+)$/i);
  const isFeatureOnlyFront = (process.env.STAGE || '').match(/^staging-(.+)$/i);

  return identify(isFeatureBranch, isFeatureOnlyFront);
}

export const config = {
  // GraphQL endpoint URL
  graphqlUri: env(
    {
      production: 'https://backend.alli.ai/d/user/',
      travelers: 'https://travelers-backend.alli.ai/d/user/',
      'travelers-dev': 'https://travelers-dev-backend.alli.ai/d/user/',
      staging: 'https://staging-backend.alli.ai/d/user/',
      'staging-hotfix': 'https://backend-staging-hotfix.alli.ai/d/user/',
      'staging-reg1': 'https://backend-staging-reg1.alli.ai/d/user/',
      'staging-reg2': 'https://backend-staging-reg2.alli.ai/d/user/',
      beta3: 'https://beta3-backend.alli.ai:1445/d/user/',
      local: 'http://localhost:8100/user/',
      jisu: 'https://backend.alli.ai/d/user/',
      hyundai: 'https://alli.hyundai.net/be/d/user/',
      hyundai_local_test: 'https://hcs.alli.ai/be/d/user/',
      smbc: 'https://allijsol.japaneast.cloudapp.azure.com/be/d/user/',
      smbc_jsol_ip: 'https://10.240.0.100/be/d/user/',
      smbc_jri_ip: 'https://10.11.0.11/be/d/user/',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/user/',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/user/',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/user/',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/user/',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/user/',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/user/',
      jpower: 'https://jpallisv.jpower.local/be/d/user/',
      busan_bank: 'https://alli.busanbank.com/be/d/user/',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `https://backend-${isFeatureBranch[0]}.alli.ai/d/user/`;
      }
      if (isFeatureOnlyFront) {
        return 'https://staging-backend.alli.ai/d/user/';
      }
      return 'https://backend.alli.ai/d/user/';
    }),
  ),
  graphqlJaUri: env(
    {
      production: 'https://backend-ja.alli.ai/d/user/',
      travelers: 'https://travelers-backend.alli.ai/d/user/',
      'travelers-dev': 'https://travelers-dev-backend.alli.ai/d/user/',
      staging: 'https://staging-backend.alli.ai/d/user/',
      'staging-hotfix': 'https://backend-staging-hotfix-ja.alli.ai/d/user/',
      'staging-reg1': 'https://backend-staging-reg1-ja.alli.ai/d/user/',
      'staging-reg2': 'https://backend-staging-reg2-ja.alli.ai/d/user/',
      rc: 'https://rc-backend-ja.alli.ai/d/user/',
      beta3: 'https://beta3-backend.alli.ai:1445/d/user/',
      local: 'https://beta3-backend.alli.ai:1445/d/user/',
      jisu: 'https://backend-ja.alli.ai/d/user/',
      dhrubesh: 'https://beta3-backend.alli.ai:1445/d/user/',
      hyundai: 'https://alli.hyundai.net/be/d/user/',
      hyundai_local_test: 'https://hcs.alli.ai/be/d/user/',
      smbc: 'https://allijsol.japaneast.cloudapp.azure.com/be/d/user/',
      smbc_jsol_ip: 'https://10.240.0.100/be/d/user/',
      smbc_jri_ip: 'https://10.11.0.11/be/d/user/',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/user/',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/user/',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/user/',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/user/',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/user/',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/user/',
      jpower: 'https://jpallisv.jpower.local/be/d/user/',
      busan_bank: 'https://alli.busanbank.com/be/d/user/',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `https://beta3-backend.alli.ai:1445/d/user/`;
      }
      if (isFeatureOnlyFront) {
        return 'https://beta3-backend.alli.ai:1445/d/user/';
      }
      return 'https://backend-ja.alli.ai/d/user/';
    }),
  ),
  graphqlKrUri: env(
    {
      production: 'https://backend-kr.alli.ai/d/user/',
      travelers: 'https://travelers-backend.alli.ai/d/user/',
      'travelers-dev': 'https://travelers-dev-backend.alli.ai/d/user/',
      staging: 'https://staging-backend.alli.ai/d/user/',
      'staging-hotfix': 'https://backend-staging-hotfix-kr.alli.ai/d/user/',
      'staging-reg1': 'https://backend-staging-reg1-kr.alli.ai/d/user/',
      'staging-reg2': 'https://backend-staging-reg2-kr.alli.ai/d/user/',
      beta3: 'https://beta3-backend.alli.ai:1445/d/user/',
      local: 'http://localhost:8100/user/',
      jisu: 'https://backend-kr.alli.ai/d/user/',
      hyundai: 'https://alli.hyundai.net/be/d/user/',
      hyundai_local_test: 'https://hcs.alli.ai/be/d/user/',
      smbc: 'https://allijsol.japaneast.cloudapp.azure.com/be/d/user/',
      smbc_jsol_ip: 'https://10.240.0.100/be/d/user/',
      smbc_jri_ip: 'https://10.11.0.11/be/d/user/',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/user/',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/user/',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/user/',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/user/',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/user/',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/user/',
      jpower: 'https://jpallisv.jpower.local/be/d/user/',
      busan_bank: 'https://alli.busanbank.com/be/d/user/',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `https://backend-${isFeatureBranch[0]}.alli.ai/d/user/`;
      }
      if (isFeatureOnlyFront) {
        return 'https://staging-backend.alli.ai/d/user/';
      }
      return 'https://backend-kr.alli.ai/d/user/';
    }),
  ),
  // GraphQL subscription websocket URL
  graphqlSubscriptionUri: env(
    {
      production: 'wss://backend.alli.ai/d/ws/user',
      travelers: 'wss://travelers-backend.alli.ai/d/ws/user',
      'travelers-dev': 'wss://travelers-dev-backend.alli.ai/d/ws/user',
      staging: 'wss://staging-backend.alli.ai/d/ws/user',
      'staging-hotfix': 'wss://backend-staging-hotfix.alli.ai/d/ws/user',
      'staging-reg1': 'wss://backend-staging-reg1.alli.ai/d/ws/user',
      'staging-reg2': 'wss://backend-staging-reg2.alli.ai/d/ws/user',
      beta3: 'wss://beta3-backend.alli.ai:1445/d/ws/user',
      local: 'ws://localhost:8100/ws/user',
      jisu: 'wss://backend.alli.ai/d/ws/user',
      hyundai: 'wss://alli.hyundai.net/be/d/ws/user',
      hyundai_local_test: 'wss://hcs.alli.ai/be/d/ws/user',
      smbc: 'wss://allijsol.japaneast.cloudapp.azure.com/be/d/ws/user',
      smbc_jsol_ip: 'wss://10.240.0.100/be/d/ws/user',
      smbc_jri_ip: 'wss://10.11.0.11/be/d/ws/user',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/ws/user',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/ws/user',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/ws/user',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/ws/user',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/ws/user',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/ws/user',
      jpower: 'https://jpallisv.jpower.local/be/d/ws/user',
      busan_bank: 'wss://alli.busanbank.com/d/ws/user',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `wss://backend-${isFeatureBranch[0]}.alli.ai/d/ws/user`;
      }
      if (isFeatureOnlyFront) {
        return 'wss://staging-backend.alli.ai/d/ws/user';
      }
      return 'wss://backend.alli.ai/d/ws/user';
    }),
  ),
  graphqlSubscriptionJaUri: env(
    {
      production: 'wss://backend-ja.alli.ai/d/ws/user',
      travelers: 'wss://travelers-backend.alli.ai/d/ws/user',
      'travelers-dev': 'wss://travelers-dev-backend.alli.ai/d/ws/user',
      staging: 'wss://beta3-backend.alli.ai:1445/d/ws/user',
      'staging-hotfix': 'wss://backend-staging-hotfix-ja.alli.ai/d/ws/user',
      'staging-reg1': 'wss://backend-staging-reg1-ja.alli.ai/d/ws/user',
      'staging-reg2': 'wss://backend-staging-reg2-ja.alli.ai/d/ws/user',
      beta3: 'wss://beta3-backend.alli.ai:1445/d/ws/user',
      local: 'wss://beta3-backend.alli.ai:1445/d/ws/user',
      jisu: 'wss://backend-ja.alli.ai/d/ws/user',
      dhrubesh: 'wss://beta3-backend.alli.ai:1445/d/ws/user',
      hyundai: 'wss://alli.hyundai.net/be/d/ws/user',
      hyundai_local_test: 'wss://hcs.alli.ai/be/d/ws/user',
      smbc: 'wss://allijsol.japaneast.cloudapp.azure.com/be/d/ws/user',
      smbc_jsol_ip: 'wss://10.240.0.100/be/d/ws/user',
      smbc_jri_ip: 'wss://10.11.0.11/be/d/ws/user',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/ws/user',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/ws/user',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/ws/user',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/ws/user',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/ws/user',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/ws/user',
      jpower: 'https://jpallisv.jpower.local/be/d/ws/user',
      busan_bank: 'https://alli.busanbank.com/be/d/ws/user',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return 'wss://beta3-backend.alli.ai:1445/d/ws/user';
      }
      if (isFeatureOnlyFront) {
        return 'wss://beta3-backend.alli.ai:1445/d/ws/user';
      }
      return 'wss://backend-ja.alli.ai/d/ws/user';
    }),
  ),
  graphqlSubscriptionKrUri: env(
    {
      production: 'wss://backend-kr.alli.ai/d/ws/user',
      travelers: 'wss://travelers-backend.alli.ai/d/ws/user',
      'travelers-dev': 'wss://travelers-dev-backend.alli.ai/d/ws/user',
      staging: 'wss://staging-backend.alli.ai/d/ws/user',
      'staging-hotfix': 'wss://backend-staging-hotfix-kr.alli.ai/d/ws/user',
      'staging-reg1': 'wss://backend-staging-reg1-kr.alli.ai/d/ws/user',
      'staging-reg2': 'wss://backend-staging-reg2-kr.alli.ai/d/ws/user',
      beta3: 'wss://beta3-backend.alli.ai:1445/d/ws/user',
      local: 'ws://localhost:8100/ws/user',
      jisu: 'wss://backend-kr.alli.ai/d/ws/user',
      hyundai: 'wss://alli.hyundai.net/be/d/ws/user',
      hyundai_local_test: 'wss://hcs.alli.ai/be/d/ws/user',
      smbc: 'wss://allijsol.japaneast.cloudapp.azure.com/be/d/ws/user',
      smbc_jsol_ip: 'wss://10.240.0.100/be/d/ws/user',
      smbc_jri_ip: 'wss://10.11.0.11/be/d/ws/user',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/ws/user',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/ws/user',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/ws/user',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/ws/user',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/ws/user',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/ws/user',
      jpower: 'https://jpallisv.jpower.local/be/d/ws/user',
      busan_bank: 'wss://alli.busanbank.com/d/ws/user',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `wss://backend-${isFeatureBranch[0]}.alli.ai/d/ws/user`;
      }
      if (isFeatureOnlyFront) {
        return 'wss://staging-backend.alli.ai/d/ws/user';
      }
      return 'wss://backend-kr.alli.ai/d/ws/user';
    }),
  ),
  // GraphQL internal endpoint URL
  graphqlInternalUri: env(
    {
      production: 'https://backend.alli.ai/d/graphql/',
      travelers: 'https://travelers-backend.alli.ai/d/graphql/',
      'travelers-dev': 'https://travelers-dev-backend.alli.ai/d/graphql/',
      staging: 'https://staging-backend.alli.ai/d/graphql/',
      'staging-hotfix': 'https://backend-staging-hotfix.alli.ai/d/graphql/',
      'staging-reg1': 'https://backend-staging-reg1.alli.ai/d/graphql/',
      'staging-reg2': 'https://backend-staging-reg2.alli.ai/d/graphql/',
      beta3: 'https://beta3-backend.alli.ai:1445/d/graphql/',
      local: 'http://localhost:8100/graphql/',
      jisu: 'https://backend.alli.ai/d/graphql/',
      hyundai: 'https://alli.hyundai.net/be/d/graphql/',
      hyundai_local_test: 'https://hcs.alli.ai/be/d/graphql/',
      smbc: 'https://allijsol.japaneast.cloudapp.azure.com/be/d/graphql/',
      smbc_jsol_ip: 'https://10.240.0.100/be/d/graphql/',
      smbc_jri_ip: 'https://10.11.0.11/be/d/graphql/',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/graphql/',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/graphql/',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/graphql/',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/graphql/',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/graphql/',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/graphql/',
      jpower: 'https://jpallisv.jpower.local/be/d/graphql/',
      busan_bank: 'https://alli.busanbank.com/be/d/graphql/',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `https://backend-${isFeatureBranch[0]}.alli.ai/d/graphql/`;
      }
      if (isFeatureOnlyFront) {
        return 'https://staging-backend.alli.ai/d/graphql/';
      }
      return 'https://backend.alli.ai/d/graphql/';
    }),
  ),
  graphqlInternalJaUri: env(
    {
      production: 'https://backend-ja.alli.ai/d/graphql/',
      travelers: 'https://travelers-backend.alli.ai/d/graphql/',
      'travelers-dev': 'https://travelers-dev-backend.alli.ai/d/graphql/',
      staging: 'https://beta3-backend.alli.ai:1445/d/graphql/',
      'staging-hotfix': 'https://backend-staging-hotfix-ja.alli.ai/d/graphql/',
      'staging-reg1': 'https://backend-staging-reg1-ja.alli.ai/d/graphql/',
      'staging-reg2': 'https://backend-staging-reg2-ja.alli.ai/d/graphql/',
      beta3: 'https://beta3-backend.alli.ai:1445/d/graphql/',
      local: 'https://beta3-backend.alli.ai:1445/d/graphql/',
      jisu: 'https://backend-ja.alli.ai/d/graphql/',
      hyundai: 'https://alli.hyundai.net/be/d/graphql/',
      hyundai_local_test: 'https://hcs.alli.ai/be/d/graphql/',
      smbc: 'https://allijsol.japaneast.cloudapp.azure.com/be/d/graphql/',
      smbc_jsol_ip: 'https://10.240.0.100/be/d/graphql/',
      smbc_jri_ip: 'https://10.11.0.11/be/d/graphql/',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/graphql/',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/graphql/',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/graphql/',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/graphql/',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/graphql/',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/graphql/',
      jpower: 'https://jpallisv.jpower.local/be/d/graphql/',
      busan_bank: 'https://alli.busanbank.com/be/d/graphql/',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `https://beta3-backend.alli.ai:1445/d/graphql/`;
      }
      if (isFeatureOnlyFront) {
        return 'https://beta3-backend.alli.ai:1445/d/graphql/';
      }
      return 'https://backend-ja.alli.ai/d/graphql/';
    }),
  ),
  graphqlInternalKrUri: env(
    {
      production: 'https://backend-kr.alli.ai/d/graphql/',
      travelers: 'https://travelers-backend.alli.ai/d/graphql/',
      'travelers-dev': 'https://travelers-dev-backend.alli.ai/d/graphql/',
      staging: 'https://staging-backend.alli.ai/d/graphql/',
      'staging-hotfix': 'https://backend-staging-hotfix-kr.alli.ai/d/graphql/',
      'staging-reg1': 'https://backend-staging-reg1-kr.alli.ai/d/graphql/',
      'staging-reg2': 'https://backend-staging-reg2-kr.alli.ai/d/graphql/',
      beta3: 'https://beta3-backend.alli.ai:1445/d/graphql/',
      local: 'http://localhost:8100/graphql/',
      jisu: 'https://backend-kr.alli.ai/d/graphql/',
      hyundai: 'https://alli.hyundai.net/be/d/graphql/',
      hyundai_local_test: 'https://hcs.alli.ai/be/d/graphql/',
      smbc: 'https://allijsol.japaneast.cloudapp.azure.com/be/d/graphql/',
      smbc_jsol_ip: 'https://10.240.0.100/be/d/graphql/',
      smbc_jri_ip: 'https://10.11.0.11/be/d/graphql/',
      smbc_nikko_prod: 'https://ipcs.alli.connect.nikko.co.jp/be/d/graphql/',
      smbc_nikko_dev: 'https://allidev-nk.jsol.jp/be/d/graphql/',
      smbc_jsol_nk: 'https://10.1.10.111/be/d/graphql/',
      smbc_smcc_prod: 'https://knowledge_search.smbc-card.com/be/d/graphql/',
      smbc_smcc_dev: 'https://allidev-cc.jsol.jp/be/d/graphql/',
      smbc_jsol_cc: 'https://10.1.10.11/be/d/graphql/',
      jpower: 'https://jpallisv.jpower.local/be/d/graphql/',
      busan_bank: 'https://alli.busanbank.com/be/d/graphql/',
    },
    getConfigValueToCheckFeature((isFeatureBranch, isFeatureOnlyFront) => {
      if (isFeatureBranch) {
        return `https://backend-${isFeatureBranch[0]}.alli.ai/d/graphql/`;
      }
      if (isFeatureOnlyFront) {
        return 'https://staging-backend.alli.ai/d/graphql/';
      }
      return 'https://backend-kr.alli.ai/d/graphql/';
    }),
  ),
  // Sentry DSN key used for exception reporting
  sentryDsn: env(
    {
      production: 'https://efcf0aeb99e84e85a3163d8dcd885762@sentry.io/1267508',
      travelers: 'https://efcf0aeb99e84e85a3163d8dcd885762@sentry.io/1267508',
      local: null,
      jisu: null,
      yjang: null,
      hyundai: null,
      hyundai_local_test: null,
      smbc: null,
      smbc_jsol_ip: null,
      smbc_jri_ip: null,
      smbc_nikko_prod: null,
      smbc_nikko_dev: null,
      smbc_jsol_nk: null,
      smbc_smcc_prod: null,
      smbc_smcc_dev: null,
      smbc_jsol_cc: null,
      jpower: null,
      busan_bank: null,
    },
    'https://efcf0aeb99e84e85a3163d8dcd885762@sentry.io/1267508',
  ),
};
