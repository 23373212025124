import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { cn } from '@bem-react/classname';

import { makeStyles, Styles, StyledComponentProps, Color } from '@/styles';
import { capitalize, fade } from '@/utils';

import { ButtonBase, ButtonBaseProps } from './ButtonBase';

const styles: Styles<
  | 'root'
  | 'label'
  | 'text'
  | 'textPrimary'
  | 'textSecondary'
  | 'textBlue'
  | 'outlined'
  | 'outlinedPrimary'
  | 'outlinedSecondary'
  | 'outlinedBlue'
  | 'contained'
  | 'containedPrimary'
  | 'containedSecondary'
  | 'containedBlue'
  | 'disableElevation'
  | 'focusVisible'
  | 'disabled'
  | 'colorInherit'
  | 'textSizeSmall'
  | 'textSizeLarge'
  | 'outlinedSizeSmall'
  | 'outlinedSizeLarge'
  | 'containedSizeSmall'
  | 'containedSizeLarge'
  | 'sizeSmall'
  | 'sizeLarge'
  | 'fullWidth'
  | 'startIcon'
  | 'endIcon'
  | 'iconSizeSmall'
  | 'iconSizeMedium'
  | 'iconSizeLarge'
> = theme => ({
  root: {
    ...theme.typography.button,
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    padding: '6px 16px',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(
      ['background-color', 'box-shadow', 'border'],
      {
        duration: theme.transitions.duration.short,
      },
    ),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: fade(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
      },
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
    },
  },
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  text: {
    padding: '6px 8px',
  },
  textPrimary: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  textSecondary: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.secondary.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  textBlue: {
    color: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: fade(
        theme.palette.blue.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  outlined: {
    padding: '5px 15px',
    border: `1px solid ${
      theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.23)'
        : 'rgba(255, 255, 255, 0.23)'
    }`,
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabledBackground}`,
    },
  },
  outlinedPrimary: {
    color: theme.palette.primary.main,
    border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: fade(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  outlinedSecondary: {
    color: theme.palette.secondary.main,
    border: `1px solid ${fade(theme.palette.secondary.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: fade(
        theme.palette.secondary.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
  },
  outlinedBlue: {
    color: theme.palette.blue.main,
    border: `1px solid ${fade(theme.palette.blue.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.blue.main}`,
      backgroundColor: fade(
        theme.palette.blue.main,
        theme.palette.action.hoverOpacity,
      ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
  },
  contained: {
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    backgroundColor: theme.palette.grey[300],
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.grey.A100,
      boxShadow: theme.shadows[4],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.grey[300],
      },
      '&$disabled': {
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
    '&$focusVisible': {
      boxShadow: theme.shadows[6],
    },
    '&:active': {
      boxShadow: theme.shadows[8],
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  containedPrimary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  containedSecondary: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  containedBlue: {
    color: theme.palette.blue.contrastText,
    backgroundColor: theme.palette.blue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.blue.main,
      },
    },
  },
  disableElevation: {
    boxShadow: theme.shadows[0],
    '&:hover': {
      boxShadow: theme.shadows[0],
    },
    '&$focusVisible': {
      boxShadow: theme.shadows[0],
    },
    '&:active': {
      boxShadow: theme.shadows[0],
    },
    '&$disabled': {
      boxShadow: theme.shadows[0],
    },
  },
  focusVisible: {},
  disabled: {},
  colorInherit: {
    color: 'inherit',
    borderColor: 'currentColor',
  },
  textSizeSmall: {
    padding: '4px 5px',
    fontSize: '13px',
  },
  textSizeLarge: {
    padding: '8px 11px',
    fontSize: '15px',
  },
  outlinedSizeSmall: {
    padding: '3px 9px',
    fontSize: '13px',
  },
  outlinedSizeLarge: {
    padding: '7px 21px',
    fontSize: '15px',
  },
  containedSizeSmall: {
    padding: '4px 10px',
    fontSize: '13px',
  },
  containedSizeLarge: {
    padding: '8px 22px',
    fontSize: '15px',
  },
  sizeSmall: {},
  sizeLarge: {},
  fullWidth: {
    width: '100%',
  },
  startIcon: {
    display: 'inherit',
    marginRight: '8px',
    marginLeft: '-4px',
    '&$iconSizeSmall': {
      marginLeft: '-2px',
    },
  },
  endIcon: {
    display: 'inherit',
    marginRight: '-4px',
    marginLeft: '8px',
    '&$iconSizeSmall': {
      marginRight: '-2px',
    },
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: '18px',
    },
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: '20px',
    },
  },
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: '22px',
    },
  },
});

const useStyles = makeStyles(styles);
const buttonClasses = cn('alli-sdk-Button');

export interface ButtonProps
  extends StyledComponentProps<typeof styles>,
    Omit<ButtonBaseProps, 'classes'> {
  color?: Color;
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      startIcon,
      endIcon,
      color = 'default',
      variant = 'text',
      size = 'medium',
      fullWidth,
      classes: classesProp,
      children,
      ...other
    },
    ref,
  ) => {
    const classes = useStyles({ classes: classesProp });

    return (
      <ButtonBase
        {...other}
        className={clsx(
          classes.root,
          classes[variant],
          {
            [classes[`${variant}${capitalize(color)}` as keyof typeof classes]]:
              color !== 'default' && color !== 'inherit',
            [classes[
              `${variant}Size${capitalize(size)}` as keyof typeof classes
            ]]: size !== 'medium',
            [classes[`size${capitalize(size)}` as keyof typeof classes]]:
              size !== 'medium',
            [classes.disabled]: other.disabled,
            [classes.fullWidth]: fullWidth,
            [classes.colorInherit]: color === 'inherit',
          },
          buttonClasses({
            color,
            variant,
            size,
            disabled: other.disabled,
            fullWidth,
          }),
          other.className,
        )}
        ref={ref}
      >
        <span className={clsx(classes.label, buttonClasses('label'))}>
          {startIcon && (
            <span
              className={clsx(
                classes.startIcon,
                classes[`iconSize${capitalize(size)}` as keyof typeof classes],
                buttonClasses('startIcon'),
              )}
            >
              {startIcon}
            </span>
          )}

          {children}

          {endIcon && (
            <span
              className={clsx(
                classes.endIcon,
                classes[`iconSize${capitalize(size)}` as keyof typeof classes],
                buttonClasses('endIcon'),
              )}
            >
              {endIcon}
            </span>
          )}
        </span>
      </ButtonBase>
    );
  },
);
