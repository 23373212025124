import React, {
  createContext,
  ComponentType,
  FunctionComponent,
  useContext,
} from 'react';

import { isSmall, isIEAndEdge } from '../utils/Display';

export enum Device {
  web = 'web',
  popup = 'popup',
  mobile = 'mobileWeb',
  android = 'android',
  ios = 'ios',
}

export interface Environment {
  device: Device;
  isIEAndEdge?: boolean;
}

const EnvironmentContext = createContext<Environment>({
  device: isSmall(window) ? Device.mobile : Device.web,
  isIEAndEdge: isIEAndEdge(),
});

export const EnvironmentProvider = EnvironmentContext.Provider;
export const useEnvironment = () => useContext(EnvironmentContext);

export interface InjectedEnvironmentProps {
  environment: Environment;
}

export function withEnvironment<T extends InjectedEnvironmentProps>(
  WrappedComponent: ComponentType<T>,
): FunctionComponent<Omit<T, keyof InjectedEnvironmentProps>> {
  return props => (
    <EnvironmentContext.Consumer>
      {environment => (
        // @ts-ignore
        <WrappedComponent {...props} environment={environment} />
      )}
    </EnvironmentContext.Consumer>
  );
}

/**
 * 1. NativeSDK: They used Alli NativeSDK
 * 2. Custom NativeApp: They made their NativeApp and Webview and used Alli WebSDK
 * If they are NativeSDK, this is true.
 * If they are Custom NativeApp, this is false. because device was injected as "web" because they used WebSDK
 */
export const isNativeSDK = (device?: Device): boolean => {
  return device === Device.android || device === Device.ios;
};

export const isFullScreenSDK = (device?: Device): boolean => {
  return device === Device.mobile || device === Device.popup;
};

export const isMobileSDK = (device?: Device): boolean => {
  return device === Device.mobile;
};
