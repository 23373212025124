import { theme } from './styles/theme';
import {
  NLUModelLanguageEnum,
  ChatBubblePositionEnum,
  ChatWindowStyle,
  ChatOptionAlign,
  PopUpBoxIconOption,
  ChatTextAlign,
} from './models';
import avatar from './avatar.svg';
import launcher from './profile-alli.svg';

export const ALLI_HOME = 'https://www.alli.ai';

export const BASE_HEIGHT = 480;
export const MIN_HEIGHT = 400;
export const MIN_WIDTH = 300;
export const FORM_HEIGHT = 110;
export const LAUNCHER_MARGIN = 16;
export const LAUNCHER_HEIGHT = 80;
export const DEFAULT_POPUP_WIDTH = 250;
export const MIN_POPUP_WIDTH = 200;
export const MAX_POPUP_WIDTH = 500;
export const CHAT_WINDOW_WIDTH_DEFAULT = 360;
export const CHAT_WINDOW_WIDTH_MIN = 300;
export const CHAT_WINDOW_WIDTH_MAX = 1600;
export const CHAT_WINDOW_HEIGHT_DEFAULT = 640;
export const CHAT_WINDOW_HEIGHT_MIN = 400;
export const CHAT_WINDOW_HEIGHT_MAX = 1000;

export const DEFAULT_SDKSETTINGS = {
  avatar,
  avatarSrcSet: undefined,
  companyLogo: undefined,
  companyName: 'Alli',
  showContactAgentButton: true,
  showSendConversationButton: true,
  themeColor: theme.palette.primary.main,
  componentsHeaderColor: theme.palette.common.white,
  componentsColor: theme.palette.blue.main,
  componentsTextColor: theme.palette.common.white,
  chatBubblePosition: ChatBubblePositionEnum.RIGHT_BOTTOM,
  hasNewConversationButton: false,
  hasBackButton: true,
  chatOptionAlign: ChatOptionAlign.DEFAULT,
  chatTextAlign: ChatTextAlign.LEFT,
  chatWindowStyle: ChatWindowStyle.COMPACT,
  chatWindowWidth: CHAT_WINDOW_WIDTH_DEFAULT,
  chatWindowHeight: CHAT_WINDOW_HEIGHT_DEFAULT,
  useAutoComplete: false,
  numAutoComplete: 3,
  popUpLauncher: launcher,
  popUpLauncherSrcSet: undefined,
  popUpWidth: DEFAULT_POPUP_WIDTH,
  popUpCustomMessage:
    '{"blocks":[{"text":"Hi, I am Alli","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}',
  popUpUseCustomMessage: true,
  popUpShowMessageFirstArrive: true,
  popUpShowMessageAfterClose: false,
  popUpBoxIconOption: PopUpBoxIconOption.AVATAR,
  popUpBoxIcon: undefined,
};

export const LANG_DIC = {
  [NLUModelLanguageEnum.ko]: 'ko_KR',
  [NLUModelLanguageEnum.en]: 'en_US',
  [NLUModelLanguageEnum.ja]: 'ja_JP',
};

export const ALLI_CONTAINER = 'alli-container';

export const CHAT_PER_PAGE = 20;
export const CHATHISTORY_PER_PAGE = 20;

export const ALLI_Z_INDEX = 9999;

export const allichatListID = 'alli-chat-list-container';

export const alliNegativeFeedbackModalID = 'alli-chat-negative-feedback-modal';
