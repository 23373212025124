import React, { Component, Suspense } from 'react';
import { hot } from 'react-hot-loader';
import ApolloClient, { ApolloError } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { EventSubscription } from 'fbemitter';
import { IntlProvider } from 'react-intl';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';
import { configureScope } from '@sentry/react';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { IdProvider } from 'react-use-id-hook';

import {
  Locale,
  NLUModelLanguageEnum,
  SDKSettings,
  SDKUrlPattern,
} from '@/models';
import { AlliEventKeyMap } from '@/Alli.const';
import { User, Variable } from '@/Alli.types';
import { Settings } from '@/Settings';
import { ConfirmProvider } from '@/components/Confirm';

import PreviewContainerProvider from '@/components/PreviewContainer/PreviewContainerProvider';
import { NotFound } from './UIState';
import {
  getBrowserLocale,
  getInitialLocale,
  getBrowserLocaleEnum,
  getSDKReferrerInput,
} from '../utils';
import { SubscriptionClientProvider } from '../apollo/SubscriptionClientContext';
// eslint-disable-next-line import/no-unresolved
import translations from '../translations.json';

import {
  StartConversationMutation,
  Data,
  Variables,
  Conversation as ConversationType,
} from './StartConversationMutation';
import { SettingsProvider } from './SettingsProvider';
import {
  Data as EndConversationData,
  Variables as EndConversationVariables,
  EndConversationMutation,
} from './Header/EndConversationMutation';
import StartingConversation from './StartingConversation';
import { ViewType } from './Models';
import {
  TryConversationMutation,
  Data as TryData,
} from './TryConversationMutation';
import { InjectedTryModeProps, withTryMode } from './TryModeProvider';
import {
  withConversation,
  InjectedConversationProps,
  ConversationActionType,
} from './ConversationProvider';
import { PreviewModeProvider } from './PreviewModeProvider';
import ErrorNotice from './ErrorNotice';
import { AppContextProvider } from './App.Context';

const Container = React.lazy(() => import('./Container'));

interface AppProps extends InjectedTryModeProps, InjectedConversationProps {
  settings: Settings;
}

interface AppState {
  client: ApolloClient<any> | null;
  subscriptionClient: SubscriptionClient | null;
  user: User | null | undefined;
  variables: Variable[] | null | undefined;
  viewType: ViewType;
  firstLoad: boolean;
  isTokenExpired: boolean;
}

class App extends Component<AppProps, AppState> {
  private static LOCALES: string[] = values(Locale);

  public state: AppState = {
    user: this.props.settings.user,
    client: null,
    subscriptionClient: null,
    variables: this.props.settings.variables,
    viewType: ViewType.conversation,
    firstLoad: this.props.settings.launcher,
    isTokenExpired: false,
  };

  private clientSubscription: EventSubscription | null = null;
  private eventSubscription: EventSubscription | null = null;
  private closeSubscription: EventSubscription | null = null;
  private endConversationSubscription: EventSubscription | null = null;
  private continueConversationSubscription: EventSubscription | null = null;
  private expiredTokenSubscription: EventSubscription | null = null;
  private intervalId: number | null = null;

  public componentDidMount() {
    this.props.settings.setFingerprint();
    this.addListeners();
  }

  public componentDidUpdate(prevProps: AppProps) {
    if (!isEqual(this.props.settings, prevProps.settings)) {
      this.removeListeners();
      this.props.settings.setFingerprint();
      this.addListeners();
    }
  }

  public componentWillUnmount() {
    this.removeListeners();

    if (this.expiredTokenSubscription !== null) {
      this.expiredTokenSubscription.remove();
      this.expiredTokenSubscription = null;
    }
  }

  public render() {
    const {
      variables,
      client,
      subscriptionClient,
      user,
      viewType,
      firstLoad,
      isTokenExpired,
    } = this.state;
    const { placement, isTryMode, conversation, startOver, error } = this.props;
    if (
      this.props.settings.errorStatusCode !== null &&
      // show error only in popupMode
      this.props.settings.popupMode
    ) {
      return (
        <IntlProvider
          locale={getBrowserLocale(window.navigator)}
          messages={translations[getBrowserLocale(window.navigator)]}
        >
          <NotFound errorStatusCode={this.props.settings.errorStatusCode} />
        </IntlProvider>
      );
    }
    if (isTokenExpired || this.props.settings.isTokenExpired) {
      return (
        <IntlProvider
          locale={getBrowserLocale(window.navigator)}
          messages={translations[getBrowserLocale(window.navigator)]}
        >
          <NotFound emailTokenExpired />
        </IntlProvider>
      );
    }

    if (placement !== null && client) {
      const startConversationMutationVariables: Variables = {
        placement,
        locale: getBrowserLocaleEnum(window.navigator),
        userAgent: window.navigator.userAgent,
        debug: this.props.settings.alliLogger.isDebug(),
        sdkReferrer: getSDKReferrerInput(window.location, window.document),
        variables: variables
          ? variables.filter(
              v => v.value !== null && typeof v.value !== 'undefined',
            )
          : undefined,
        startOver: startOver || undefined,
      };

      return (
        <ConfirmProvider>
          <AppContextProvider changeViewType={this.changeViewType}>
            <IdProvider>
              <PreviewModeProvider>
                <SettingsProvider settings={this.props.settings}>
                  <ApolloProvider client={client}>
                    <SubscriptionClientProvider value={subscriptionClient}>
                      <StartConversationMutation
                        mutation={StartConversationMutation.mutation}
                        variables={startConversationMutationVariables}
                        onCompleted={this.handleStartConversationCompleted}
                        onError={this.handleStartConversationError}
                      >
                        {startConversationMutation => (
                          <TryConversationMutation
                            mutation={TryConversationMutation.mutation}
                            variables={startConversationMutationVariables}
                            onCompleted={this.handleTryConversationCompleted}
                            onError={this.handleStartConversationError}
                          >
                            {tryConversationMutation => {
                              if (error && this.props.settings.errorNotice) {
                                const userLocale: Locale = getInitialLocale(
                                  getBrowserLocale(window.navigator),
                                );
                                return (
                                  <IntlProvider
                                    locale={userLocale}
                                    messages={translations[userLocale]}
                                  >
                                    <ErrorNotice />
                                  </IntlProvider>
                                );
                              }

                              if (conversation && conversation.user) {
                                const { user: me } = conversation;
                                const userLocale: Locale = getInitialLocale(
                                  me.locale &&
                                    App.LOCALES.includes(
                                      me.locale.toLowerCase(),
                                    )
                                    ? me.locale.toLowerCase()
                                    : getBrowserLocale(window.navigator),
                                );
                                const nluLanguage: NLUModelLanguageEnum = ((conversation.project &&
                                  conversation.project.nluLanguage) ||
                                  userLocale) as NLUModelLanguageEnum;

                                this.setConfiture(conversation);
                                if (
                                  me.ownUserId &&
                                  this.props.settings.user === null
                                ) {
                                  this.props.settings.setUser(
                                    { id: me.ownUserId },
                                    true,
                                  );
                                }
                                return (
                                  <IntlProvider
                                    locale={userLocale}
                                    messages={translations[userLocale]}
                                  >
                                    <Suspense fallback={null}>
                                      <PreviewContainerProvider>
                                        <Container
                                          viewType={viewType}
                                          selectConversation={
                                            this.selectConversation
                                          }
                                          startNewConversation={
                                            this.startNewConversation
                                          }
                                          nluLanguage={nluLanguage}
                                          firstLoad={firstLoad}
                                        />
                                      </PreviewContainerProvider>
                                    </Suspense>
                                  </IntlProvider>
                                );
                              }

                              if (
                                user === null &&
                                isTryMode &&
                                this.props.settings.fingerprint === null &&
                                !this.props.settings.chatToken &&
                                !isTokenExpired
                              ) {
                                return (
                                  <StartingConversation
                                    start={tryConversationMutation}
                                  />
                                );
                              }

                              if (!error) {
                                return (
                                  <StartingConversation
                                    start={startConversationMutation}
                                  />
                                );
                              }

                              return null;
                            }}
                          </TryConversationMutation>
                        )}
                      </StartConversationMutation>
                    </SubscriptionClientProvider>
                  </ApolloProvider>
                </SettingsProvider>
              </PreviewModeProvider>
            </IdProvider>
          </AppContextProvider>
        </ConfirmProvider>
      );
    }

    return null;
  }

  private removeListeners() {
    if (this.clientSubscription !== null) {
      this.clientSubscription.remove();
      this.clientSubscription = null;
    }

    if (this.eventSubscription !== null) {
      this.eventSubscription.remove();
      this.eventSubscription = null;
    }

    if (this.closeSubscription !== null) {
      this.closeSubscription.remove();
      this.closeSubscription = null;
    }

    if (this.endConversationSubscription !== null) {
      this.endConversationSubscription.remove();
      this.endConversationSubscription = null;
    }

    if (this.continueConversationSubscription !== null) {
      this.continueConversationSubscription.remove();
      this.continueConversationSubscription = null;
    }

    if (this.intervalId !== null) {
      window.clearTimeout(this.intervalId);
      this.intervalId = null;
    }
  }

  private addListeners() {
    this.intervalId = window.setInterval(
      this.props.settings.setFingerprint,
      1000,
    );
    this.clientSubscription = this.props.settings.addListener(
      AlliEventKeyMap.Client,
      this.handleClient,
    );
    this.eventSubscription = this.props.settings.addListener(
      AlliEventKeyMap.Event,
      this.handleEvent,
    );
    this.closeSubscription = this.props.settings.addListener(
      AlliEventKeyMap.Close,
      this.handleClose,
    );
    this.endConversationSubscription = this.props.settings.addListener(
      AlliEventKeyMap.EndConversation,
      this.handleEndConversation,
    );
    this.continueConversationSubscription = this.props.settings.addListener(
      AlliEventKeyMap.ContinueConversation,
      this.handleContinueConversation,
    );
    this.expiredTokenSubscription = this.props.settings.addListener(
      AlliEventKeyMap.ExpiredToken,
      this.handleExpiredToken,
    );
  }

  private handleClient = (
    client: ApolloClient<any>,
    subscriptionClient: SubscriptionClient,
  ) => {
    this.setState({ client, subscriptionClient });
  };

  private handleEvent = (
    user: User | null,
    placement: string,
    variables?: Variable[],
  ) => {
    this.setState({
      user,
      variables,
    });
    this.props.dispatch({
      type: ConversationActionType.INIT,
      payload: { placement },
    });
  };

  private handleClose = () => {
    this.props.dispatch({
      type: ConversationActionType.CLOSE,
    });
  };

  private handleExpiredToken = () => {
    this.setState({ isTokenExpired: true });
  };

  private handleContinueConversation = ({
    conversation,
    sdkSettings,
    sdkUrlPatterns,
    placement,
  }: {
    conversation: ConversationType;
    sdkSettings: SDKSettings;
    sdkUrlPatterns: SDKUrlPattern[];
    placement: string | null;
  }) => {
    this.props.disableTryMode();
    this.props.dispatch({
      type: ConversationActionType.CONTINUE_CONVERSATION,
      payload: { conversation, sdkSettings, sdkUrlPatterns, placement },
    });
    this.props.settings.emit(AlliEventKeyMap.Conversation, {
      data: conversation,
    });
  };

  private handleEndConversation = () => {
    if (this.props.conversation && this.state.client) {
      this.state.client
        .mutate<EndConversationData, EndConversationVariables>({
          mutation: EndConversationMutation.mutation,
          variables: {
            where: {
              id: this.props.conversation.id,
            },
          },
        })
        .then(response => {
          if (response?.data?.endConversation?.conversation) {
            this.props.dispatch({
              type: ConversationActionType.END_CONVERSATION,
              payload: {
                conversation: response.data.endConversation.conversation,
              },
            });
          }
        });
    }
  };

  private handleStartConversationCompleted = (data: Data) => {
    if (data.startConversation?.conversation) {
      this.props.settings.emit(AlliEventKeyMap.Conversation, {
        data: data.startConversation.conversation,
      });
      this.props.dispatch({
        type: ConversationActionType.START_CONVERSATION,
        payload: {
          conversation: data.startConversation.conversation,
          sdkSettings: data.startConversation.sdkSettings,
          sdkUrlPatterns: data.startConversation.sdkUrlPatterns || [],
        },
      });
      this.props.disableTryMode();
    } else {
      this.props.settings.emit(AlliEventKeyMap.Conversation, {
        data: null,
      });
      this.props.clearConversation(
        this.props.settings.apiKey,
        this.props.settings.user && this.props.settings.user.id,
      );
      this.props.dispatch({
        type: ConversationActionType.FAIL_CONVERSATION,
        payload: {
          conversation: data.startConversation?.conversation || null,
          sdkSettings: data.startConversation?.sdkSettings || null,
          sdkUrlPatterns: data.startConversation?.sdkUrlPatterns || [],
          error:
            (data.startConversation?.errors &&
              data.startConversation?.errors.length > 0) ||
            false,
        },
      });
      this.props.disableTryMode();
    }
    if (data.startConversation?.debug) {
      this.props.settings.alliLogger.printDebug(
        'registered campaigns',
        JSON.parse(data.startConversation.debug),
      );
    }
  };

  private handleTryConversationCompleted = (data: TryData) => {
    if (data.tryConversation?.conversation) {
      /* broadcast to outside(ex. mobile) */
      this.props.settings.emit(AlliEventKeyMap.Conversation, {
        data: data.tryConversation.conversation,
      });
      this.props.dispatch({
        type: ConversationActionType.TRY_CONVERSATION,
        payload: {
          conversation: data.tryConversation.conversation,
          sdkSettings: data.tryConversation.sdkSettings,
          sdkUrlPatterns: data.tryConversation.sdkUrlPatterns || [],
        },
      });
      this.props.enableTryMode();
    } else {
      this.props.settings.emit(AlliEventKeyMap.Conversation, {
        data: null,
      });
      this.props.clearConversation(
        this.props.settings.apiKey,
        this.props.settings.user && this.props.settings.user.id,
      );
      this.props.dispatch({
        type: ConversationActionType.FAIL_CONVERSATION,
        payload: {
          conversation: data.tryConversation?.conversation || null,
          sdkSettings: data.tryConversation?.sdkSettings || null,
          sdkUrlPatterns: data.tryConversation?.sdkUrlPatterns || [],
          error:
            (data.tryConversation?.errors &&
              data.tryConversation?.errors.length > 0) ||
            false,
        },
      });
      this.props.enableTryMode();
    }
  };

  private handleStartConversationError = (error: ApolloError) => {
    this.props.settings.emit(AlliEventKeyMap.Conversation, {
      error,
    });
    this.props.dispatch({
      type: ConversationActionType.ERROR_CONVERSATION,
    });
  };

  private changeViewType = (viewType: ViewType) => {
    this.setState({ viewType });
  };

  private selectConversation = (conversation: ConversationType) => {
    this.setState({
      viewType: ViewType.conversation,
    });
    this.props.dispatch({
      type: ConversationActionType.SELECT_CONVERSATION,
      payload: {
        conversation,
      },
    });
  };

  private startNewConversation = () => {
    this.props.dispatch({
      type: ConversationActionType.START_NEW_CONVERSATION,
    });
    this.setState({
      viewType: ViewType.conversation,
      firstLoad: false,
    });
  };

  private setConfiture = (conversation: ConversationType) => {
    const { id, user, project, campaign } = conversation;

    configureScope(scope => {
      if (user) {
        scope.setUser({
          id: `${user.id}`,
          name: `${user.firstName} ${user.lastName}`,
        });
      }
      if (project) {
        scope.setTag('projectId', `${project.id}`);
        scope.setTag('projectName', project.name);
      }
      scope.setTag('conversationId', `${id}`);
      scope.setTag('campaignId', (campaign && `${campaign.id}`) || '');
      scope.setTag('campaignName', (campaign && campaign.name) || '');
    });
  };
}

export default hot(module)(withTryMode(withConversation(App)));
