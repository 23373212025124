import Cookies from 'js-cookie';

import { ID } from '@/models';

const ALLI_LAST_MESSAGE_KEY = 'alli-last-cursor';
const EXPIRES_DAYS = 30;

export const setLastMessage = (cursor: ID) => {
  Cookies.set(ALLI_LAST_MESSAGE_KEY, `${cursor}`, {
    expires: EXPIRES_DAYS,
    sameSite: 'None',
    secure: true,
  });
};
export const getLastMessage = () => {
  return Cookies.get(ALLI_LAST_MESSAGE_KEY) || null;
};
export const removeLastMessage = () => {
  Cookies.remove(ALLI_LAST_MESSAGE_KEY);
};
