export const timeout = (ms: number): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    let timeoutId: number | NodeJS.Timeout | null = null;

    const clear = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId as any);
        timeoutId = null;
      }
    };

    try {
      timeoutId = setTimeout(() => {
        try {
          resolve();
          clear();
        } catch (err) {
          clear();
          reject(err);
        }
      }, ms);
    } catch (err) {
      clear();
      reject(err);
    }
  });

export const raf = (): Promise<void> =>
  new Promise<void>(resolve => {
    let rafId: number | null = null;

    rafId = window.requestAnimationFrame(() => {
      if (rafId !== null) {
        window.cancelAnimationFrame(rafId);
      }

      resolve();
    });
  });
