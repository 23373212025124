import React, { Fragment, FunctionComponent, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { confirmAtom, useConfirm } from './ConfirmContext';
import { ConfirmDialog } from './ConfirmDialog';

export const ConfirmProvider: FunctionComponent = ({ children }) => {
  const dialogs = useRecoilValue(confirmAtom);
  const { cancelConfirm } = useConfirm();
  const dialog = dialogs[0] || null;
  const options =
    (dialog &&
      (typeof dialog.options === 'function'
        ? dialog.options({
            key: dialog.key,
            cancel: () => cancelConfirm(dialog.key),
          })
        : dialog.options)) ||
    null;

  const handleClose = useCallback(
    (event, reason) => {
      if (dialog && options) {
        if (options.onClose) {
          options.onClose(event, reason);
        }

        cancelConfirm(dialog.key);
      }
    },
    [cancelConfirm, dialog, options],
  );

  return (
    <Fragment>
      {children}

      {dialog && <ConfirmDialog {...options} open onClose={handleClose} />}
    </Fragment>
  );
};
