import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';

import { Styles, makeStyles, StyledComponentProps } from '@/styles';
import { useFocusVisible } from '../FocusVisible';

const styles: Styles<'root' | 'disabled' | 'focusVisible'> = {
  root: {
    minWidth: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent', // Reset default value
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
    border: 0,
    margin: 0, // Remove the margin in Safari
    borderRadius: 0,
    padding: 0, // Remove the padding in Firefox
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    '-moz-appearance': 'none', // Reset
    '-webkit-appearance': 'none', // Reset
    textDecoration: 'none',
    // So we take precedent over the style of a native <a /> element.
    color: 'inherit',
    '&::-moz-focus-inner': {
      borderStyle: 'none', // Remove Firefox dotted outline.
    },
    '&$disabled': {
      pointerEvents: 'none', // Disable link interactions
      cursor: 'default',
    },
  },
  disabled: {},
  focusVisible: {},
};

export const useButtonBaseStyles = makeStyles(styles);

export const useButtonBase = <T extends HTMLElement>({
  classes,
  disabled,
  tabIndex = 0,
  ...props
}: StyledComponentProps<typeof styles> & {
  disabled?: boolean;
  tabIndex?: number;
  onKeyDown?: React.KeyboardEventHandler<T>;
  onFocus?: React.FocusEventHandler<T>;
  onBlur?: React.FocusEventHandler<T>;
}) => {
  const focusVisible = useFocusVisible<T>(props);

  const buttonBase = useMemo(
    () => ({
      ...focusVisible,
      tabIndex: disabled ? -1 : tabIndex,
      className: classes
        ? clsx(
            classes.root,
            classes.disabled && {
              [classes.disabled]: disabled,
            },
            classes.focusVisible && {
              [classes.focusVisible]: focusVisible && !disabled,
            },
          ) || undefined
        : undefined,
    }),
    [focusVisible, disabled, tabIndex, classes],
  );

  return buttonBase;
};

export type ButtonBaseProps<
  T extends React.ElementType = 'button'
> = StyledComponentProps<typeof styles> &
  React.ComponentPropsWithRef<T> & {
    className?: string;
    disabled?: boolean;
    component?: T;
  };

export const ButtonBase = forwardRef(function ButtonBaseBase<
  T extends React.ElementType = 'button'
>(
  { component, classes: classesProp, ...other }: ButtonBaseProps<T>,
  ref: React.Ref<ButtonBaseProps<T>['ref']>,
) {
  const Component = component || 'button';
  const classes = useButtonBaseStyles({ classes: classesProp });
  const { tabIndex, className, onKeyDown, onFocus, onBlur } = useButtonBase({
    ...other,
    classes,
  });

  return (
    <Component
      type={Component === 'button' ? 'button' : undefined}
      {...other}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      className={clsx(className, other.className)}
      ref={ref}
    />
  );
});
