/* eslint-disable react/no-unused-state */
import React, {
  Component,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';

import { noop } from '../utils';

const inputTypesWhitelist: Partial<
  Record<HTMLInputElement['type'], boolean>
> = {
  text: true,
  search: true,
  url: true,
  tel: true,
  email: true,
  password: true,
  number: true,
  date: true,
  month: true,
  week: true,
  time: true,
  datetime: true,
  'datetime-local': true,
};

/**
 * Computes whether the given element should automatically trigger the
 * `focus-visible` class being added, i.e. whether it should always match
 * `:focus-visible` when focused.
 */
const focusTriggersKeyboardModality = (el: Element): boolean => {
  if (
    el.tagName === 'INPUT' &&
    inputTypesWhitelist[(el as HTMLInputElement).type] &&
    !(el as HTMLInputElement).readOnly
  ) {
    return true;
  }

  if (el.tagName === 'TEXTAREA' && !(el as HTMLTextAreaElement).readOnly) {
    return true;
  }

  if ((el as HTMLElement).isContentEditable) {
    return true;
  }

  return false;
};

interface FocusVisibleState {
  isFocusVisible(el: Element): boolean;
  checkSwitch(): void;
}

export const FocusVisibleContext = createContext<FocusVisibleState>({
  isFocusVisible() {
    return true;
  },
  checkSwitch: noop,
});

export function useFocusVisible<T extends Element>({
  onKeyDown,
  onFocus,
  onBlur,
}: {
  onKeyDown?: React.KeyboardEventHandler<T>;
  onFocus?: React.FocusEventHandler<T>;
  onBlur?: React.FocusEventHandler<T>;
} = {}) {
  const { isFocusVisible, checkSwitch } = useContext(FocusVisibleContext);
  const [focusVisible, setFocusVisible] = useState(false);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<T>) => {
      if (e.metaKey || e.altKey || e.ctrlKey) {
        return;
      }

      if (document.activeElement && e.target === document.activeElement) {
        setFocusVisible(true);
      }

      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [onKeyDown],
  );

  /**
   * On `focus`, add the `focus-visible` class to the target if:
   * - the target received focus as a result of keyboard navigation, or
   * - the event target is an element that will likely require interaction
   *   via the keyboard (e.g. a text box)
   */
  const handleFocus = useCallback(
    (e: React.FocusEvent<T>) => {
      if (e.target && isFocusVisible(e.target)) {
        setFocusVisible(true);
      }

      if (onFocus) {
        onFocus(e);
      }
    },
    [isFocusVisible, onFocus],
  );

  const handleBlur = useCallback(
    e => {
      if (focusVisible) {
        checkSwitch();
        setFocusVisible(false);
      }

      if (onBlur) {
        onBlur(e);
      }
    },
    [focusVisible, checkSwitch, onBlur],
  );

  return {
    focusVisible,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onKeyDown: handleKeyDown,
  };
}

interface FocusVisibleProps {}

export class FocusVisible extends Component<
  FocusVisibleProps,
  FocusVisibleState
> {
  private hadKeyboardEvent = true;
  private hadFocusVisibleRecently = false;
  private hadFocusVisibleRecentlyTimeout: number | null = null;

  // eslint-disable-next-line react/sort-comp
  private isFocusVisible = (el: Element) => {
    try {
      return el.matches(':focus-visible');
    } catch (_) {
      // nothing to do
    }

    return this.hadKeyboardEvent || focusTriggersKeyboardModality(el);
  };

  // eslint-disable-next-line react/sort-comp
  private checkSwitch = () => {
    // To detect a tab/window switch, we look for a blur event followed
    // rapidly by a visibility change.
    // If we don't see a visibility change within 100ms, it's probably a
    // regular focus change.
    this.hadFocusVisibleRecently = true;

    if (this.hadFocusVisibleRecentlyTimeout !== null) {
      window.clearTimeout(this.hadFocusVisibleRecentlyTimeout);
    }

    this.hadFocusVisibleRecentlyTimeout = window.setTimeout(() => {
      this.hadFocusVisibleRecently = false;
    }, 100);
  };

  public state: FocusVisibleState = {
    isFocusVisible: this.isFocusVisible,
    checkSwitch: this.checkSwitch,
  };

  public componentDidMount() {
    // For some kinds of state, we are interested in changes at the global scope
    // only. For example, global pointer input, global key presses and global
    // visibility change should affect the state at every scope:
    document.addEventListener('keydown', this.onKeyDown, true);
    document.addEventListener('mousedown', this.onPointerDown, true);
    document.addEventListener('pointerdown', this.onPointerDown, true);
    document.addEventListener('touchstart', this.onPointerDown, true);
    document.addEventListener(
      'visibilitychange',
      this.onVisibilityChange,
      true,
    );

    this.addInitialPointerMoveListeners();
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, true);
    document.removeEventListener('mousedown', this.onPointerDown, true);
    document.removeEventListener('pointerdown', this.onPointerDown, true);
    document.removeEventListener('touchstart', this.onPointerDown, true);
    document.removeEventListener(
      'visibilitychange',
      this.onVisibilityChange,
      true,
    );

    this.removeInitialPointerMoveListeners();

    if (this.hadFocusVisibleRecentlyTimeout !== null) {
      window.clearTimeout(this.hadFocusVisibleRecentlyTimeout);
    }
  }

  public render() {
    return (
      <FocusVisibleContext.Provider value={this.state}>
        {this.props.children}
      </FocusVisibleContext.Provider>
    );
  }

  /**
   * If the most recent user interaction was via the keyboard;
   * and the key press did not include a meta, alt/option, or control key;
   * then the modality is keyboard. Otherwise, the modality is not keyboard.
   * Apply `focus-visible` to any current active element and keep track
   * of our keyboard modality state with `hadKeyboardEvent`.
   */
  private onKeyDown = (e: KeyboardEvent) => {
    if (e.metaKey || e.altKey || e.ctrlKey) {
      return;
    }

    this.hadKeyboardEvent = true;
  };

  /**
   * If at any point a user clicks with a pointing device, ensure that we change
   * the modality away from keyboard.
   * This avoids the situation where a user presses a key on an already focused
   * element, and then clicks on a different element, focusing it with a
   * pointing device, while we still think we're in keyboard modality.
   */
  private onPointerDown = () => {
    this.hadKeyboardEvent = false;
  };

  /**
   * If the user changes tabs, keep track of whether or not the previously
   * focused element had .focus-visible.
   */
  private onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      // If the tab becomes active again, the browser will handle calling focus
      // on the element (Safari actually calls it twice).
      // If this tab change caused a blur on an element with focus-visible,
      // re-apply the class when the user switches back to the tab.
      if (this.hadFocusVisibleRecently) {
        this.hadKeyboardEvent = true;
      }

      this.addInitialPointerMoveListeners();
    }
  };

  /**
   * Add a group of listeners to detect usage of any pointing devices.
   * These listeners will be added when the polyfill first loads, and anytime
   * the window is blurred, so that they are active when the window regains
   * focus.
   */
  private addInitialPointerMoveListeners() {
    document.addEventListener('mousemove', this.onInitialPointerMove);
    document.addEventListener('mousedown', this.onInitialPointerMove);
    document.addEventListener('mouseup', this.onInitialPointerMove);
    document.addEventListener('pointermove', this.onInitialPointerMove);
    document.addEventListener('pointerdown', this.onInitialPointerMove);
    document.addEventListener('pointerup', this.onInitialPointerMove);
    document.addEventListener('touchmove', this.onInitialPointerMove);
    document.addEventListener('touchstart', this.onInitialPointerMove);
    document.addEventListener('touchend', this.onInitialPointerMove);
  }

  private removeInitialPointerMoveListeners() {
    document.removeEventListener('mousemove', this.onInitialPointerMove);
    document.removeEventListener('mousedown', this.onInitialPointerMove);
    document.removeEventListener('mouseup', this.onInitialPointerMove);
    document.removeEventListener('pointermove', this.onInitialPointerMove);
    document.removeEventListener('pointerdown', this.onInitialPointerMove);
    document.removeEventListener('pointerup', this.onInitialPointerMove);
    document.removeEventListener('touchmove', this.onInitialPointerMove);
    document.removeEventListener('touchstart', this.onInitialPointerMove);
    document.removeEventListener('touchend', this.onInitialPointerMove);
  }

  /**
   * When the polfyill first loads, assume the user is in keyboard modality.
   * If any event is received from a pointing device (e.g. mouse, pointer,
   * touch), turn off keyboard modality.
   * This accounts for situations where focus enters the page from the URL bar.
   */
  private onInitialPointerMove = (
    e: MouseEvent | PointerEvent | TouchEvent,
  ) => {
    // Work around a Safari quirk that fires a mousemove on <html> whenever the
    // window blurs, even if you're tabbing out of the page. ¯\_(ツ)_/¯
    if (
      e.target &&
      (e.target as Node).nodeName &&
      (e.target as Node).nodeName.toLowerCase() === 'html'
    ) {
      return;
    }

    this.hadKeyboardEvent = false;
    this.removeInitialPointerMoveListeners();
  };
}
