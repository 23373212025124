import { Typename, Node, Nullable, Float, Int } from '../graphql';

import { ChatInputType } from './Chat';
import { ChatMedia } from './ChatMedia';
import { ChatVariable } from './ChatVariable';
import { ChatFAQCarousel } from './ChatFAQCarousel';
import { ChatOptionInfo } from './ChatOptionInfo';

export enum MRCAnswerType {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  GENERATED = 'GENERATED',
}

export enum ChatBoxTemplate {
  NLU_OPTIONS = 'NLU_OPTIONS',
  DEFAULT = 'DEFAULT',
}

export enum ChatCompleteState {
  SUCCESS = 'SUCCESS',
  PROGRESS = 'PROGRESS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  OPENAI_ERROR = 'OPENAI_ERROR',
  STOPPED = 'STOPPED',
}

export enum SearchContextStep {
  PREPARE = 'PREPARE',
  CLARIFYING_INTENT = 'CLARIFYING_INTENT',
  GENERATING_FOLLOW_UP_QUESTION = 'GENERATING_FOLLOW_UP_QUESTION',
  PREPARE_CLUE = 'PREPARE_CLUE',
  GENERATING_ANSWER = 'GENERATING_ANSWER',
  COMPLETE = 'COMPLETE',
}

export interface BotChat extends Typename<'BotChat'>, Node {
  message: Nullable<string>;
  media: Nullable<ChatMedia>;
  chatOptionInfos: Nullable<ChatOptionInfo[]>;
  createdAt: Float;
  multipleOption: Nullable<boolean>;
  nextInputType: ChatInputType[];
  faqCarousel: Nullable<ChatFAQCarousel[]>;
  saveAsVariable: Nullable<ChatVariable>;
  reusable: Nullable<boolean>;
  ratable: boolean;
  rating: Nullable<boolean>;
  useAutoComplete: boolean;
  mrcAnswerType: Nullable<MRCAnswerType>;
  placeholder: Nullable<string>;
  chatBoxTemplate: Nullable<ChatBoxTemplate>;
  completed: Nullable<boolean>;
  completeState: Nullable<ChatCompleteState>;
  generatingState: Nullable<SearchContextStep>;
  hidden: Nullable<boolean>;
}

export interface AllowKnowledgeBaseFileInfo
  extends Typename<'AllowKnowledgeBaseFileInfo'> {
  extensions: Nullable<string[]>;
  maxSize: Nullable<Int>;
}
