import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'Chat',
          possibleTypes: [
            { name: 'UserChat' },
            { name: 'BotChat' },
            { name: 'CarouselChat' },
            { name: 'FillSlotRichUXChat' },
            { name: 'AgentChat' },
            { name: 'CustomUIChat' },
            { name: 'EventChat' },
            { name: 'ContactAgentByEmailChat' },
            { name: 'SendFormChat' },
          ],
        },
      ],
    },
  },
});
